import {
    AppBar,
    Avatar,
    Box,
    Button, Chip,
    Collapse,
    Container, Divider,
    Grid,
    IconButton, LinearProgress,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from "react-router-dom";
import VerifyChip from "./VerifyChip";
import { CommonFunctions } from "../api/CommonFunctions";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from "react-i18next";
import { getTranslation, useLanguage } from "../LanguageProvider";
import CustomizedSwitches from "./CustomizedSwitches";
import { AiOutlineInbox, AiOutlineMail, AiOutlineCaretRight } from "react-icons/ai";
import { BiHome, BiNews } from "react-icons/bi";
import { BsPersonWorkspace } from "react-icons/bs";
import { GiTeacher } from "react-icons/gi";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";




const pages = ['Products', 'Pricing', 'Blog'];
type Anchor = 'left';

interface NavbarProps {
    toggleNavbarDrawer: (value: boolean | ((prevState: boolean) => boolean)) => void;
    data: string;
    takeSpace?: boolean;
}
function Navbar({ toggleNavbarDrawer, data, takeSpace = false }: NavbarProps) {
    const auth = useAuth();
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [timeoutProgress, setTimeoutProgress] = React.useState(false);
    const { language, changeLanguage } = useLanguage(); // Dil bağlamını kullanarak dil ve değiştirme işlevini alın
    const translation = getTranslation(language);
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };
    const handleChangeLanguage = (lang: string) => {
        changeLanguage(lang); // Dil değiştirme işlevini çağırın
    };
    const activePage = data.toLowerCase();
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    async function logout() {
        setTimeoutProgress(true)
        await CommonFunctions.timeout(1000)
        auth.signOut();
        navigate("/login");
    }

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: 300 }}
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem disablePadding>
                    <a href="https://ibb.co/N1NyQ5w"><img style={{ width: "100%" }}
                        src="https://i.ibb.co/5YjRtmq/dilagaci.jpg"
                        alt="dilagaci" /></a>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        navigate("/adminpanel")
                    }}>
                        <ListItemIcon>
                            <SupervisorAccountIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Admin Panel"} />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <AccountTreeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Diagrams"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <PlaylistAddCheckIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Diagram Verification"} />
                    </ListItemButton>
                </ListItem>

            </List>
            <Divider />
            <List>

                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <PhoneInTalkIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Contact Us"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <QuestionAnswerIcon />
                        </ListItemIcon>
                        <ListItemText primary={"FAQ"} />
                    </ListItemButton>
                </ListItem>

            </List>

        </Box>
    );
    return (
        <header className="main-header main-header--one  clearfix">

            <div className="main-header--two__top clearfix">
                <div className="container">
                    <div className="main-header--two__top-inner clearfix">
                        <div className="main-header--two__top-left">
                            <ul className="main-header--two__top-contact-info list-unstyled">
                                <li className="main-header--two__top-contact-info-single">
                                    <div className="icon">
                                        <span className="icon-chat"></span>
                                    </div>
                                    <div className="text">
                                        <p><a href="tel:123456789">+90 366 280 14 97</a></p>
                                    </div>
                                </li>

                                <li className="main-header--two__top-contact-info-single">
                                    <div className="icon">
                                        <span className="icon-message-1"></span>
                                    </div>
                                    <div className="text">
                                        <p><a href="mailto:dilagaci2021@gmail.com">dilagaci2021@gmail.com</a></p>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="main-header--two__top-right clearfix">
                            {!auth.user ?
                                <div className="main-header--two__top-right-login-register">
                                    <ul className="list-unstyled">
                                        <li><Link to={"/login"}>Login</Link></li>
                                        <li><Link to={"/register"}>Register</Link></li>
                                    </ul>
                                </div>
                                : null
                            }
                            <div className="main-header--two__top-right-social-link">
                                <ul className="list-unstyled">
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fab fa-brands fa-linkedin"></i></a></li>
                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="main-header-one__bottom clearfix">

                <div className="container">

                    <div className="main-header-one__bottom-inner clearfix">

                        <nav className="main-menu main-menu--1">

                            <div className="main-menu__inner">

                            
                                <img src="assets/images/resources/langtreevector.png" alt="" className="responsive-image-header" />


                                <a className="mobile-nav__toggler" onClick={() => setIsOpen(true)}>
                                    <i className="fa fa-bars"></i>
                                </a>

                                <div className="left">

                                    <ul className="main-menu__list">
                                        <li className={`dropdown ${activePage === 'home' ? 'current' : ''}`}>
                                            <a href="" onClick={() => navigate('/')}>
                                                {translation.home}
                                            </a>
                                        </li>
                                        <li className={`dropdown ${activePage === 'courses' || activePage === 'coursesforstudent' ? 'current' : ''}`}>
                                            <a href="" onClick={() => navigate('/courses')}>
                                                {translation.courses}
                                            </a>
                                            <ul>
                                                <li><a href="#" onClick={() => navigate('/courses')}>{translation.admincoursepage}</a></li>

                                            </ul>
                                        </li>
                                        <li className={`dropdown ${activePage === 'ourteachers' || activePage === 'becometeacher' ? 'current' : ''}`}>
                                            <a href=""> {translation.teachers}</a>
                                            <ul>
                                                <li><a href="#" onClick={() => navigate('/teachers')}>{translation.ourteachers}</a></li>
                                                <li><a href="#" onClick={() => navigate('/becomeTeacher')}>{translation.becometeacher}</a></li>
                                            </ul>
                                        </li>
                                        <li className={`dropdown ${activePage === 'news' ? 'current' : ''}`}>
                                            <a href="" onClick={() => navigate('/news')}>
                                                {translation.news}
                                            </a>
                                        </li>
                                        <li className={`dropdown ${activePage === 'contact' ? 'current' : ''}`}>
                                            <a href="" onClick={() => navigate('/contact')}>
                                                {translation.contact}
                                            </a>
                                        </li>
                                    </ul>

                                    <ul >

                                    </ul>

                                    <ul className={`main-menu__list ${activePage === 'ourteachers' ? 'current' : ''}`}>

                                    </ul>

                                    <ul className={`main-menu__list ${activePage === 'becometeacher' ? 'current' : ''}`}>

                                    </ul>

                                    <ul className={`main-menu__list ${activePage === 'news' ? 'current' : ''}`}>

                                    </ul>

                                    <ul className={`main-menu__list ${activePage === 'contact' ? 'current' : ''}`}>

                                    </ul>
                                </div>

                                <div className="right">
                                    <div className="main-menu__right">
                                        <CustomizedSwitches />
                                        {auth.user ?
                                            <Box sx={{ flexGrow: 0 }}>
                                                <Tooltip title="Open settings">
                                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                        <Avatar src="/broken-image.jpg" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Menu
                                                    PaperProps={{
                                                        style: {
                                                            width: 250,
                                                        },
                                                    }}
                                                    sx={{ mt: '45px' }}
                                                    id="menu-appbar"
                                                    anchorEl={anchorElUser}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    keepMounted
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    open={Boolean(anchorElUser)}
                                                    onClose={handleCloseUserMenu}
                                                >
                                                    <MenuItem>

                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            <Grid item><Avatar style={{
                                                                border: '5px solid #0288d1', width: 56, height: 56
                                                            }}>{auth.user.email.toString().at(0)}</Avatar></Grid>
                                                            <Grid sx={{ mt: 1 }} item><Typography textAlign="center">{auth.user.email}</Typography></Grid>
                                                            <Grid sx={{ mt: 1 }} item> <VerifyChip /></Grid></Grid>



                                                    </MenuItem>
                                                    <Divider />
                                                    <MenuItem onClick={() => {
                                                        navigate("/account");
                                                        handleCloseUserMenu();
                                                    }}>
                                                        <Grid container direction="row" alignItems="center">
                                                            <Grid item xs={4}><PersonIcon sx={{ fontSize: 30 }} /></Grid>
                                                            <Grid item sx={{ mb: 0.4 }} ><Typography>{translation.account}</Typography></Grid>

                                                        </Grid>


                                                    </MenuItem>
                                                    <Divider />

                                                    <MenuItem onClick={() => {
                                                        navigate("/diagrams");
                                                        handleCloseUserMenu();
                                                    }}>
                                                        <Grid container direction="row" alignItems="center">
                                                            <Grid item xs={4}><PersonIcon sx={{ fontSize: 30 }} /></Grid>
                                                            <Grid item sx={{ mb: 0.4 }} ><Typography>{translation.diagrams}</Typography></Grid>

                                                        </Grid>


                                                    </MenuItem>
                                                    <Divider />
                                                    <MenuItem onClick={() => {
                                                        logout();
                                                        handleCloseUserMenu();
                                                    }}>
                                                        <Grid container direction="row" alignItems="center">
                                                            <Grid item xs={4}><LogoutIcon sx={{ fontSize: 30 }} /></Grid>
                                                            <Grid item sx={{ mb: 0.4 }} ><Typography>{translation.logout}</Typography></Grid>

                                                        </Grid>
                                                    </MenuItem>
                                                </Menu>
                                            </Box>
                                            : null
                                        }

                                    </div>
                                </div>

                            </div>
                        </nav>

                    </div>
                </div>
            </div>
            <div className={isOpen ? "mobile-nav__wrapper expanded" : "mobile-nav__wrapper"}>
                <div className="mobile-nav__overlay mobile-nav__toggler"></div>

                <div className="mobile-nav__content">
                    <span className="mobile-nav__close mobile-nav__toggler" onClick={() => setIsOpen(false)}>
                        <i className="fa fa-times"></i>
                    </span>

                    <div className="logo-box">
                        <a href="index.html" aria-label="logo image"><img src="assets/images/resources/mobilemenu_logo.png"
                            width="155" alt="" /></a>
                    </div>


                    <List
                        sx={{ width: '100%', maxWidth: 360, }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"

                    >
                        <Link to={'/'} className="course-link">
                            <ListItemButton>
                                <ListItemIcon>
                                    <BiHome color="white" />
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItemButton>
                        </Link>
                        <Divider />
                        <Link to={'/courses'} className="course-link">
                            <ListItemButton >
                                <ListItemIcon>
                                    <BsPersonWorkspace color="white" />
                                </ListItemIcon>
                                <ListItemText primary="Courses" />
                            </ListItemButton>
                        </Link>
                        <Divider />

                        <ListItemButton onClick={handleClick}>
                            <ListItemIcon>
                                <GiTeacher color="white" />
                            </ListItemIcon>
                            <ListItemText primary="Teachers" />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Divider />
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            <Link to={'/teachers'} className="course-link">
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <AiOutlineCaretRight color="white" />
                                    </ListItemIcon>
                                    <ListItemText primary="Our Teachers" />
                                </ListItemButton>
                                </Link>
                                <Link to={'/becomeTeacher'} className="course-link">
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <AiOutlineCaretRight color="white" />
                                    </ListItemIcon>
                                    <ListItemText primary="Become Teacher" />
                                </ListItemButton>
                                </Link>
                            </List>
                        </Collapse>
                        <Link to={'/news'} className="course-link">
                        <ListItemButton >
                            <ListItemIcon>
                                <BiNews color="white" />
                            </ListItemIcon>
                            <ListItemText primary="News" />
                        </ListItemButton>
                        </Link>
                        <Divider />
                        <Link to={'/contact'} className="course-link">
                        <ListItemButton >
                            <ListItemIcon>
                                <AiOutlineInbox color="white" />
                            </ListItemIcon>
                            <ListItemText primary="Contact" />
                        </ListItemButton>
                        </Link>
                        <Divider />
                    </List>

                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="icon-phone-call"></i>
                            <a href="mailto:dilagaci2021@gmail.com">dilagaci2021@gmail.com</a>
                        </li>
                        <li>
                            <i className="icon-letter"></i>
                            <a href="tel:+90 366 280 14 97">+90 366 280 14 97</a>
                        </li>
                    </ul>
                    <div className="mobile-nav__top">
                        <div className="mobile-nav__social">
                            <a href="#" className="fab fa-twitter"></a>
                            <a href="#" className="fab fa-facebook-square"></a>
                            <a href="#" className="fab fa-brands fa-linkedin"></a>
                            <a href="#" className="fab fa-instagram"></a>
                        </div>
                    </div>
                    <i className=""></i>
                </div>

            </div>
        </header>
    );

}

export default Navbar;