import {Box, FormControl, Grid, InputLabel, NativeSelect, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {SubCondition, VideoNode} from "../../api/NodeModels";

export function PatternRange({
                                 data,
                                 setter,
                                 indexes
                             }: { data: VideoNode[], setter: (newData: VideoNode[]) => void, indexes: number[] }) {
    const [minRange, setMinRange] = useState(data[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions![indexes[3]].value.substring(0, data[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions![indexes[3]].value.indexOf("-")))
    const [maxRange, setMaxRange] = useState(data[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions![indexes[3]].value.substring(data[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions![indexes[3]].value.indexOf("-")+1, data[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions![indexes[3]].value.length-1))

    let tmp: SubCondition[] = [...data[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions || []]
    const handleChange = (event: { target: { value: string } }) => {
        if (event.target.value == "1") {
            tmp[indexes[3]].type = "range"
        }
        if (event.target.value == "2") {
            tmp[indexes[3]].type = "text"
        }
        let temp = [...data]
        temp[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions = [...tmp]
        setter(temp)

    }
    const maxHandleControl = (e: { target: { value: string } }) => {
        const tmpRange =minRange+"-"+e.target.value
        let temp = [...data]
        temp[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions![indexes[3]].value=tmpRange
        setter(temp)
        setMaxRange(e.target.value)
    }
    const minHandleControl=(e: { target: { value: string } })=>{
        const tmpRange =e.target.value+"-"+maxRange
        let temp = [...data]
        temp[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions![indexes[3]].value=tmpRange
        setter(temp)
        setMinRange(e.target.value)
    }
    return (
        <>
            <Box sx={{px: 3}}>
                <Grid container direction={"row"} spacing={2}>
                    <Grid item>
                        <Typography sx={{pt: 3}}>
                            {tmp[indexes[3]].varName}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            </InputLabel>
                            <NativeSelect
                                defaultValue=
                                    {tmp[indexes[3]].type == "range" ? (
                                        1
                                    ) : (
                                        2
                                    )}
                                onChange={e => handleChange(e)}
                                inputProps={{
                                    name: 'rangeText',
                                }}
                            >
                                <option value={1}>Range</option>
                                <option value={2}>Text</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    {tmp[indexes[3]].type == "range" ?
                        <Grid item>
                            <Box>
                                <TextField
                                    type={"number"}
                                    onChange={e => minHandleControl(e)}
                                    value={minRange}
                                    margin="normal"
                                    id="min"
                                    label="Min"
                                    name="min"
                                />
                                <TextField
                                    type={"number"}
                                    onChange={e => maxHandleControl(e)}
                                    value={maxRange}
                                    sx={{mx: 5}}
                                    margin="normal"
                                    id="max"
                                    label="Max"
                                    name="max"
                                />
                            </Box>
                        </Grid>
                        :
                        <Grid item>
                            <TextField
                                value={data[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions![indexes[3]].value}
                                onChange={
                                    (event: React.ChangeEvent<HTMLInputElement>) => {
                                        const node: VideoNode[] = [...data]
                                        node[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions![indexes[3]].value = event.currentTarget.value
                                        setter(node)
                                    }
                                }
                                fullWidth
                                margin="normal"
                                id="text"
                                label="Text"
                                name="text"
                            />
                        </Grid>
                    }
                </Grid>
            </Box>

        </>)
}