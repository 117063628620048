import Navbar from "../../components/Navbar";
import {useAuth} from "../../hooks/useAuth";
import UserTable from "./UserTable";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";

function Home() {
    const auth = useAuth();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleDrawer = (value: boolean | ((prevState: boolean) => boolean)) => {
      setIsOpen(value);
    }
    // Redirect unauthorized users to login
    useEffect(() => {
        if (!auth.user || auth.user.permission < 40) {
            navigate("/login");
        }
    }, [])

    return (
        <Box sx={{mt:10}}>
            <Navbar toggleNavbarDrawer={handleToggleDrawer} data={"home"} />
            <UserTable/>
        </Box>

    );
}

export default Home;