import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGrid, GridApi, GridCellValue,
    GridToolbarContainer,
    GridToolbarExport, GridToolbarQuickFilter, GridValueGetterParams,
} from '@mui/x-data-grid';
import {
    Dialog,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
    Button,
    DialogActions
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import GavelIcon from '@mui/icons-material/Gavel';
import {GiPeaceDove} from "react-icons/gi";
import {AccountAPI} from "../../api/AccountAPI";
import {useAuth} from "../../hooks/useAuth";
import {useEffect, useState} from "react";
import {User} from "../../api/AccountModels";
import {PermissionButton} from "./PermissionButton";

function getColumns(
    updatePermission: (id: number, permission: number) => void,
    updateBan: (id: number, banned: boolean) => void,
    HandleClickOpen: (id:number) => void,
    deleteUser: () => void,
    UseHandleClose: () => void,
    returnIsTrue: () => boolean,
) {

    return [
        {field: 'id', headerName: 'ID', width: 90},
        {

            field: 'email',
            headerName: 'E-Mail',
            width: 200,
        },
        {
            field: 'birthday',
            headerName: 'Date of Birth',
            width: 100,
            valueGetter: (params: GridValueGetterParams) => {
                return params.value.substring(0, 10)

            }
        },
        {
            field: 'country',
            headerName: 'Country',
            width: 140,
        },
        {
            field: 'education',
            headerName: 'Education',
            width: 140,
        },
        {
            field: 'nativeLanguage',
            headerName: 'Native Language',
            width: 200,
        },
        {
            field: 'otherLanguages',
            headerName: 'Other Languages',
            width: 260,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 100,
            valueGetter: (params: GridValueGetterParams) => {
                return params.value.substring(0, 10)

            }
        },
        {
            field: 'permission',
            headerName: 'Permission',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => {
                switch (params.value) {
                    case 40:
                        return "Admin";
                    case 30:
                        return "Mod";
                    case 20:
                        return "Tutor";
                    case 10:
                        return "User";
                    case  0:
                        return "Unverified";
                    default:
                        return "Unknown";
                }
            }
        },
        {
            field: 'banned',
            headerName: 'Is Banned',
            width: 80
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 200,
            renderCell: (params: any) => {
                const onClick = (e: { stopPropagation: () => void; }) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const api: GridApi = params.api;
                    const thisRow: Record<string, GridCellValue> = {};

                    api
                        .getAllColumns()
                        .filter((c) => c.field !== '__check__' && !!c)
                        .forEach(
                            (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
                        );
                    return alert(JSON.stringify(thisRow, null, 4));
                };

                return <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >

                    {params.getValue(params.id, "banned") &&
                        <Grid item xs={4}>
                            <IconButton onClick={() => updateBan(params.getValue(params.id, "id"), false)}
                                        color={"success"}>
                                <GiPeaceDove/>
                            </IconButton>
                        </Grid>
                    }
                    {!(params.getValue(params.id, "banned")) &&
                        <Grid item xs={4}>
                            <IconButton onClick={() => updateBan(params.getValue(params.id, "id"), true)}
                                        color={"error"}>
                                <GavelIcon/>
                            </IconButton>
                        </Grid>
                    }

                    <Grid item xs={4}>
                        <Box>
                            <PermissionButton updatePermission={updatePermission}
                                              userID={params.getValue(params.id, "id")}/>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton onClick={() => HandleClickOpen(params.getValue(params.id, "id"))} color={"error"}>
                            <DeleteIcon/>
                        </IconButton>
                    </Grid>
                    <div>
                        <Dialog
                            open={returnIsTrue()}
                            onClose={UseHandleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Are you sure you want to delete this account?"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Deleted users cannot be recovered.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={UseHandleClose}>Disagree</Button>
                                <Button onClick={() =>  {
                                    deleteUser();
                                    UseHandleClose();
                                }} autoFocus>
                                    Agree
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                </Grid>;
            },
        },

    ];
}


function CustomToolbar() {
    return (

        <GridToolbarContainer>
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
            >
                <Grid item xs={10.5}>
                    <GridToolbarExport/>
                </Grid>
                <Grid item xs={1.5}>
                    <GridToolbarQuickFilter/>
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default function UserTable() {
    const [users, setUsers] = useState<User[]>([]);
    const auth = useAuth()
    const [permissionID, setPermissionID] = useState(0)
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(0);
    const HandleClickOpen = async (id:number) => {
        setOpen(true);
        await setId(id)
    };
    const UseHandleClose = () => {
        setOpen(false);
    };
    const returnIsTrue = () => {
        return open;
    };
    const updateAllUsers = async () => {
        const token = await auth.getToken();
        if (token === null) {
            return;
        }
        setUsers(await AccountAPI.getAllUsers(token));
    }

    const updatePermission = async (id: number, permission: number) => {
        console.log({id, permission});
        await AccountAPI.setPermission(id, permission, (await auth.getToken())!)
        updateAllUsers();
    }

    const updateBan = async (id: number, banned: boolean) => {
        console.log({id, banned});
        await AccountAPI.setBan(id, banned, (await auth.getToken())!);
        updateAllUsers();

    }


    const deleteUser = async () => {
        console.log({id});
        await AccountAPI.setDelete(id, (await auth.getToken())!)
        updateAllUsers();
    }


    useEffect(() => {
        updateAllUsers();
    }, [])

    useEffect(() => {
        console.log(permissionID)
    }, [permissionID])

    return (
        <Box sx={{mt: 5}}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"

            >
                <Grid item xs={12} sx={{width: '90%'}}>
                    <Box sx={{height: 800}}>
                        <DataGrid
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: {debounceMs: 500},
                                },
                            }}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                            rows={users}
                            columns={getColumns(updatePermission, updateBan, HandleClickOpen, deleteUser, UseHandleClose, returnIsTrue)}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection={false}
                            disableSelectionOnClick
                            experimentalFeatures={{newEditingApi: true}}
                        ></DataGrid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
