import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from "react";
import axios from "axios";
import zxcvbn from "zxcvbn";
import * as EmailValidator from 'email-validator';
import { AccountAPI } from "../../api/AccountAPI";
import FormControl from "@mui/material/FormControl";
import CloseIcon from '@mui/icons-material/Close';
import countries_en from '../../languagedata/countries/countries_en.json'
import countries_tr from '../../languagedata/countries/countries_tr.json'

import { registerLocale, getNames } from "i18n-iso-countries"

import ISO6391 from "iso-639-1"


import {
  Alert, Card, CardContent, Chip, Collapse,
  FormLabel, IconButton, InputAdornment,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import {useLocation, useNavigate} from "react-router-dom";
import { CommonFunctions } from "../../api/CommonFunctions";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import HomeButton from "../../components/HomeButton";
import ReCAPTCHA from "react-google-recaptcha";
import { useAuth } from '../../hooks/useAuth';
import Navbar from '../../components/Navbar';

const Reginew: React.FC = () => {

  interface State {
    amount: string;
    password: string;
    weight: string;
    weightRange: string;
    showPassword: boolean;
  }

  const [values, setValues] = React.useState<State>({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  let navigate = useNavigate();
  const location = useLocation()
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [signup, setSignup] = useState(0)
  const [progress, setProgress] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [date, setDate] = useState(new Date())
  const [gender, setGender] = useState('')
  const [genderError, setGenderError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordColor, setPasswordColor] = useState("primary")
  const [countryError, setCountryError] = useState(false)
  const [educationError, setEducationError] = useState(false)
  const [nativeError, setNativeError] = useState(false)
  const [helperText, setHelperText] = useState<string | undefined>(undefined)
  const [country, setCountry] = React.useState('');
  const [education, setEducation] = React.useState('');
  const [native, setNative] = React.useState('');
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [invalidCaptcha, setInvalidCaptcha] = useState(false)
  const auth = useAuth();
  const [invalidLogin, setInvalidLogin] = useState(false)
  const [isSignInDisabled, setIsSignInDisabled] = useState(false);
  const [registerAlertOpen, setRegisterAlertOpen] = useState(false); //register disabled alert
  const [showRegisterForm, setShowRegisterForm] = useState(!location.pathname.includes("register"));
  const theme = createTheme();
  registerLocale(countries_en);
  registerLocale(countries_tr);


  // TODO: select language here
  const countries: string[] = Object.values(getNames("en", { select: "official" }));
  const languages: string[] = ISO6391.getAllNativeNames()

  const [extraLanguage, setExtraLanguage] = React.useState<string[]>([]);

  const onChangeCaptcha = (value: string | null) => {
    console.log("Captcha value:", value);
    setCaptchaToken(value);
  }

  const handleGenderChange = (event: SelectChangeEvent) => {
    setGender(event.target.value as string);
  };

  const handleEducationChange = (event: SelectChangeEvent) => {
    setEducation(event.target.value as string);
  };
  const handleNativeChange = (event: SelectChangeEvent) => {
    setNative(event.target.value as string);
  };

  const [value, setValue] = React.useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  );
  const handleDateChange = (newValue: Date | null) => {
    setDate(newValue!)
    setValue(newValue);
  };
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value as string);
  };

  const handleExtraLanguageChange = (event: SelectChangeEvent<typeof extraLanguage>) => {
    const { target: { value } } = event;
    setExtraLanguage(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    if (process.env.REACT_APP_DISABLE_REGISTER === "true"){
      setRegisterAlertOpen(true);
      event.preventDefault();
      return;
    }
    setEmailError(false);
    setCountryError(false);
    setEducationError(false);
    setNativeError(false);
    setPasswordColor("primary");
    setGenderError(false);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const _email = data.get('email') as string;
    const _password = data.get('password') as string;
    const isEmailValid = EmailValidator.validate(_email);
    const score = zxcvbn(_password as any).score;

    if (_email == null || _email === "" || !isEmailValid) {
      setEmailError(true);
      return;
    }
    if (_password == null || _password === "" || score < 3 || _password.length < 8) {
      setPasswordColor("error");
      return;
    }
    if (country == null || country === "") {
      setCountryError(true);
      return;
    }
    if (education == null || education === "") {
      setEducationError(true);
      return;
    }
    if (native == null || native === "") {
      setNativeError(true);
      return;
    }
    if (gender == null || gender === "") {
      setGenderError(true);
      return;
    }

    const captchaToken = await recaptchaRef.current!.executeAsync();

    if (captchaToken === null) {
      setInvalidCaptcha(true);
      return;
    } else {
      setInvalidCaptcha(false);
    }

    setProgress(true);
    if (await AccountAPI.register(_email, _password, captchaToken!, date, country, gender, education, native, extraLanguage)) {
      setSignup(1);
      navigate("/login");
    } else {
      setSignup(2);
    }
    setProgress(false);
   
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    setEmailError(false);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const _email = data.get('email') as string;
    const _password = data.get('password') as string;
    const isEmailValid = EmailValidator.validate(_email);

    const captchaToken = await recaptchaRef.current!.executeAsync();

    if (captchaToken === null) {
      setInvalidCaptcha(true);
      return;
    } else {
      setInvalidCaptcha(false);
    }


    // TODO: validate other info
    if (_email == null || _email == "" || !isEmailValid) {
      setEmailError(true);
      return;
    }
    if (await auth.signIn(_email as string, _password as string, captchaToken as string)) {
      navigate(`/`);
    } else {
      setInvalidLogin(true)
    }
  };

  const passwordOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
    const score = zxcvbn(event.currentTarget.value).score;
    if (event.currentTarget.value == "") {
      setPasswordColor("primary");
      setHelperText(undefined);
    } else if (score < 3 || event.currentTarget.value.length < 8) {
      setPasswordColor("warning");
      setHelperText("Password is weak");
      console.log(score)
    } else {
      setPasswordColor("success");
      setHelperText("Strong password!");
    }
  }



  const handleToggleForm = () => {
    setShowRegisterForm(!showRegisterForm);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#e8f8f6";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const typographyStyle = {
    fontFamily: 'Kumbh Sans',
    fontWeight: 'bold',
    fontSize: '24px',
    paddingLeft: '16px',
    paddingTop: '16px',
  };
  const additionalTextStyle = {
    fontFamily: 'Kumbh Sans',
    color: '#757783',
    fontSize: '16px',
    paddingLeft: '16px',
  };
  const buttonStyle = {
    backgroundColor: '#60c6c1',
    fontSize: '14px',
    width: '60%',
    height: '50px',
    marginTop: '12px',
    fontFamily: 'Kumbh Sans',
    '&:hover': {
      backgroundColor: '#37a9a3',
      color: '#ffffff',
    },
    fontWeight: 'bold',
    borderRadius: '10px',
    marginX: '5px',
    color: '#ffffff',
  };
  const buttonStyle2 = {
    backgroundColor: showRegisterForm ? '#60c6c1' : '#e3ebf9',
    fontSize: '14px',
    width: '60%',
    height: '50px',
    marginTop: '12px',
    fontFamily: 'Kumbh Sans',
    '&:hover': {
      backgroundColor: showRegisterForm ? '#37a9a3' : '#e3ebf9',
      color: showRegisterForm ? '#ffffff' : '#60c6c1',
    },
    fontWeight: 'bold',
    borderRadius: '10px',
    marginX: '5px',
    color: showRegisterForm ? '#ffffff' : '#1c1e5d',
  };
  const buttonStyle3 = {
    backgroundColor: showRegisterForm ? '#e3ebf9' : '#60c6c1',
    fontSize: '14px',
    width: '60%',
    height: '50px',
    marginTop: '12px',
    fontFamily: 'Kumbh Sans',
    '&:hover': {
      backgroundColor: showRegisterForm ? '#e3ebf9' : '#37a9a3',
      color: showRegisterForm ? '#60c6c1' : '#ffffff',
    },
    fontWeight: 'bold',
    borderRadius: '10px',
    marginX: '5px',
    color: showRegisterForm ? '#1c1e5d' : '#ffffff',
  };
  const buttonStyle4 = {
    backgroundColor: '#60c6c1',
    fontSize: '14px',
    width: '20%',
    height: '30px',
    fontFamily: 'Kumbh Sans',
    '&:hover': {
      backgroundColor: '#37a9a3',
      color: '#ffffff'
    },
    fontWeight: 'bold',
    borderRadius: '10px',
    marginX: '5px',

    color: '#ffffff'
  };
  const cardStyle = {
    boxShadow: '0px 0px 8px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    height: '850px',

  };
  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  };
  const imageContainerStyle = {
    display: { xs: 'none', md: 'flex', lg: 'flex' },
    justifyContent: 'center',
    alignItems: 'center',
    height: '83vh',
  };


  return (
    <>
      <Collapse in={registerAlertOpen} sx={{position: "fixed", bottom: 0, left: "50%", transform: "translate(-50%, -50%)"}}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setRegisterAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Registers are currently disabled!
        </Alert>
      </Collapse>
      <Container maxWidth="lg" sx={{ marginY: '20px' }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Card sx={cardStyle}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{ backgroundColor: '#e8f8f6', display: { xs: 'none', md: 'flex', lg: 'flex' }, flexDirection: 'column', alignItems: 'center' }}>
                  <Box sx={imageContainerStyle}>
                    <img src="https://i.ibb.co/rZZjHBp/details-1-office-worker.png" alt="register" style={{ maxWidth: '90%', height: 'auto' }} />


                  </Box>
                  <Typography variant="body1">
                    Forgot your password?    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      sx={buttonStyle4}
                      onClick={() => {
                        navigate("/forgotpassword")
                      }}
                    >
                      RESET
                    </Button>
                  </Typography>

                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom sx={typographyStyle}>
                    {showRegisterForm ? 'Create a free account' : 'Login to your account'}
                  </Typography>

                  <Typography sx={additionalTextStyle}>
                    {!showRegisterForm
                      ? 'A few clicks away from creating your account.'
                      : 'Login to access your account.'}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      sx={buttonStyle2}
                      onClick={handleToggleForm}
                    >
                      LOGIN
                    </Button>
                    <Button
                      onClick={handleToggleForm}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      sx={buttonStyle3}
                    >
                      SIGNUP
                    </Button>
                  </Box>

                  {!showRegisterForm ? (
                    <Box component="form" noValidate onSubmit={handleRegister} sx={{ mt: 3, marginX:'8px' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            error={emailError}
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.currentTarget.value)}

                          />
                        </Grid>
                        <Grid item xs={12}>

                          <FormControl sx={{ width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput

                              name="password"
                              id="outlined-adornment-password"
                              color={passwordColor as any}
                              error={passwordColor == "error" ? true : false}
                              type={values.showPassword ? 'text' : 'password'}
                              autoComplete="new-password"
                              value={password}
                              onChange={passwordOnChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password"
                            />
                            <Typography variant="subtitle2">{helperText}</Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={3}>
                              <DesktopDatePicker
                                inputFormat="MM/dd/yyyy"
                                label="Date of birth"
                                value={value}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} />}
                              ></DesktopDatePicker>
                            </Stack>
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel>Country</InputLabel>
                            <Select
                              value={country}
                              label="Country"
                              error={countryError}
                              onChange={handleCountryChange}
                            >
                              {countries.map((country) => (
                                <MenuItem
                                  key={country}
                                  value={country}
                                >
                                  {country}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel>Gender</InputLabel>
                            <Select
                              value={gender}
                              error={genderError}
                              label="Gender"
                              onChange={handleGenderChange}
                            >
                              <MenuItem value={"Female"}>Female</MenuItem>
                              <MenuItem value={"Male"}>Male</MenuItem>
                              <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                          </FormControl>

                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Education</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={education}
                              error={educationError}
                              label="Education"
                              onChange={handleEducationChange}
                            >
                              <MenuItem value={"Illiterate"}>Illiterate</MenuItem>
                              <MenuItem value={"Primary School"}>Primary School</MenuItem>
                              <MenuItem value={"Secondary School"}>Secondary School</MenuItem>
                              <MenuItem value={"High School"}>High School</MenuItem>
                              <MenuItem value={"Undergraduate"}>Undergraduate</MenuItem>
                              <MenuItem value={"Graduate"}>Graduate</MenuItem>
                              <MenuItem value={"Bachelor"}>Bachelor</MenuItem>
                              <MenuItem value={"Master"}>Master</MenuItem>
                              <MenuItem value={"Doctorate"}>Doctorate</MenuItem>
                            </Select>
                          </FormControl>

                        </Grid>
                        <Grid item xs={12}>
                          <FormControl sx={{ width: "100%" }}>
                            <InputLabel>Native Language</InputLabel>
                            <Select
                              defaultValue=""
                              error={nativeError}
                              label="Native Language"
                              onChange={handleNativeChange}
                            >
                              {languages.map((language) => (
                                <MenuItem
                                  key={Math.random()}
                                  value={language}
                                >
                                  {language}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl sx={{ width: "100%" }}>
                            <InputLabel >Which other languages dou you know?</InputLabel>
                            <Select
                              multiple
                              value={extraLanguage}
                              onChange={handleExtraLanguageChange}
                              input={<OutlinedInput label="Which other languages dou you know?" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={Math.random()} label={value} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 250,
                                  },
                                },
                              }}
                            >
                              {languages.map((language) => (
                                <MenuItem
                                  key={Math.random()}
                                  value={language}
                                  style={{ fontWeight: extraLanguage.indexOf(language) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium }}
                                >
                                  {language}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label="Privacy Policy"
                          />
                        </Grid>

                      </Grid>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Lf43bYnAAAAAG0SkJCww0wA2yugn-P1X5AASoGL"
                        size="invisible"
                        onChange={onChangeCaptcha}
                      />
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          type="submit"
                          fullWidth
                          sx={buttonStyle}
                          variant="contained"
                        >
                          Sign Up
                        </Button>
                      </Box>
                      {signup === 1 &&
                        <Alert severity="success">
                          Your account has been created
                        </Alert>}
                      {signup === 2 &&
                        <Alert severity="error">
                          An error occurred while creating your account
                        </Alert>}
                      {invalidCaptcha &&
                        <Alert severity="error">
                          Captcha seems to be invalid!
                        </Alert>
                      }

                    </Box>
                  ) : (
                    <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 20, marginX:'8px' }}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        error={emailError}
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.currentTarget.value)}
                        sx={{ paddingBottom: '30px' }}
                      />


                      <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput

                          name="password"
                          id="outlined-adornment-password"
                          type={values.showPassword ? 'text' : 'password'}
                          autoComplete="new-password"
                          value={password}
                          onChange={passwordOnChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                      </FormControl>
                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey="6Lf43bYnAAAAAG0SkJCww0wA2yugn-P1X5AASoGL"
                          size="invisible"
                          onChange={onChangeCaptcha}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={buttonStyle}
                        disabled={isSignInDisabled}
                      >
                        LOGIN
                      </Button>
                      </Box>

                      {invalidLogin &&
                        <Alert severity="error">
                          Your email or password seems to be invalid!
                        </Alert>
                      }
                      {invalidCaptcha &&
                        <Alert severity="error">
                          Captcha seems to be invalid!
                        </Alert>
                      }


                    </Box>
                  )}


                  <div style={buttonContainerStyle}>

                  </div>
                  <Box sx={{ display: { xs: 'flex', md: 'none', lg: 'none', marginLeft: '30px' }, flexDirection: 'column', alignItems: 'center' }} >
                    <Typography variant="body1">
                      Forgot your password?
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        sx={buttonStyle4}
                        onClick={() => {
                          navigate("/forgotpassword")
                        }}
                      >
                        RESET
                      </Button>
                    </Typography>
                  </Box>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Lf43bYnAAAAAG0SkJCww0wA2yugn-P1X5AASoGL"
          size="invisible"
          onChange={onChangeCaptcha}
        />
      </Container>
    </>
  );
};

export default Reginew;
