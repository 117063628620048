import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Signup from './pages/signup/Signup';
import ForgotPassword from "./pages/forgotpassword/ForgotPassword";
import {ProvideAuth} from "./hooks/useAuth";

import RegisterLanguageList from './pages/signup/RegisterLanguageList';
import ResetPassword from "./pages/resetPassword/ResetPassword";
import Verify from "./pages/verfiy/Verify";
import AdminPanel from "./pages/adminpanel/AdminPanel";

import {Button, createTheme, ThemeProvider} from "@mui/material";
import Diagrams from "./pages/diagrams/Diagrams";
import useLocalStorage from 'use-local-storage'
import AddNodeMain from "./pages/addNode/addNodeMain";
import CourseDetails from './pages/courseDetails/CourseDetails';


import { Player } from './pages/player/Player';
import Reginew from './pages/reginew/Reginew';
import Maintenance from "./pages/maintenance/Maintenance";
import Pronew from './pages/pronew/Pronew';
import NotFound from './pages/errorPages/NotFound';
import Courses from './pages/courses/Courses';
import BecomeTeacher from './pages/becomeTeacher/BecomeTeacher';
import Contact from './pages/contact/Contact';
import Teachers from './pages/teachers/Teachers';
import News from './pages/news/News';




function App() {
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
  const maintenance = process.env.REACT_APP_MAINTENANCE_MODE === "true";
  return (
        maintenance ?
        <Maintenance/>:
        <ProvideAuth>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home/>}/>
              {/*<Route path="login" element={<Login/>}/>*/}
              <Route path="resetPassword/:code" element={<ResetPassword/>}/>
              {/*<Route path="signup" element={<Signup/>}/>*/}
              <Route path="forgotPassword" element={<ForgotPassword/>}/>
              {/*<Route path="account" element={<Account/>}/>*/}
              <Route path="listview" element={<RegisterLanguageList/>}/>
              <Route path="verify/:code" element={<Verify/>}/>
              <Route path="adminpanel" element={<AdminPanel/>}/>
              <Route path="diagrams" element={<Diagrams/>}/>
              <Route path="courses" element={<Courses/>}/>
              <Route path="player/:diagramID" element={<Player/>}/>
              <Route path="addNode/:id" element={<AddNodeMain/>}/>
              <Route path="login" element={<Reginew/>}/>
              <Route path="register" element={<Reginew/>}/>
              <Route path="account" element={<Pronew/>}/>
              <Route path="becomeTeacher" element={<BecomeTeacher/>} />
              <Route path="contact" element={<Contact />} />
              <Route path="teachers" element={<Teachers />} />
              <Route path="news" element={<News />} />
              <Route path="courseDetails/:id" element={<CourseDetails />} /> {/* Güncellenen yol */}
              <Route path="*" element={<NotFound/>}/>

            </Routes>
          </BrowserRouter>
        </ProvideAuth>
  );
}

export default App;
