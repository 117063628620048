export const languages = [
    "AF	Afrikaans",
    "SQ	Albanian",
    "AR	Arabic",
    "HY	Armenian",
    "AZ	Azerbaijani",
    "EU	Basque Spanish",
    "BE	Belarusian",
    "BN	Bengali",
    "BS	Bosnian",
    "BG	Bulgarian",
    "CA	Catalan Spanish",
    "CE	Cebuano",
    "HA	Chadic",
    "ZH	Chinese (Mandarin)",
    "CO	Colombian Spanish",
    "HR	Croatian",
    "CS	Czech",
    "DA	Danish",
    "NL	Dutch",
    "-	English American",
    "-	English Australian",
    "-	English Irish",
    "-	English Indian",
    "-	English Scottish",
    "-	English UK",
    "EO	Esperanto",
    "ET	Estonian",
    "FR	European French",
    "ES	European Spanish",
    "FL	Filipino",
    "FI	Finnish",
    "MG	Fiteny Malagasy",
    "-	French Canadian",
    "IG	Igbo",
    "GA	Irish",
    "GL	Galician",
    "KA	Georgian",
    "DE	German",
    "EL	Greek",
    "GU	Gujarati",
    "HT	Haitian Creole",
    "HA	Hausa",
    "HE	Hebrew",
    "HI	Hindi",
    "HM	Hmong",
    "HU	Hungarian",
    "IS	Icelandic",
    "IN	Indonesian",
    "IT	Italian",
    "JA	Japanese",
    "JV	Javanese",
    "KN	Kannada",
    "KK	Kazakh",
    "KM	Khmer",
    "KO	Korean",
    "LO	Lao",
    "LA	Latin",
    "LV	Latvian",
    "LT	Lithuanian",
    "MK	Macedonian",
    "MS	Malay",
    "ML	Malayalam",
    "MT	Maltese",
    "MI	Te Reo Maori",
    "MR	Marathi",
    "-	Mexican Spanish",
    "MN	Mongolian",
    "NE	Nepalese",
    "NO	Norwegian",
    "NY	Nyanja",
    "PL	Polish",
    "PT	Portuguese",
    "BR	Portuguese Brazilian",
    "PA	Punjabi",
    "RO	Romanian",
    "RU	Russian",
    "GD	Scottish Gaelic",
    "SR	Serbian",
    "SI	Sinhala",
    "SK	Slovak",
    "SL	Slovenian",
    "SO	Somali",
    "ST	Southern Sotho",
    "SU	Sudanese",
    "SW	Swahili",
    "SV	Swedish",
    "TG	Tajik",
    "TA	Tamil",
    "TE	Telugu",
    "TH	Thai",
    "TR	Turkish",
    "UK	Ukrainian",
    "UR	Urdu",
    "UZ	Uzbek",
    "VI	Vietnamese",
    "YI	Yiddish",
    "YO	Yoruba",
    "ZU	Zulu",

];