import {Box, Grid, IconButton, Paper, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {PatternRange} from "./patternRange";
import {JumpCondition, SubCondition, VideoNode} from "../../api/NodeModels";

export function PatternNode({
                                data,
                                setter,
                                indexes
                            }: { data: VideoNode[], setter: (newData: VideoNode[]) => void, indexes: number[] }) {

    const handleControl = (e: { target: { value: string } }) => {
        let nextTemp: string[] = [];
        let tmp : (SubCondition[]) = [...data[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions||[]]

        for (let i = 0; i < e.target.value.length; i++) {
            if (e.target.value[i] == "{") {
                let temp = ""
                i++
                while (e.target.value[i] != "}" && i < (e.target.value.length)) {
                    temp = temp + e.target.value[i]
                    i++
                }
                if (temp.length > 0 && i < e.target.value.length) {
                    nextTemp.push(temp)
                }
            }
        }
        for (let j = 0; j < tmp.length; j++) {
            tmp[j].varName=nextTemp[j]
        }
        for (let i = tmp.length; i < nextTemp.length; i++) {
            tmp.push({type:"range",value:"",varName:nextTemp[i]})
        }
        if (tmp.length>nextTemp.length)
            tmp.splice(nextTemp.length)
        let temp=[...data]
        temp[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions=[...tmp]
        temp[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].value=e.target.value
        setter(temp)

    }
    return (
        <Paper elevation={2}
            sx={{
                borderRadius:5,
                backgroundColor: "white",
                px: 1,
                py:2
            }}
        >
            <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Grid item xs={1}>
                    <Typography fontSize={20}>
                        Pattern:
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        multiline
                        sx={{pr:3}}
                        value={data[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].value}
                        onChange={e => handleControl(e)}
                        margin="normal"
                        required
                        fullWidth
                        id="patternText"
                        name="patternText"
                    />
                </Grid>

                <Grid item xs={1}>
                    <IconButton
                                aria-label="delete"  onClick={()=>{
                        const tmp: VideoNode[] = [...data]
                        tmp[indexes[0]].jumps[indexes[1]].conditions.splice(indexes[2], 1)
                        setter(tmp)
                    }
                    }>
                        <DeleteIcon/>
                    </IconButton>
                </Grid>
            </Grid>
            {
                data.length > 0 ? (
                        data[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].subConditions?.map((mapArray,index) => (
                                <Box key={index}>
                                    <PatternRange data={data} setter={setter} indexes={[...indexes,index]}/>
                                </Box>
                            )
                        )
                    )
                    :
                    null
            }
        </Paper>
    )
}