import { useEffect, useRef, useState } from "react";
import { Jump, VideoNode } from "../../api/NodeModels";
import { NodeAPI } from "../../api/NodeAPI";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { Diagram } from "../../api/DiagramModels";
import { DiagramAPI } from "../../api/DiagramAPI";
import ReactPlayer from "react-player/vimeo";
import { Box, Button, Card, Chip, CircularProgress, Container, Divider, Grid, IconButton, keyframes, List, ListItem, ListItemIcon, ListItemText, Modal, Popover, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { VideoLoader } from "./VideoLoader";
import { selectJump } from "./JumpSelector";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import MicWarningDialog from "./MicWarningDialog";
import MicIcon from '@mui/icons-material/Mic';
import { ProgressAPI } from "../../api/ProgressAPI";
import { IoIosArrowBack } from 'react-icons/io';
import { BiErrorAlt } from 'react-icons/bi';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BiErrorCircle } from 'react-icons/bi';
const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.8);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)
  }
`;

export function Player() {
  useEffect(() => {
    // Disable scrolling
    document.body.style.overflow = 'hidden';
    document.body.style.backgroundColor = '#e8f8f6';

    return () => {
      // Enable scrolling
      document.body.style.overflow = '';
      document.body.style.backgroundColor = '';
    };
  }, []);
  const auth = useAuth();
  const { diagramID } = useParams()
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const theme = useTheme();

  const [diagramCompleted, setDiagramCompleted] = useState<boolean>(false);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [videoEnded, setVideoEnded] = useState(false);

  const [allNodes, setAllNodes] = useState<Record<string, VideoNode>>({});
  const [diagram, setDiagram] = useState<Diagram>(null!);
  const [activeNode, setActiveNode] = useState<VideoNode | null>(null)
  const [nextNodes, setNextNodes] = useState<Array<VideoNode>>([]); // nodes to preload
  const ref = useRef<ReactPlayer>(null!);
  const [errorText, setErrorText] = useState<Array<{ code: string | undefined; text: string; isCorrect: boolean }>>([]);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable
  } = useSpeechRecognition();


  // Fetch nodes and diagram
  useEffect(() => {
    const tempFunc = async () => {
      const _token = await auth.getToken();
      if (_token === null) { // giriş yapılmamış
        console.log("Can't use player without logging in!");
        navigate("/login");
        return;
      }

      if (diagramID === undefined || isNaN(diagramID as any)) { // url eksik/bozuk
        console.log("Diagram ID is missing in url!");
        navigate("/progress");
        return;
      }

      setToken(_token);

      const parsedDiagramID = parseInt(diagramID as string);
      const tempNodes = await NodeAPI.nodesOfDiagram(_token, parsedDiagramID);
      const tempDiagram = await DiagramAPI.getDiagram(_token, parsedDiagramID);

      if (tempDiagram === null) { // diagram bulunamadı?
        console.log("Diagram not found!");
        navigate("/courses");
        return;
      }

      const mappedNodes: Record<string, VideoNode> = {}
      tempNodes.forEach((videoNode, i) => {
        mappedNodes[videoNode.code] = videoNode;
        if (videoNode.error3) {
          const e3Node: VideoNode = {
            code: videoNode.code + "-e3",
            diagramId: videoNode.diagramId,
            error3: "",
            id: -1,
            jumps: videoNode.jumps,
            videoUrl: videoNode.error3
          }
          mappedNodes[videoNode.code + "-e3"] = e3Node;
        }
        let c = 0;
        for (let jump of videoNode.jumps) {
          console.log("jump" + c)
          if (jump.error6) {
            console.log("jump" + c + "+")
            const e6Node: VideoNode = {
              code: videoNode.code + "-e6-" + c,
              diagramId: videoNode.diagramId,
              error3: "",
              id: -1,
              jumps: [{ target: jump.target, error6: "", conditions: [] }],
              videoUrl: jump.error6
            }
            mappedNodes[videoNode.code + "-e6-" + c] = e6Node;
            c += 1;
          }
        }
      });

      setAllNodes(mappedNodes);
      setDiagram(tempDiagram);


      if (tempDiagram.startNode) {
        // find start node
        const startNode = tempNodes.find((value) => value.id == tempDiagram.startNode);
        const preloadVideos: Array<VideoNode> = [];

        if (startNode) {
          // prepare preload videos for first node
          startNode.jumps.forEach((j) => {
            preloadVideos.push(mappedNodes[j.target])
          })
          // preload e3
          try {
            preloadVideos.push(mappedNodes[startNode.code + "-e3"]);
          } catch (e) {
            console.log("Can't preload e3")
          }
          // preload e6s
          try {
            const randomNextVideoNo = Math.floor(Math.random() * startNode.jumps.length);
            const randomNextVideoCode = startNode.code + "-e6-" + randomNextVideoNo;
            console.log({ randomNextVideoCode, mappedNodes })
            preloadVideos.push(mappedNodes[randomNextVideoCode])
          } catch (e) {
            console.log("Can't preload random e6")
          }

          setActiveNode(startNode);
          setNextNodes(preloadVideos);
          console.log("starting complete")
        } else {
          // TODO: error ?
        }
      } else {
        // TODO: error ?
      }
    }
    tempFunc();

  }, [])


  useEffect(() => {
    if (listening) {
      console.log("listening")
      return;
    }
    if (transcript) {
      let target: string = "";
      let newActiveNode: VideoNode;

      if (token == "")
        navigate("/login");

      console.log("not listening now")
      if (activeNode!.jumps[0].conditions.length == 0) {
        // Şart yok direkt sonraki videoya geç (video end handle)
        target = activeNode?.jumps[0].target!;
        ProgressAPI.updateProgress(+(diagramID!), +(allNodes[target].id), token);
        console.log("şart yok")

      } else {
        console.log("selecting jump")

        const userInput: string = transcript;
        console.log({userInput})
        const selectedJump = selectJump(activeNode!.jumps, userInput);
        console.log(selectedJump);
        if (selectedJump == null) {
          console.log("selected jump: null")

          // 1-2: nothing (restart)
          // 3: 3 err video
          // 4-5: 3 error video restart
          // 6  : 6 error video
          if (errorCount < 2) {
            console.log("error 1-2")
            ref.current.seekTo(0); //restart vid here
            setErrorCount(errorCount + 1);
            const errorItem = {
              code: '0',
              text: transcript,
              isCorrect: false
            };

            // Mevcut errorText listesine bu nesneyi ekleyin
            setErrorText((prevErrorText) => [...prevErrorText, errorItem]);

            setVideoEnded(false);
            return;
          } else if (errorCount == 2) {
            console.log("error 3");
            target = activeNode?.code + "-e3";
            setErrorCount(errorCount + 1);
            const errorItem = {
              code: '1',
              text: transcript,
              isCorrect: false
            };

            // Mevcut errorText listesine bu nesneyi ekleyin
            setErrorText((prevErrorText) => [...prevErrorText, errorItem]);

          } else if (errorCount >= 2 && errorCount <= 4) {
            console.log("error 4-5");
            ref.current.seekTo(0); //restart vid here
            setErrorCount(errorCount + 1);
            const errorItem = {
              code: '0',
              text: transcript,
              isCorrect: false
            };

            // Mevcut errorText listesine bu nesneyi ekleyin
            setErrorText((prevErrorText) => [...prevErrorText, errorItem]);
            setVideoEnded(false);
            return;
          } else {
            console.log("error 6");
            const e6Code = nextNodes.find((value) => {
              return value.code.includes("-e6-");
            })?.code
            console.log({ e6Code });
            target = e6Code!;
            setErrorCount(0);
            const errorItem = {
              code: '2',
              text: transcript,
              isCorrect: false
            };

            // Mevcut errorText listesine bu nesneyi ekleyin
            setErrorText((prevErrorText) => [...prevErrorText, errorItem]);

          }

        } else {
          console.log("jump select success")
          setErrorCount(0);
          const errorItem = {
            code: '3',
            text: transcript,
            isCorrect: true
          };

          // Mevcut errorText listesine bu nesneyi ekleyin
          setErrorText((prevErrorText) => [...prevErrorText, errorItem]);

          target = selectedJump.target;
          ProgressAPI.updateProgress(+(diagramID!), +(allNodes[target].id), token);
        }
      }


      newActiveNode = allNodes[target]; // new node to show
      setActiveNode(newActiveNode);

      console.log({ nextActive: newActiveNode })

      // prepare normal nodes to preload
      const preloadVideos: Array<VideoNode> = []
      const uniqueTargets = new Set(newActiveNode.jumps.map((j)=>j.target));
      uniqueTargets.forEach((target) => {
        console.log({ target })
        preloadVideos.push(allNodes[target])
      })
      console.log({ "asdasd": preloadVideos })

      console.log({ preloadVideos })

      // prepare error nodes to preload
      if (!(newActiveNode.code.endsWith("-e3") || newActiveNode.code.includes("-e6-"))) { // if non-error video
        preloadVideos.push(allNodes[newActiveNode.code + "-e3"]); // TODO: check if exist allNodes[newActiveNode.code + "-e3"]
      }
      if (newActiveNode.code.endsWith("-e3")) { // if e3 video

        const randomVidNo = Math.floor(Math.random() * (newActiveNode.jumps.length));
        const e6Node = allNodes[newActiveNode.code.substring(0, newActiveNode.code.length - 2) + "e6-" + randomVidNo]
        /*
        const e6Node = nextNodes.find((value) => {
          return value.code.includes("-e6-"+);
        }) */

        console.log({ e6Node })
        preloadVideos.push(e6Node)

      }
      resetTranscript();

      console.log({ allNodes })
      console.log({ preloadVideos })
      console.log({ errorCount })
      setVideoEnded(false);
      setNextNodes(preloadVideos)
    }
  }, [listening])


  const videoEndHandle = (currentActive: VideoNode) => {
    if (activeNode?.jumps.length == 0) {
      console.log("diagramCompleted??!")
      setDiagramCompleted(true);
      return;
    }
    console.log("before ahs condition check")
    const hasConditionedJump = activeNode?.jumps.some((j: Jump, index, array) => j.conditions.length != 0);
    console.log({ hasConditionedJump })
    if (hasConditionedJump) {
      setVideoEnded(true)
      SpeechRecognition.startListening({ language: 'tr' });
    } else {
      let target = "";
      target = activeNode!.jumps[0].target;
      const newActiveNode = allNodes[target]; // new node to show
      setActiveNode(newActiveNode);

      console.log({ nextActive: newActiveNode })

      // prepare nodes to preload
      const preloadVideos: Array<VideoNode> = []
      const uniqueTargets = new Set(newActiveNode.jumps.map((j)=>j.target));
      uniqueTargets.forEach((target) => {
        console.log({ target })
        preloadVideos.push(allNodes[target])
      })
      preloadVideos.push(allNodes[newActiveNode.code + "-e3"]);
      setNextNodes(preloadVideos)

    }
  }

  return (
    <Box

      justifyContent="center"
      alignItems="center"
    >
      <Card sx={{ margin: '10px'}}  variant="outlined">
        <Grid container
          direction="row"
          justifyContent="center"

          alignItems="stretch" >
          {activeNode === null ? (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          ) : (
            <>

              <Grid p={0.1} item xs={12} sm={9} >

                <div style={{
                  flexDirection: 'column', alignItems: 'center',
                  borderRadius: '5px',
                }}>


                  <div style={{
                    position: 'relative',
                    paddingTop: '56.25%',
                    border: '2px solid transparent',
                    borderRadius: '5px',

                  }}>
                    <VideoLoader
                      ref={ref}
                      activeNode={activeNode!}
                      nextNodes={nextNodes}
                      onVideoEnd={() => {

                        console.log("end handle start" + activeNode?.code)
                        videoEndHandle(activeNode!);
                      }}
                    />
                  </div>
                  <div onClick={() => {
                    /*
                    if (videoEnded) {
                      SpeechRecognition.startListening({ language: 'tr' })
                    }*/
                    SpeechRecognition.startListening({ language: 'tr' })
                  }} className={listening ? 'border-animation' : (videoEnded && !listening) ? 'custom-cursor' : ''} style={{
                    marginTop: '2px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: "#4e5b63", height: '14vh', border: '2px solid transparent', // Eğer başlangıçta bir border istemiyorsanız, burayı ekleyebilirsiniz.
                    borderRadius: '5px', flex: '0 0 50px', width: '100%', position: 'relative'
                  }}>
                    {/* Alt kısmın içeriğini buraya ekleyin */}

                    <div style={{ position: 'absolute', top: '10%' }} >

                    </div>
                    <div>
                      <h5 style={{ color: '#fff', paddingBottom: '15px' }}>
                        {(transcript !== '' && videoEnded) ?
                          <span>{transcript}</span> :
                          (transcript == '' && videoEnded && listening) ?
                            <span className="loading">...</span> :
                            (videoEnded && !listening) ?
                              <span style={{ color: 'rgba(255, 255, 255, 0.3)' }} >Click here to reactivate speech recognition</span>
                              : <span style={{ color: 'rgba(255, 255, 255, 0.3)' }} >Watch the video carefully</span>}
                      </h5>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid p={0.1} item xs={12} sm={3}>
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', alignContent: 'center', padding: '2px', backgroundColor: '#ffffff' }}>
    <IconButton
      color="primary"
      onClick={() => {
        navigate("/courses");
      }}
      sx={{ marginRight: '10px' }}
    >
      <IoIosArrowBack color="#212121" />
    </IconButton>
    <div style={{ flex: 1 }}>
      <h5 style={{ fontFamily: 'Kumbh Sans, sans-serif', color: '#212121' }}>{diagram?.title}</h5>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
      <h5 style={{ fontFamily: 'Kumbh Sans, sans-serif', color: errorCount > 0 ? 'red' : '#212121', paddingRight: '10px' }}>{errorCount}</h5>
    </div>
  </div>
  <div style={{ padding: '10px', margin: '2px' }}>
    <Typography sx={{ mb: 2 }} variant="h6" component="div">
      Previous Responses
    </Typography>

    <List sx={{ height: '75vh', scrollSnapType: "y mandatory", overflowY: "scroll" }}>
      {/* Divider with Chip to mark the start */}
      <Divider key="start-divider">
        <Chip label="Start" />
      </Divider>

      {/* Mapping through errorText array */}
      {errorText.map((item, index, array) => (
        <div key={index}>
          <ListItem>
            <ListItemIcon>
              {item.isCorrect ? <AiFillCheckCircle color="green" /> : <BiErrorCircle color="red" />}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>

          {/* Conditional rendering of Divider based on item code */}
          {item.code === '3' ? (
            <Divider style={index === array.length - 1 && !diagramCompleted ? { scrollSnapAlign: "end" } : {}}>
              <Chip color="success" label="Correct" />
            </Divider>
          ) : item.code === '2' ? (
            <Divider style={index === array.length - 1 && !diagramCompleted ? { scrollSnapAlign: "end" } : {}}>
              <Chip color="error" label="6th Error" />
            </Divider>
          ) : item.code === '1' ? (
            <Divider style={index === array.length - 1 && !diagramCompleted ? { scrollSnapAlign: "end" } : {}}>
              <Chip color="error" label="3rd Error" />
            </Divider>
          ) : item.code !== '0' && !diagramCompleted && (
            <Divider key={`divider-${index}`} />
          )}
        </div>
      ))}

      {/* Conditional rendering for diagram completion */}
      {diagramCompleted && (
        <div key="diagram-completed" style={{ display: 'flex', flexDirection: 'column', paddingTop: '10px', scrollSnapAlign: "end" }}>
          <Divider>
            <Chip color="info" onClick={() => {
              navigate("/courses");
            }} label="Diagram Completed" />
          </Divider>
        </div>
      )}
    </List>
  </div>
</Grid>
            </>
          )}
        </Grid>
      </Card>
    </Box>
  )

}