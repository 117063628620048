import {Button,  DialogActions,  Grid, IconButton, Paper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {EditNode} from "./EditNode";
import DeleteIcon from "@mui/icons-material/Delete";
import { VideoNode} from "../../api/NodeModels";
import {Diagram} from "../../api/DiagramModels"
import Popover from "@mui/material/Popover";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {useAuth} from "../../hooks/useAuth";
import {NodeAPI} from "../../api/NodeAPI"
import {DiagramAPI} from "../../api/DiagramAPI"
import {useNavigate} from "react-router-dom";
import {useParams} from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

export default function AddNodeMain() {
    const iCreateNode: VideoNode[] = []
    const [handleCreateNodeArray, setHandleCreateNodeArray] = useState(iCreateNode)
    const {id} = useParams();
    const auth = useAuth();
    const navigate = useNavigate();
    const [diagram, setDiagram] = useState<Diagram|null>()
    const [startingNodeID, setStartingNodeID] = useState(0)
    const [isSaved, setIsSaved]=useState(false)
    const [saved,setSaved]=useState(false)
    let preSelected=0

    async function getNodes(id: number) {
        const token = await auth.getToken()
        let tmp: VideoNode[] = []
        try {
            tmp = await NodeAPI.nodesOfDiagram(token as string, id)
        } catch (e) {
        }
        tmp.sort((p1, p2) => {
            if (p1.id < p2.id) return -1;
            if (p1.id > p2.id) return 1;
            return 0;
        })
        setHandleCreateNodeArray(tmp)

    }
    useEffect(()=>{
            setSaved(true)
        },[handleCreateNodeArray]
    )
    useEffect(() => {
        if (!auth.user || auth.user.permission < 20) {
            navigate("/login");
        }
        getDiagramData(parseInt(id!))
        getNodes(parseInt(id!))
    }, [])

    async function AddNode() {
        const tmp = [...handleCreateNodeArray]
        const token = await auth.getToken()
        if (!token) {
            navigate("/login");
        }
        tmp.push(await NodeAPI.createNode(token as string, {
            code: "",
            diagramId: parseInt(id!),
            videoUrl: "",
            error3: "",
            jumps: [],
        }))
        setHandleCreateNodeArray(tmp)
        setSelectItem(handleCreateNodeArray.length)
    }

    async function getDiagramData(id: number) {
        const token = await auth.getToken();
        const tmp=await DiagramAPI.getDiagram(token!, id)
        setStartingNodeID(tmp?.startNode!)
        setDiagram(tmp)
    }
    async function updateNode(){
        const token = await auth.getToken()
        if (!token) {
            navigate("/login");
        }
        await NodeAPI.updateNode(token!, {
            nodeId:handleCreateNodeArray[preSelected].id,
            code:handleCreateNodeArray[preSelected].code,
            videoUrl:handleCreateNodeArray[preSelected].videoUrl,
            error3:handleCreateNodeArray[preSelected].error3,
            jumps:handleCreateNodeArray[preSelected].jumps
        })
    }

    async function pushDiagramData(diagram: Diagram, startingNode: number) {
        const token = await auth.getToken()
        await DiagramAPI.updateDiagram(token!, {
            id: diagram.id,
            startNode: startingNode,
            title: diagram.title,
            topic: diagram.topic,
            scenario: diagram.scenario,
            rules: diagram.rules,
            targetGrammarRules: diagram.rules,
            usableGrammarRules: diagram.usableGrammarRules,
            type: diagram.type,
            extra: diagram.extra
        })
        setStartingNodeID(startingNode)
    }
    async function isSavedNode(savedNode:boolean){
        if(!savedNode){
            setIsSaved(true)
            await updateNode()
        }
    }

    async function deleteNode(i: number) {
        const tmp = [...handleCreateNodeArray]
        const token = await auth.getToken()
        if (!token) {
            navigate("/login");
        }
        await NodeAPI.deleteNode(token!, tmp[i].id)
        tmp.splice(i, 1)
        setHandleCreateNodeArray(tmp)
        setDeletePopup(false)
    }

    function select() {
        return (
            handleCreateNodeArray.length > 0 && selectItem != -1 ?
                <EditNode data={handleCreateNodeArray} setter={setHandleCreateNodeArray} savedSetter={setSaved} saved={saved}
                          selectedItem={selectItem}></EditNode>
                :
                null
        )
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        AddNode()
    };
    const handleClickOpen = () => {
        setDeletePopup(true);
    };


    const [searchNode, setSearchNode] = useState("")
    const [selectItem, setSelectItem] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const [deletePopup, setDeletePopup] = React.useState(false);
    const [startNodePopup, setStartNodePopup] = React.useState(false);


    return (
        <Grid container
              alignItems="stretch"
              direction="row"
              justifyContent="center">
            <Grid item xs={2} sx={{"padding": 1, maxHeight: 300}}>
                <Paper elevation={5}
                       sx={{
                           backgroundColor: "white",
                           "padding": 2,
                           borderRadius: 5,
                       }}
                >
                    <TextField
                        value={searchNode}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchNode(event.target.value)
                        }}
                        margin="normal"
                        fullWidth
                        id="search"
                        label="search"
                        name="search"
                    />
                    <Button
                        type="submit"
                        onClick={handleClick}
                        disabled={searchNode.length != 0}
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >Add Node</Button>
                    <Popover
                        open={open}
                        onClose={() => {
                            setAnchorEl(null);
                        }}
                        anchorReference="anchorPosition"
                        anchorPosition={{top: 180, left: 300}}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "center",
                            horizontal: "right"
                        }}
                        sx={{
                            borderRadius: 5

                        }}
                    ><Typography fontSize={20}>Node created.</Typography></Popover>
                    <Grid container direction={"column"} sx={{
                        alignItems: "center",
                        flexWrap: "nowrap",
                        height: "75vh",
                        overflow: "hidden",
                        overflowY: "scroll"
                    }}>
                        {handleCreateNodeArray.map((mapItem) => (
                            <Grid item key={mapItem.id}>
                                <Paper elevation={2}
                                       onClick={() => {
                                           let index = handleCreateNodeArray.findIndex(index => index.id === mapItem.id)
                                           preSelected=selectItem
                                           if (saved)setIsSaved(true)
                                           setSelectItem(index)
                                           setSaved(false)
                                       }
                                       }
                                       sx={{
                                           display: mapItem.code.toLowerCase().includes(searchNode.toLowerCase()) ? "" : "none",
                                           backgroundColor: "white",
                                           border: handleCreateNodeArray[selectItem].id == mapItem.id ? 2 : 0,
                                           '&:hover': {
                                               backgroundColor: 'primary.main',
                                               opacity: [0.9, 0.8, 0.7],
                                               cursor: 'pointer'
                                           },
                                           overflow: "hidden", textOverflow: "ellipsis", width: "15rem",
                                           pl: 2,
                                           py: 1,
                                           borderRadius: 2,
                                           my: 0.5,
                                       }}>
                                    <Grid container direction={"row"} sx={{
                                        alignItems: "center",
                                        ml: 1
                                    }}>
                                        <Grid item xs={7}>
                                            <Typography noWrap>{!(mapItem.code.length == 0) ? mapItem.code :
                                                <Typography>Empty node</Typography>}</Typography>

                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton aria-label="delete" onClick={() => setStartNodePopup(true)}>
                                                {startingNodeID == mapItem.id ?  <StarIcon/>: <StarBorderIcon/> }
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton aria-label="delete" onClick={() => handleClickOpen()}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))
                        }
                    </Grid>
                </Paper>
            </Grid>
            <Dialog
                open={deletePopup}
                onClose={() => setDeletePopup(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Node"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this node?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeletePopup(false)}>No</Button>
                    <Button onClick={() => {
                        deleteNode(selectItem)
                        setSelectItem(0)
                    }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isSaved}
                onClose={() => setIsSaved(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Unsaved Changes"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to save changes before switching to other node?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setIsSaved(false)
                        setSaved(false)}}>No</Button>
                    <Button onClick={() => {
                        if(saved)isSavedNode(saved)
                        setIsSaved(false)
                    }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={startNodePopup}
                onClose={() => setStartNodePopup(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to change the starting dialog node?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Users will first see this node video
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStartNodePopup(false)}>No</Button>
                    <Button onClick={() => {
                            pushDiagramData(diagram!, handleCreateNodeArray[selectItem].id)
                            setStartNodePopup(false)
                    }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={9} sx={{"padding": 0}}>
                <Paper elevation={5}
                       sx={{
                           borderRadius: 5,
                           backgroundColor: "white",
                           "padding": 2,
                           height: 1
                       }}>
                    {
                        select()
                    }
                </Paper>
            </Grid>
        </Grid>
    );
}