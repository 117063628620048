import {createContext, ReactNode, useContext, useEffect} from "react";
import {AccountAPI} from "../api/AccountAPI";
import {useLocalStorage} from "./useLocalStorage";


// Similar usage: https://usehooks.com/useAuth

export interface AuthUser {
    id: number;
    email: string;
    birthday: Date;
    country: string;
    gender: string;
    education: string;
    nativeLanguage: string;
    otherLanguages: string[];
    permission: number;
    createdAt: Date;
}



interface AuthContext {
    user: AuthUser | null;
    signIn: (_email: string, _password: string, _captchaToken: string) => Promise<false | string>;
    getToken: () => Promise<string | null>;
    signOut: () => Promise<void>;
    signUp: (email: string, password: string, captchaToken: string, birthday: Date, country: string, gender: string, education: string, nativeLanguage: string, otherLanguages: string[]) => Promise<boolean>
    verify: (code: string) => Promise<boolean>
    verifyResend: () => Promise<boolean>;
    deleteAccount: () => Promise<void>;
}


export const authContext = createContext<AuthContext>(null!);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({children}: { children: ReactNode }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};


function useProvideAuth() {
    const [email, setEmail] = useLocalStorage<string>("authEmail", '');
    const [password, setPassword] = useLocalStorage<string>("authPassword", ''); // password
    const [user, setUser] = useLocalStorage<AuthUser | null>("authUser", null);
    const [token, setToken] = useLocalStorage<string | null>("authToken", null);
    const [tokenExpiration, setTokenExpiration] = useLocalStorage<number>("tokenExpiration", 0);

    useEffect(() => {
        async function initAuth() {
            if (user) {
                const token = await getToken();
            }
        }

        initAuth();
    }, []);

    /**
     * Giriş başarılı ise tokeni dödürür, **token** ve **user**'ı günceller.
     *
     * Email veya şifre hatalı ise **false** döndürür.
     */
    const signIn = async (_email: string, _password: string, _captchaToken: string): Promise<false | string> => {
        const tempToken = await AccountAPI.login(_email, _password, _captchaToken);
        if (typeof tempToken == "string") {
            // Save token
            setToken(tempToken);

            // Save user and pass for later
            setEmail(_email);
            setPassword(_password);


            // Save expiration
            const now = Date.now();
            const expiration = now + 1000 * 60 * 60 * 24 * 30; // 30 day
            setTokenExpiration(expiration);

            // Fetch and save user
            const tempUser = await AccountAPI.getDetails(tempToken)
            setUser(tempUser);

            return tempToken;
        } else {
            return false;
        }
    };

    /**
     * Başarılıysa **true** döndürür.
     *
     * Email zaten kullanılıyorsa **false** döndürür.
     */
    const signUp = async (email: string, password: string, captchaToken: string, birthday: Date, country: string, gender: string, education: string, nativeLanguage: string, otherLanguages: string[]): Promise<boolean> => {
        const res = await AccountAPI.register(email, password, captchaToken, birthday, country,gender, education, nativeLanguage, otherLanguages);
        return res;
    };


    const signOut = async (): Promise<void> => {
        setUser(null);
        setToken(null);
        setTokenExpiration(0);
    };
    const deleteAccount = async (): Promise<void> => {
        const tempToken = await getToken()
        if (!tempToken)
            return;
        await AccountAPI.deleteAccount(tempToken)
        setUser(null);
        setToken(null);
        setTokenExpiration(0);
    };

    const isTokenExpired = async (): Promise<boolean> => {
        return tokenExpiration <= Date.now()
    }

    const getToken = async (): Promise<string | null> => {
        if (!user) {
            return null;
        }
        if (await isTokenExpired()) {
            await signOut();

            // trying to renew token
            /*
            let tempToken: string | false;
            try {
                tempToken = await signIn(email, password);
            } catch (e) {  // can't renew
                await signOut();
                return null;
            }
            if (!tempToken) { // can't renew
                await signOut();
                return null;
            } else { // success
                return tempToken;
            }
            */
        }

        // token is still fresh
        return token;
    };

    /**
     * Başarılıysa **true** döner.
     *
     * Hesap zaten verified ise **false** döner.
     * @throws token hatalıysa
     */
    const verify = async (code: string): Promise<boolean> => {
        const success = await AccountAPI.verify(code);
        if (success && user) {
            setUser({...user, permission: 1});
        }
        return success;
    };

    /**
     * Başarılı ise true döner.
     *
     * Hesap verified ise false döner.
     */
    const verifyResend = async (): Promise<boolean> => {
        const token = await getToken();
        if (!token) {
            throw Error();
        }
        return await AccountAPI.verifyResend(token);
    };



    return {
        user,
        verify,
        verifyResend,
        signIn,
        signUp,
        signOut,
        getToken,
        deleteAccount
    };
}