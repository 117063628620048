import {
    Alert,
    Avatar,
    Box, Button, Checkbox,
    Container,
    createTheme,
    CssBaseline, FormControlLabel, Grid,
    Link,
    TextField,
    ThemeProvider,
    Typography
} from "@mui/material";
import {useState} from "react";
import * as EmailValidator from "email-validator";
import {AccountAPI} from "../../api/AccountAPI";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import Copyright from "../../components/Copyright";


const theme = createTheme();
export default function ForgotPassword() {
    let navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [recoverySuccess, setRecoverySuccess] = useState(false)
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setEmailError(false);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const _email = data.get('email') as string;

        const isEmailValid = EmailValidator.validate(_email);
        if (_email == null || _email == "" || !isEmailValid) {
            setEmailError(true);
            return;
        }
        await AccountAPI.sendRecoveryEmail(_email as string)
        setRecoverySuccess(true)
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main', width: 64, height: 64}}>
                        <LockIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot Password?
                    </Typography>
                    <Typography sx={{mx: "5%"}} variant="subtitle1" component="h2">
                        The reset instructions will be sent to your mailbox.
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1, width: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            error={emailError}
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.currentTarget.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 5}}
                        >
                            Reset Password
                        </Button>

                        {recoverySuccess &&
                            <Alert severity="success">
                                Instructions have been sent to your mail address
                            </Alert>
                        }

                    </Box>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}