import React, { useState } from 'react'
import Navbar from '../../components/Navbar'

const News = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleDrawer = (value: boolean | ((prevState: boolean) => boolean)) => {
      setIsOpen(value);
    }
  return (
    <body>

 
   
    <div className="page-wrapper">

    <Navbar toggleNavbarDrawer={handleToggleDrawer} data={"home"} />


        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content">

            </div>
        </div>


        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content">

            </div>
        </div>



    
        <section className="page-header clearfix" style={{ backgroundImage: 'url(assets/images/backgrounds/page-header-bg1.jpg)' }}>
        <div className="container">
            <div className="row">
                <div className="col-xl-12">
                    <div className="page-header__wrapper clearfix">
                        <div className="page-header__title">
                            <h2>News</h2>
                        </div>
                        <div className="page-header__menu">
                           
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
   


   
    <section className="blog-one blog-one--blog">
        <div className="container">
            <div className="row">
              
                <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div className="blog-one__single">
                        <div className="blog-one__single-img">
                            <img src="assets/images/blog/blog-v1-img1.jpg" alt=""/>
                        </div>
                        <div className="blog-one__single-content">
                            <div className="blog-one__single-content-overlay-mata-info">
                                <ul className="list-unstyled">
                                    <li><a href="#"><span className="icon-clock"></span>20 June</a></li>
                                    <li><a href="#"><span className="icon-user"></span>Admin </a></li>
                                    <li><a href="#"><span className="icon-chat"></span> Comments</a></li>
                                </ul>
                            </div>
                            <h2 className="blog-one__single-content-title"><a href="news-details.html">Helping Answers Stand out in Discussions</a></h2>
                            <p className="blog-one__single-content-text">Lorem ipsum is simply free text on used by copytyping refreshing the whole area.</p>
                        </div>
                    </div>
                </div>
               

              
                <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                    <div className="blog-one__single">
                        <div className="blog-one__single-img">
                            <img src="assets/images/blog/blog-v1-img2.jpg" alt=""/>
                        </div>
                        <div className="blog-one__single-content">
                            <div className="blog-one__single-content-overlay-mata-info">
                                <ul className="list-unstyled">
                                    <li><a href="#"><span className="icon-clock"></span>20 June</a></li>
                                    <li><a href="#"><span className="icon-user"></span>Admin </a></li>
                                    <li><a href="#"><span className="icon-chat"></span> Comments</a></li>
                                </ul>
                            </div>
                            <h2 className="blog-one__single-content-title"><a href="news-details.html">Helping Answers Stand out in Discussions</a></h2>
                            <p className="blog-one__single-content-text">Lorem ipsum is simply free text on used by copytyping refreshing the whole area.</p>
                        </div>
                    </div>
                </div>
               

              
                <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                    <div className="blog-one__single">
                        <div className="blog-one__single-img">
                            <img src="assets/images/blog/blog-v1-img3.jpg" alt=""/>
                        </div>
                        <div className="blog-one__single-content">
                            <div className="blog-one__single-content-overlay-mata-info">
                                <ul className="list-unstyled">
                                    <li><a href="#"><span className="icon-clock"></span>20 June</a></li>
                                    <li><a href="#"><span className="icon-user"></span>Admin </a></li>
                                    <li><a href="#"><span className="icon-chat"></span> Comments</a></li>
                                </ul>
                            </div>
                            <h2 className="blog-one__single-content-title"><a href="news-details.html">Helping Answers Stand out in Discussions</a></h2>
                            <p className="blog-one__single-content-text">Lorem ipsum is simply free text on used by copytyping refreshing the whole area.</p>
                        </div>
                    </div>
                </div>
               

              
                <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                    <div className="blog-one__single">
                        <div className="blog-one__single-img">
                            <img src="assets/images/blog/blog-v1-img4.jpg" alt=""/>
                        </div>
                        <div className="blog-one__single-content">
                            <div className="blog-one__single-content-overlay-mata-info">
                                <ul className="list-unstyled">
                                    <li><a href="#"><span className="icon-clock"></span>20 June</a></li>
                                    <li><a href="#"><span className="icon-user"></span>Admin </a></li>
                                    <li><a href="#"><span className="icon-chat"></span> Comments</a></li>
                                </ul>
                            </div>
                            <h2 className="blog-one__single-content-title"><a href="news-details.html">Helping Answers Stand out in Discussions</a></h2>
                            <p className="blog-one__single-content-text">Lorem ipsum is simply free text on used by copytyping refreshing the whole area.</p>
                        </div>
                    </div>
                </div>
               

              
                <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms">
                    <div className="blog-one__single">
                        <div className="blog-one__single-img">
                            <img src="assets/images/blog/blog-v1-img5.jpg" alt=""/>
                        </div>
                        <div className="blog-one__single-content">
                            <div className="blog-one__single-content-overlay-mata-info">
                                <ul className="list-unstyled">
                                    <li><a href="#"><span className="icon-clock"></span>20 June</a></li>
                                    <li><a href="#"><span className="icon-user"></span>Admin </a></li>
                                    <li><a href="#"><span className="icon-chat"></span> Comments</a></li>
                                </ul>
                            </div>
                            <h2 className="blog-one__single-content-title"><a href="news-details.html">Helping Answers Stand out in Discussions</a></h2>
                            <p className="blog-one__single-content-text">Lorem ipsum is simply free text on used by copytyping refreshing the whole area.</p>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                    <div className="blog-one__single">
                        <div className="blog-one__single-img">
                            <img src="assets/images/blog/blog-v1-img6.jpg" alt=""/>
                        </div>
                        <div className="blog-one__single-content">
                            <div className="blog-one__single-content-overlay-mata-info">
                                <ul className="list-unstyled">
                                    <li><a href="#"><span className="icon-clock"></span>20 June</a></li>
                                    <li><a href="#"><span className="icon-user"></span>Admin </a></li>
                                    <li><a href="#"><span className="icon-chat"></span> Comments</a></li>
                                </ul>
                            </div>
                            <h2 className="blog-one__single-content-title"><a href="news-details.html">Helping Answers Stand out in Discussions</a></h2>
                            <p className="blog-one__single-content-text">Lorem ipsum is simply free text on used by copytyping refreshing the whole area.</p>
                        </div>
                    </div>
                </div>
             
            </div>
        </div>
    </section>

    <footer className="footer-one">
            <div className="footer-one__bg">
            </div>
            <div className="footer-one__top">
              <div className="container">
                <div className="row">

                  <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.1s">
                    <div className="footer-widget__column footer-widget__about">
                      <div className="footer-widget__about-logo">
                        <a href="index.html"><img src="assets/images/resources/langtreevector.png" alt=""
                          className="responsive-image-footer" /></a>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.3s">
                    <div className="footer-widget__column footer-widget__courses">
                      <h3 className="footer-widget__title">Courses</h3>
                      <ul className="footer-widget__courses-list list-unstyled">
                        <li><a href="#">UI/UX Design</a></li>
                        <li><a href="#">WordPress Development</a></li>
                        <li><a href="#">Business Strategy</a></li>
                        <li><a href="#">Software Development</a></li>
                        <li><a href="#">Business English</a></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.5s">
                    <div className="footer-widget__column footer-widget__links">
                      <h3 className="footer-widget__title">Links</h3>
                      <ul className="footer-widget__links-list list-unstyled">
                        <li><a href="about.html">About Us</a></li>
                        <li><a href="#">Overview</a></li>
                        <li><a href="teachers-1.html">Teachers</a></li>
                        <li><a href="#">Join Us</a></li>
                        <li><a href="news.html">Our News</a></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.7s">
                    <div className="footer-widget__column footer-widget__contact">
                      <h3 className="footer-widget__title">Contact</h3>
                      <p className="text">Kastamonu University, Türkiye</p>
                      <p><a href="mailto:dilagaci2021@gmail.com">dilagaci2021@gmail.com</a></p>
                      <p className="phone"><a href="tel:+90 366 280 14 97">+90 366 280 14 97</a></p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.9s">
                    <div className="footer-widget__column footer-widget__social-links">
                      <ul className="footer-widget__social-links-list list-unstyled clearfix">
                        <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fab fa-brands fa-linkedin"></i></a></li>
                      </ul>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </footer>
  









    </div>



    <div className="mobile-nav__wrapper">
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
     
        <div className="mobile-nav__content">
            <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times"></i></span>

            <div className="logo-box">
                <a href="index.html" aria-label="logo image"><img src="assets/images/resources/mobilemenu_logo.png" width="155" alt="" /></a>
            </div>
           
            <div className="mobile-nav__container"></div>
         

            <ul className="mobile-nav__contact list-unstyled">
                <li>
                    <i className="icon-phone-call"></i>
                    <a href="mailto:needhelp@packageName__.com">needhelp@zilom.com</a>
                </li>
                <li>
                    <i className="icon-letter"></i>
                    <a href="tel:666-888-0000">666 888 0000</a>
                </li>
            </ul>
            <div className="mobile-nav__top">
                <div className="mobile-nav__social">
                    <a href="#" className="fab fa-twitter"></a>
                    <a href="#" className="fab fa-facebook-square"></a>
                    <a href="#" className="fab fa-pinterest-p"></a>
                    <a href="#" className="fab fa-instagram"></a>
                </div>
            </div>
        </div>
    </div>



    <div className="search-popup">
        <div className="search-popup__overlay search-toggler"></div>
        <div className="search-popup__content">
            <form action="#">
                <label htmlFor="search" className="sr-only">search here</label>
                <input type="text" id="search" placeholder="Search Here..." />
                <button type="submit" aria-label="search submit" className="thm-btn2">
                    <i className="fa fa-search" aria-hidden="true"></i>
                </button>
            </form>
        </div>
    </div>



    <a href="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></a>


  

</body>
  )
}

export default News