import {Jump, SubCondition} from "../../api/NodeModels";

export function selectJump(jumps: Jump[], userInput: string): Jump | null {
  //temp
  //return jumps[0]
  //console.log({jumps, userInput})
  for (let i = 0; i < jumps.length; i++) {
    const jump = jumps[i];
    for (let j = 0; j < jump.conditions.length; j++) {
      const condition = jump.conditions[j];

      // TODO: make it case insensitive
      if (condition.type == "start" && betterStartWith(userInput, condition.value)) {
        return jump;
      }
      if (condition.type == "end" && betterEndsWith(userInput, condition.value)) {
        return jump;
      }
      if (condition.type == "include" && betterInludes(userInput, condition.value)) {
        return jump;
      }
      if (condition.type == "pattern") { // Ali bana {x} al.

        const mappedSC: Record<string, SubCondition> = {}

        condition.subConditions?.forEach((sc) => {
          if (sc.type == "text"){
            sc.value = sc.value.replaceAll(/[$&+,:;=?@#|'<>.^*()%!-]/gui,"");
          }
          mappedSC[sc.varName] = sc;
        })

        const valueLines = condition.value.replaceAll(/[$&+,:;=?@#|'<>.^*()%!-]/gui,"").toLocaleLowerCase("TR").split("\n");

        // V3
        for (let valueLine of valueLines) {
          const reg = valueLine.replace(/\{\w+}/g, str => {
            let varName = str.substring(1,str.length-1);
            const sc = mappedSC[varName];
            if (sc.type == "text"){
              const newValues = sc.value.replaceAll("/", "|");
              return "(" + newValues + ")";
            } else if (sc.type == "range"){
              return `(?<${varName}>\\p{L}+|[0-9]+)`
            }else {
              throw Error()
            }
          });

          const cleanUserInput = cleanString(userInput).toLocaleLowerCase("TR")
          const matches = cleanUserInput.match(RegExp(("^"+reg+"$"), "ui"))
          if (matches == null) {
            continue
          }

          let rangeError = false;
          for (const key in matches?.groups) {
            const sc = mappedSC[key];
            const userValue = matches?.groups[key];
            const splittedValues = sc.value.split("-").map((v:string)=>Number.parseInt(v));

            let inputNumber: number;
            // TODO: tüm sayılar için rakam değilse tanıyıp dönüştürme olması lazım
            if (userValue == "bir"){
              inputNumber = 1;
            }else {
              inputNumber = Number.parseInt(userValue as string);
            }
            if (!(inputNumber >= splittedValues[0] && inputNumber <= splittedValues[1])){
              rangeError = true;
            }
          }
          if (!rangeError) return jump;

        }


      }
    }
  }
  return null;
}

/**
 * Return true if sc is satisfied else returns false.
 * userInput should be sliced.
 */
const checkSC = (userInput: string, sc: SubCondition): boolean => {
  if (sc.type == "text") {
    const splittedValues = sc.value.split(",");
    return betterArrayIncludes(splittedValues, userInput);
  }else if (sc.type == "range"){
    const splittedValues = sc.value.split("-").map((v:string)=>Number.parseInt(v));
    let inputNumber: number;
    // TODO: tüm sayılar için rakam değilse tanıyıp dönüştürme olması lazım
    if (userInput == "bir"){
      inputNumber = 1;
    }else {
      inputNumber = Number.parseInt(userInput);
    }
    if (inputNumber >= splittedValues[0] && inputNumber <= splittedValues[1]){
      return true
    }

  }
  return false;
}
const checkSC2 = (userInput: string[]|string, sc: SubCondition): [boolean, number] => {
  if (sc.type == "text") {
    const alternatives = sc.value.split("/");
    for (let alternative of alternatives) {
      const splittedValues = alternative.split(" ");
      let err = false;
      for (let i = 0; i < splittedValues.length; i++) {
        if (!ciCompare(splittedValues[i], userInput[i])){
          err = true
          break
        }
      }
      if (!err) {return [true, splittedValues.length]}
    }
    return [false, 1];

  }else if (sc.type == "range"){
    const splittedValues = sc.value.split("-").map((v:string)=>Number.parseInt(v));
    let inputNumber: number;
    // TODO: tüm sayılar için rakam değilse tanıyıp dönüştürme olması lazım
    userInput = userInput[0]
    if (userInput == "bir"){
      inputNumber = 1;
    }else {
      inputNumber = Number.parseInt(userInput as string);
    }
    if (inputNumber >= splittedValues[0] && inputNumber <= splittedValues[1]){
      return [true, 1]
    }

  }
  return [false,1];
}

export const cleanString = (str: string): string => {
  return str.replace(/[.,\/#!$%\^&\*;:=\-_`~()?]/g,"").replaceAll(/[$&+,:;=?@#|'<>.^*()%!-]/gui,"").replace(/\s{2,}/g," ");
}

// Case insensitive compare
const ciCompare = (a: string, b: string): boolean => {
  return a.localeCompare(b, "tr-TR", { sensitivity: 'accent' }) === 0
}

// Array.includes() but better
const betterArrayIncludes = (arr: string[], value: string): boolean => {
  for (let x of arr) {
    if (ciCompare(x, value)){
      return true;
    }
  }
  return false;
}

const betterInludes = (str: string, value: string): boolean => {
  const cleanStr = cleanString(str);
  const cleanValue = cleanString(value);
  if (cleanStr.length < cleanValue.length){
    return false;
  }
  const lowerStr = cleanStr.toLocaleLowerCase("TR");
  const lowerValue = cleanValue.toLocaleLowerCase("TR");
  return lowerStr.includes(lowerValue);
}


const betterStartWith = (str: string, value: string): boolean => {
  const cleanStr = cleanString(str);
  const cleanValue = cleanString(value);
  if (cleanStr.length < cleanValue.length){
    return false;
  }
  const cut = cleanStr.substring(0, cleanValue.length);
  if (ciCompare(cut, cleanValue)){
    return true;
  }
  return false;
}

const betterEndsWith = (str: string, value: string): boolean => {
  const cleanStr = cleanString(str);
  const cleanValue = cleanString(value);
  if (cleanStr.length < cleanValue.length){
    return false;
  }
  const cut = cleanStr.substring(cleanStr.length-cleanValue.length, cleanStr.length);
  if (ciCompare(cut, cleanValue)){
    return true;
  }
  return false;
}