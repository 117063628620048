import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useLanguage } from '../LanguageProvider';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundImage: `url('https://i.ibb.co/zrGnKMq/united-kingdom.png')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('https://i.ibb.co/FwjVq7V/turkey.png')`,
      backgroundSize: 'cover',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

interface CustomizedSwitchesProps {
  // Burada gerekirse bileşenin props'larını tanımlayabilirsiniz.
}

const CustomizedSwitches: React.FC<CustomizedSwitchesProps> = () => {
  const { language, changeLanguage } = useLanguage(); // Dil bağlamını kullanarak dil ve değiştirme işlevini alın

  // localStorage'dan seçili dili alın, yoksa varsayılanı kullanın
  const selectedLanguage = localStorage.getItem("selectedLanguage") || 'tr';

  // localStorage'da seçili dil bilgisini saklayın
  localStorage.setItem("selectedLanguage", selectedLanguage);

  // Dil anahtarını değiştirme işlevini güncelleyin
  const handleChangeLanguage = () => {
    const newLanguage = language === 'tr' ? 'en' : 'tr';
    changeLanguage(newLanguage);

    // Anahtar değiştirildiğinde localStorage'a yeni dil bilgisini kaydedin
    localStorage.setItem("selectedLanguage", newLanguage);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked={selectedLanguage === 'en'} onChange={handleChangeLanguage} />}
        label=""
      />
    </FormGroup>
  );
};

export default CustomizedSwitches;
