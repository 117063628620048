import {VideoNode} from "./NodeModels";
import {Diagram} from "./DiagramModels";
import axios, {AxiosError} from "axios";

export class DiagramAPI {
  static baseUrl = process.env.REACT_APP_BACKEND_URL;

  /**
   * Returns diagram or null if diagram is not found.
   */
  static async getDiagram(token: string, diagramId: number): Promise<Diagram | null>{
    try {
      const config = {headers: {token: token}};
      const res = await axios.get(this.baseUrl + '/diagram/' + diagramId, config);
      return res.data;
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        if (e.response?.status == 404) {
          return null;
        }
      }
      throw e;
    }
  }

  static async getAllDiagrams(token: string): Promise<Diagram[]> {
    try {
      const config = { headers: { token: token } };
      const res = await axios.get(this.baseUrl + '/diagram/all', config);
      return res.data;
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 404) {
          return [];
        }
      }
      throw e;
    }
  }

  static async addDiagram(token: string, diagramData: {
    title: string,
    topic: string,
    scenario: string,
    rules: string,
    targetGrammarRules: string,
    usableGrammarRules: string,
    type: number,
    extra: string
}): Promise<void> {
    try {
        const config = { headers: { token: token } };
        await axios.post(this.baseUrl + '/diagram/create', diagramData, config);
    } catch (e: unknown) {
        throw e;
    }
}

static async updateDiagram(token: string,updatedData: {
  id: number,
  startNode: number,
  title: string,
  topic: string,
  scenario: string,
  rules: string,
  targetGrammarRules: string,
  usableGrammarRules: string,
  type: number,
  extra: string
  
}): Promise<void> {
  try {
    alert(JSON.stringify(updatedData));
    const config = { headers: { token: token } };
    await axios.post(this.baseUrl + `/diagram/update`, updatedData, config);
  } catch (e: unknown) {
    throw e;
  }
}

static async deleteDiagram(token: string, diagramId: number): Promise<void> {
  try {
    const config = { headers: { token: token } };
    await axios.delete(this.baseUrl + `/diagram/${diagramId}`, config);
  } catch (e: unknown) {
    throw e;
  }
}
static async approveDiagram(token: string, diagramId: number): Promise<void> {
  try {
    const config = { headers: { token: token } };
    await axios.get(this.baseUrl + `/diagram/status/approve/${diagramId}`, config);
  } catch (e: unknown) {
    throw e;
  }
}

static async rejectDiagram(token: string, diagramId: number): Promise<void> {
  try {
    const config = { headers: { token: token } };
    await axios.get(this.baseUrl + `/diagram/status/reject/${diagramId}`, config);
  } catch (e: unknown) {
    throw e;
  }
}

}