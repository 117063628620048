import React, { useEffect, useState } from 'react';
import Swiper from 'swiper';

const Slider = () => {
  const [swiperInstance, setSwiperInstance] = useState<Swiper | null>(null); // Swiper nesnesini saklamak için bir state
  const swiperOptions = {
    slidesPerView: 1,
    loop: true,
    effect: 'fade',
    pagination: {
      el: '#main-slider-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '#main-slider__swiper-button-next',
      prevEl: '#main-slider__swiper-button-prev',
    },
    autoplay: {
      delay: 7000,
      disableOnInteraction: false, // Otomatik oynatmayı kullanıcı etkileşiminden sonra devre dışı bırakma
    },
  };
  useEffect(() => {
    // Swiper slider başlatma kodu ve seçenekleri burada ekleyin
    const swiper = new Swiper('.thm-swiper__slider', {});
    
    setSwiperInstance(swiper); // Swiper nesnesini state'e kaydet
  }, []);
  

  return (
    <>
      <section className="main-slider main-slider-one">
      <div className=" thm-swiper__slider swiper-container"  data-swiper-options='{}'>
     
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="shape1"><img src="/assets/images/shapes/slider-v1-shape1.png" alt="" /></div>
              <div className="shape2"><img src="/assets/images/shapes/slider-v1-shape2.png" alt="" /></div>
              <div className="image-layer"></div>
              <div className="container">
                <div className="main-slider__content">
                  <div className="main-slider__content-icon-one">
                    <span className="icon-lamp"></span>
                  </div>
                  <div className="main-slider__content-icon-two">
                    <span className="icon-human-resources"></span>
                  </div>
                  <div className="main-slider-one__round-box">
                    <div className="main-slider-one__round-box-inner">
                      <p>Professional Teachers</p>
                      <div className="icon">
                        <i className="fas fa-sort-up"></i>
                      </div>
                    </div>
                  </div>
                  <div className="main-slider__content-tagline">
                    <h2>Ready to learn?</h2>
                  </div>
                  <h2 className="main-slider__content-title">Learn new <br />things daily</h2>
                  <p className="main-slider__content-text">Get free access to 6800+ different courses from
                    <br /> 680 professional teachers</p>
                  <div className="main-slider__content-btn">
                    <a href="#" style={{color:'white'}} className="thm-btn">Discover more</a>
                  </div>
                  <div className="main-slider-one__img">
                    <img src="assets/images/resources/main-slider-v1-img.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            
          </div>

      
   </div>
      </section>
    </>
  );
}

export default Slider;
