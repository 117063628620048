// LanguageContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";

type LanguageContextType = {
  language: string;
  changeLanguage: (newLanguage: string) => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

type Translation = {
    [key: string]: string;
  };
  
  export const getTranslation = (language: string): Translation => {
    try {
      // JSON dosyasını içe aktarın
      const translation = require(`./translations/${language}.json`);
      return translation;
    } catch (error) {
      console.error(`Translation file for ${language} not found.`);
      return {}; // Dil dosyası bulunamazsa boş bir nesne döndürün
    }
  };


export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};

export const getDefaultBrowserLanguage = (): string => {
    let userLanguage = navigator.language || navigator.language;
    if (userLanguage.startsWith("en")){
      userLanguage = "en"
    } else if (userLanguage.startsWith("tr")){
      userLanguage = "tr"
    } else {
      userLanguage = "en" // Varsayılan dil "en" (İngilizce)
    }

    return userLanguage;
  };
  
  export const getStoredLanguage = (): string => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    return savedLanguage || getDefaultBrowserLanguage(); // Tarayıcının varsayılan dilini kullanır
  };

type LanguageProviderProps = {
  children: React.ReactNode;
};

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
    children,
  }) => {
    const [language, setLanguage] = useState<string>(() => getStoredLanguage());
  
    const changeLanguage = (newLanguage: string) => {
      setLanguage(newLanguage);
      localStorage.setItem("selectedLanguage", newLanguage);
    };
  
    useEffect(() => {
      const savedLanguage = getStoredLanguage();
      setLanguage(savedLanguage);
    }, []); // Sayfa yüklendiğinde çalışır

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
