import Navbar from "../../components/Navbar";
import {useAuth} from "../../hooks/useAuth";
import 'react-circular-progressbar/dist/styles.css';
import {
    Box,
} from "@mui/material";
import React, { useState } from "react";

import Course from "../../components/Course";

export default function Courses() {
    const auth = useAuth()
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleDrawer = (value: boolean | ((prevState: boolean) => boolean)) => {
      setIsOpen(value);
    }
    return (
        <Box>
           <Navbar toggleNavbarDrawer={handleToggleDrawer} data={"home"} />
        <Course/>
        </Box>

    );
}
