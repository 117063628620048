import {Button, Grid, Icon, IconButton, Menu, MenuItem, Paper, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {VideoNode} from "../../api/NodeModels";
import AddIcon from '@mui/icons-material/Add';
import {PatternNode} from "./patternNode";
import {IncludeStartEnd} from "./IncludeStartEnd";

export function AddTarget({
                              data,
                              setter,
                              indexes
                          }: { data: VideoNode[], setter: (newData: VideoNode[]) => void, indexes: number[] }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Paper elevation={2} sx={{
            borderRadius:5,
            backgroundColor: "white",
            mt: 2,
            p: 2
        }}>
            <Grid container>
                <Grid item xs={11}>
                    <Typography fontSize={25}>Target</Typography>
                </Grid>
                <Grid item xs={1}>
                    <IconButton aria-label="delete" onClick={() => {
                        const tmp: VideoNode[] = [...data]
                        tmp[indexes[0]].jumps.splice(indexes[1], 1)
                        setter(tmp)
                    }
                    }>
                        <DeleteIcon/>
                    </IconButton>
                </Grid>
            </Grid>

            <TextField
                value={data[indexes[0]].jumps[indexes[1]].target}
                onChange={
                    (event: React.ChangeEvent<HTMLInputElement>) => {
                        const node: VideoNode[] = [...data]
                        node[indexes[0]].jumps[indexes[1]].target = event.currentTarget.value
                        setter(node)
                    }
                }
                margin="normal"
                required
                fullWidth
                id="target"
                label="Target"
                name="target"
            />
            <TextField
                value={data[indexes[0]].jumps[indexes[1]].error6}
                onChange={
                    (event: React.ChangeEvent<HTMLInputElement>) => {
                        const node: VideoNode[] = [...data]
                        node[indexes[0]].jumps[indexes[1]].error6 = event.currentTarget.value
                        setter(node)
                    }
                }
                margin="normal"
                required
                fullWidth
                id="6ErrorVideo"
                label="6 Error Video"
                name="6ErrorVideo"
            />
            <Typography fontSize={25} sx={{display:data[indexes[0]].jumps[indexes[1]].conditions.length==0? "none":"block" }}>Conditions</Typography>
            <Grid container direction={"row"} spacing={2}>
                {data[indexes[0]].jumps[indexes[1]].conditions.map((mapArray, index) => (
                    <Grid item key={index} xs={12}>
                        {mapArray.type=="pattern"?(
                            <PatternNode data={data} setter={setter} indexes={[...indexes,index]}></PatternNode>
                            )
                            :
                            (
                            <IncludeStartEnd data={data} setter={setter} indexes={[...indexes,index]}/>
                            )
                        }
                    </Grid>
                ))}
            </Grid>
            <Box sx={{
                display: "flex",
                pt: 3
            }}
                 justifyContent={"center"}
            >
                <Box>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    ><AddIcon/>Add Answer</Button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        open={open}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={()=>{
                            const node: VideoNode[] = [...data]
                            node[indexes[0]].jumps[indexes[1]].conditions.push({type:"pattern",value:""})
                            setter(node)
                            handleClose()

                        }
                        }>Pattern</MenuItem>
                        <MenuItem onClick={()=>{
                            const node: VideoNode[] = [...data]
                            node[indexes[0]].jumps[indexes[1]].conditions.push({type:"include",value:""})
                            setter(node)
                            handleClose()

                        }}>Include</MenuItem>
                        <MenuItem onClick={()=>{
                            const node: VideoNode[] = [...data]
                            node[indexes[0]].jumps[indexes[1]].conditions.push({type:"start",value:""})
                            setter(node)
                            handleClose()

                        }}>Start</MenuItem>
                        <MenuItem onClick={()=>{
                            const node: VideoNode[] = [...data]
                            node[indexes[0]].jumps[indexes[1]].conditions.push({type:"end",value:""})
                            setter(node)
                            handleClose()

                        }}>End</MenuItem>
                    </Menu>
                </Box>
            </Box>
        </Paper>
    )
}