import {Box, Divider, IconButton, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import {Key} from "@mui/icons-material";
import {useState} from "react";

export function PermissionButton({
                                     userID,
                                     updatePermission
                                 }: { updatePermission: (id: number, permission: number) => void, userID: number }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>

            <Box>

                <Tooltip title="Change Permission">
                    <IconButton
                        onClick={handleClick}
                    >
                        <Key/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >

                <MenuItem onClick={() => updatePermission(userID, 40)}>
                    <Typography>Admin</Typography>
                </MenuItem>
                <MenuItem onClick={() => updatePermission(userID, 30)}>
                    <Typography>Moderator</Typography>
                </MenuItem>
                <MenuItem onClick={() => updatePermission(userID, 20)}>
                    <Typography>Tutor</Typography>
                </MenuItem>
                <MenuItem onClick={() => updatePermission(userID, 10)}>
                    <Typography>User</Typography>
                </MenuItem>
                <MenuItem onClick={() => updatePermission(userID, 0)}>
                    <Typography>Unverified</Typography>
                </MenuItem>
            </Menu>

        </>

    );
}
