import React from 'react'
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();
  return (
    <div>

    <section className="page-section">
        <div className="full-width-screen">
            <div className="container-fluid">
                <div className="content-detail">
                    
                    <img id="shape2" className="parallax top" src={process.env.PUBLIC_URL + '/assets/images/shape2.png'}/>
                    <img id="shape4" className="parallax left top" src={process.env.PUBLIC_URL + '/assets/images/shape4.png'}/>
                    <img id="shape5" className="parallax left" src={process.env.PUBLIC_URL + '/assets/images/shape5.png'}/>
                    <img id="shape3" className="parallax top" src={process.env.PUBLIC_URL + '/assets/images/shape3.png'}/>
                    <img id="shape6" className="parallax top" src={process.env.PUBLIC_URL + '/assets/images/shape6.png'}/>
                    <img id="shape7" className="parallax" src={process.env.PUBLIC_URL + '/assets/images/shape7.png'}/>
                    <h1 className="global-title"><span>4</span><span>0</span><span>4</span></h1>

                    <h4 className="sub-title">Oops!</h4>

                    <p className="detail-text">We're sorry,<br/> The page you were looking for doesn't exist anymore.</p> 

                    <div className="back-btn">
                        <a onClick={()=>{navigate('/')}} className="btn">Back to Home</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
 
 
</div>
  )
}

export default NotFound