import Navbar from "../../components/Navbar";
import { useAuth } from "../../hooks/useAuth";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Alert, AlertTitle, CircularProgress, Container } from "@mui/material";

function Verify() {
    const auth = useAuth();
    let { code } = useParams();
    const [verified, setVerified] = useState(false);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const handleToggleDrawer = (value: boolean | ((prevState: boolean) => boolean)) => {
        setIsOpen(value);
    }
    useEffect(() => {
        if (!code) {
            return;
        }
        auth.verify(code).then((success) => {
            if (success) {
                setVerified(true);
            } else {
                setError("Account is already verified.");
            }
        }).catch(() => {
            setError("Verification url is invalid.");
        });
    }, [])
    return (
        <>
            <Navbar toggleNavbarDrawer={handleToggleDrawer} data={"home"} />

            <Container sx={{ mt: 30 }}>
                {!verified && (error == '') &&
                    <CircularProgress />}
                {verified &&
                    <Link to="/">
                        <Alert severity="success">
                            <AlertTitle>Account is verified!</AlertTitle>
                            You can go back by clicking here.
                        </Alert>
                    </Link>
                }
                {error != "" &&
                    <Link to="/">
                        <Alert severity="warning">
                            <AlertTitle>{error}</AlertTitle>
                            You can go back by clicking here.
                        </Alert>
                    </Link>
                }


            </Container>

        </>

    );
}

export default Verify;