import {CreateNodeBody, UpdateNodeBody, VideoNode} from "./NodeModels";
import axios, {AxiosError} from "axios";

export class NodeAPI {
  static baseUrl = process.env.REACT_APP_BACKEND_URL;

  static async nodesOfDiagram(token: string, diagramId: number): Promise<VideoNode[]> {
    const config = {headers: {token: token}};

    const res = await axios.get(this.baseUrl + '/node/ofDiagram/' + diagramId, config);
    return res.data;
  }

  static async createNode(token: string, node: CreateNodeBody): Promise<VideoNode> {
    const config = {headers: {token: token}};

    const res = await axios.post(this.baseUrl + '/node/create', node, config);
    return res.data;
  }
  static async deleteNode(token: string, nodeID: number): Promise<void> {
    const config = {headers: {token: token}};
    const res = await axios.delete(this.baseUrl + '/node/'+ nodeID,config );
    return res.data;
  }
  static async updateNode(token: string, node: UpdateNodeBody): Promise<VideoNode> {
    const config = {headers: {token: token}};
    const res = await axios.post(this.baseUrl + '/node/update', node, config);
    return res.data;
  }
}

