import React, { useEffect } from 'react'
import { useState } from 'react'
import Navbar from '../../components/Navbar'
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, List, ListItem, ListItemText, ListSubheader, Paper } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { DiagramListAPI } from '../../api/DiagramListAPI';
import { Diagram, ThemeList } from '../../api/DiagramModels';
import { DiagramAPI } from '../../api/DiagramAPI';
import { NodeAPI } from '../../api/NodeAPI';
import { useAuth } from '../../hooks/useAuth';
import { Token } from '@mui/icons-material';
import { RiRestartFill } from "react-icons/ri";
import { ProgressAPI } from '../../api/ProgressAPI';
import { BsFillDiagram2Fill } from "react-icons/bs";
import { AiFillPlayCircle } from "react-icons/ai";
import { SlArrowRight } from "react-icons/sl";
import { BsChatLeftTextFill } from "react-icons/bs";
import { FaImage } from "react-icons/fa";
import { RiUserVoiceFill } from "react-icons/ri";
import { IoPlayForwardCircleSharp } from "react-icons/io5";
import { AiFillCheckCircle } from "react-icons/ai";


import ReactPlayer from 'react-player';
import { getTranslation, useLanguage } from '../../LanguageProvider';
import DraggableList from '../../components/DraggableList';
const CourseDetails = () => {
  const { id } = useParams();
  const [courses, setCourses] = useState<Diagram[]>([]);
  const [openDialog, handleDisplay] = React.useState(false);
  const [theme, setTheme] = useState<ThemeList | null>(null);
  const auth = useAuth();
  const [progressData, setProgressData] = useState<any>(null);
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const [openDiagramDialog, setOpenDiagramDialog] = useState(false);
  const [selectedDiagram, setSelectedDiagram] = useState<Diagram | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const { language } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const translation = getTranslation(language);
  const handleCourseSelect = (courseId: string) => {
    // Check if the course is already in the selectedCourses array
    if (!selectedCourses.includes(courseId)) {
      // Add the course to the selectedCourses array
      const newSelectedCourses = [...selectedCourses, courseId];
      setSelectedCourses(newSelectedCourses);

      // Update the DraggableList with the new selectedCourses
      handleSelectedCoursesChange(newSelectedCourses);
    }
  };
  const handleSelectedCoursesChange = (newOrder: string[]) => {
    setSelectedCourses(newOrder);
  };
  const openDiagramDetailsDialog = async (diagram: Diagram) => {
    setSelectedDiagram(diagram);
    setOpenDiagramDialog(true);

    // Diyalog açıldığında video URL'sini getir
    if (diagram && diagram.id) {
      await fetchVideoUrl(diagram.id);
    }
  };
  async function fetchThemeDetails() {
    try {
      if (id) {
        const resolvedToken = await auth.getToken();

        if (resolvedToken !== null) {
          const themeId = parseInt(id);
          const resTheme = await DiagramListAPI.getThemeById(themeId, resolvedToken);
          setTheme(resTheme);

          const diagrams = await DiagramAPI.getAllDiagrams(resolvedToken);
          diagrams.sort((a, b) => a.type - b.type);
          setCourses(diagrams);
        } else {
          console.error('User token is null');
        }
      }
    } catch (error) {
      console.error('Error fetching theme details:', error);
    }
  }
  const handleToggleDrawer = (value: boolean | ((prevState: boolean) => boolean)) => {
    setIsOpen(value);
  }
  const fetchVideoUrl = async (diagramId: number) => {
    if (diagramId) {
      const resolvedToken = await auth.getToken();
      if (resolvedToken !== null) {
        try {
          const nodes = await NodeAPI.nodesOfDiagram(resolvedToken, diagramId);
          if (nodes && nodes.length > 0) {
            const videoNode = nodes[0];
            const videoUrl = videoNode.videoUrl || null;
            if (videoUrl) {
              setVideoUrl(videoUrl);
            } else {
              console.error('Video URL not found in response.');
            }
          } else {
            console.error('No nodes found in response.');
          }
        } catch (error) {
          console.error('Error fetching video URL:', error);
          setVideoUrl(null); // Hata durumunu ele alın
        }
      } else {
        setVideoUrl(null); // Token alınamadığında ele alın
      }
    } else {
      setVideoUrl(null); // diagramId eksik olduğunda ele alın
    }
  };
  async function fetchProgressData() {
    try {
      const resolvedToken = await auth.getToken();

      if (resolvedToken !== null) {
        const courseIds = theme?.container ? theme.container.map(courseId => parseInt(courseId)) : [];
        const progress = await ProgressAPI.getProgress(courseIds, resolvedToken);
        setProgressData(progress);
        console.log('Progress Data:', progress)
      } else {
        console.error('User token is null');
      }
    } catch (error) {
      console.error('Error fetching progress data:', error);
    }
  }
  useEffect(() => {

    fetchThemeDetails();

  }, [id]);
  useEffect(() => {
    if (theme?.container) {
      // Create an array to store the detailed information of diagrams
      const diagramDetailsPromises = theme.container.map(async (diagramId) => {
        try {
          const resolvedToken = await auth.getToken();
          if (resolvedToken !== null) {
            // Fetch diagram details for the current diagramId
            const diagramDetail = await DiagramAPI.getDiagram(resolvedToken, parseInt(diagramId));
            return diagramDetail;
          } else {
            console.error('User token is null');
            return null;
          }
        } catch (error) {
          console.error('Error fetching diagram details:', error);
          return null;
        }
      });

      // Use Promise.all to wait for all diagram details to be fetched
      Promise.all(diagramDetailsPromises).then((diagramDetails) => {
        // diagramDetails will be an array of diagram details or null for any errors
        // You can handle this data as needed
        console.log('Diagram Details:', diagramDetails);
        fetchProgressData();
      });
    }
  }, [theme]);
  const openDialogBox = () => {
    // Açıldığında, önceden eklenmiş diagram ID'lerini selectedCourses'e ekleyin
    if (theme?.container) {
      setSelectedCourses(theme.container);
    }
    handleDisplay(true);
  };
  const getProgressForDiagram = (diagramId: string | number) => {
    if (progressData && progressData[diagramId] !== undefined) {
      return progressData[diagramId];
    }
    return 0; // Return 0 if no progress data found
  };
  const handleClose = () => {
    handleDisplay(false);
  };
  const handleScroll = () => {
    // Sayfanın yukarı kaydırılacak hedef elementini seçin
    const targetElement = document.getElementById('curriculum');

    if (targetElement) {
      // Elementin yüksekliğini veya herhangi bir belirli konumunu alabilirsiniz
      const targetPosition = targetElement.offsetTop;

      // Sayfayı hedef konuma kaydırın
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth', // Daha yumuşak bir kaydırma efekti için 'smooth' kullanabilirsiniz
      });
    }
  };

  const addCoursesToTheme = async () => {
    if (theme) {
      try {
        const resolvedToken = await auth.getToken();

        if (resolvedToken !== null) {
          // Önceden eklenmiş olanları seçilenlerden filtrele
          const newCoursesToAdd = selectedCourses.filter((courseId) => !theme.container.includes(courseId));

          // Reorder the courses based on the selected order
          const reorderedCourses = selectedCourses.map((courseId) => courseId.toString()); // Convert to strings

          const updatedTheme = await DiagramListAPI.updateTheme(
            theme.id,
            {
              title: theme.title, // Retain the existing title
              description: theme.description, // Retain the existing description
              level: theme.level, // Retain the existing level
              container: reorderedCourses, // Use the reorderedCourses (array of strings)
            },
            resolvedToken
          );
          setTheme(updatedTheme);
          fetchThemeDetails();
        } else {
          console.error('User token is null');
        }
      } catch (error) {
        console.error('Error updating theme:', error);
      }

      handleClose();
    }
  };
  return (
    <div>


      <Navbar toggleNavbarDrawer={handleToggleDrawer} data={"home"} />
      <div className="page-wrapper">

        <section className="course-details">
          <div className="container">
            <div className="row">

              <div className="col-xl-8 col-lg-8">
                <div className="course-details__content">

                  <div className="courses-one__single style2 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                    <div className="courses-one__single-img">
                      <img src={process.env.PUBLIC_URL + '/assets/images/resources/course-details-img1.jpg'} alt="" />
                      <div className="overlay-text">

                      </div>
                    </div>
                    <div className="courses-one__single-content">

                      <h4 className="courses-one__single-content-title">{theme?.title}</h4>



                      <div className="course-details__content-text2">
                        <p>{theme?.description}</p>
                      </div>



                    </div>
                  </div>



                  <div id='curriculum' className="course-details__curriculum">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <h2 className="course-details__curriculum-title">Curriculum</h2>
                      <button onClick={openDialogBox} className="thm-btn courses-one--courses__top-btn" style={{ fontSize: '14px', padding: '8px 16px', alignSelf: 'center', marginBottom: '40px' }}>
                        {translation.addDiagram}
                      </button>
                    </div>


                    <ul className="course-details__curriculum-list list-unstyled">
                      {theme?.container ? (
                        theme.container.map((courseId: string, index: number) => {
                          // Find the corresponding diagram based on courseId
                          const diagram = courses.find((diagram) => diagram.id.toString() === courseId);
                          if (diagram) {
                            const progress = getProgressForDiagram(diagram.id);
                            let previous: number = 0;

                            if (index > 0) {
                              // Önceki diagramların ilerlemesini diziye ekleyin
                              for (let i = 0; i < index; i++) {
                                const previousCourseId = theme.container[i];
                                const previousDiagram = courses.find((diagram) => diagram.id.toString() === previousCourseId);
                                if (previousDiagram) {
                                  const previousProgress = getProgressForDiagram(previousDiagram.id);
                                  previous = previousProgress;

                                }
                              }
                            }
                            console.log('Previous:', previous)
                            return (
                              <li key={diagram.id}>
                                <div className="course-details__curriculum-list-left">
                                  <div className="course-details__curriculum-list-left-icon">
                                    {diagram.type === 0 ? (
                                      <BsChatLeftTextFill color="white" style={{ marginBottom: 5 }} />
                                    ) : diagram.type === 1 ? (
                                      <FaImage color="white" style={{ marginBottom: 5 }} />
                                    ) : diagram.type === 2 ? (
                                      <RiUserVoiceFill color="white" style={{ marginBottom: 5 }} />
                                    ) : null}
                                  </div>
                                  <a href="#" className="course-details__curriculum-list-left-title">
                                    {diagram.title}
                                    <span>
                                      {progress === 0
                                        ? translation.notStarted
                                        : progress === 1
                                          ? translation.inProgressDiagram
                                          : progress === 2
                                            ? translation.completed
                                            : translation.unknown}
                                    </span>
                                  </a>
                                </div>
                                <div className="course-details__curriculum-list-right">
                                  <IconButton
                                    color="success"
                                    aria-label="add to shopping cart"
                                    onClick={() => openDiagramDetailsDialog(diagram)}
                                  >
                                    <AiFillPlayCircle size={32} style={{ color: "#00e686" }} />
                                  </IconButton>
                                  {/*previous === 2 || index === 0 ? (
                                    progress === 0 ? (

                                      <IconButton
                                        color="success"
                                        aria-label="add to shopping cart"
                                        onClick={() => openDiagramDetailsDialog(diagram)}
                                      >
                                        <AiFillPlayCircle size={32} style={{ color: "#00e686" }} />
                                      </IconButton>

                                    ) : progress === 1 ? (
                                      <Link to={`/player/${diagram.id}`}>
                                        <IconButton
                                          color="success"
                                          aria-label="add to shopping cart"
                                        >
                                          <IoPlayForwardCircleSharp size={32} style={{ color: "#00e686" }} />
                                        </IconButton>
                                      </Link>
                                    ) : (
                                      <IconButton
                                        color="success"
                                        aria-label="add to shopping cart"
                                        disabled={true}
                                      >
                                        <AiFillCheckCircle size={32} style={{ color: "#60c6c1" }} />
                                      </IconButton>

                                    )
                                  ) : (
                                    <IconButton
                                      color="error"
                                      aria-label="add to shopping cart"
                                      disabled={true}
                                    >
                                      <AiFillPlayCircle size={32} style={{ color: "#dbd8ce" }} />
                                    </IconButton>
                                  )*/}
                                </div>
                              </li>
                            );
                          } else {
                            return null; // Diagram bulunamadığında bu kısmı ele alın
                          }
                        })
                      ) : (
                        <p>Loading...</p>
                      )}
                    </ul>


                  </div>



                </div>
              </div>



              <div className="col-xl-4 col-lg-4">
                <div className="course-details__sidebar">
                  <div className="course-details__price wow fadeInUp animated" data-wow-delay="0.1s">

                    <div onClick={() => handleScroll()} className="course-details__price-btn">
                      <a className="thm-btn">{translation.enrollThisCourse}</a>
                    </div>
                  </div>

                  <div className="course-details__sidebar-meta wow fadeInUp animated" data-wow-delay="0.3s">
                    <ul className="course-details__sidebar-meta-list list-unstyled">


                      <li className="course-details__sidebar-meta-list-item">
                        <div className="icon">
                          <a href=""><i className="far fa-folder-open"></i></a>
                        </div>
                        <div className="text">
                          <p><a href="#">{translation.lectures}:<span> {theme?.container ? theme.container.length : 0}</span></a></p>
                        </div>
                      </li>
                      <li className="course-details__sidebar-meta-list-item">
                        <div className="icon">
                          <a href=""><i className="far fa-bell"></i></a>
                        </div>
                        <div className="text">
                          <p><a href="#">{translation.language}:<span> Turkish</span></a></p>
                        </div>
                      </li>
                    </ul>
                  </div>


                </div>
              </div>

            </div>
          </div>
        </section>
      </div>

      <Dialog onClose={handleClose} open={openDialog} maxWidth="md"
        PaperProps={{
          sx: { borderRadius: "30px", overflow: 'hidden', },
          style: {
            width: '80%', // Set the width as a percentage or in pixels as needed
            maxHeight: '98vh',
            overflow: 'hidden',
            overflowY: 'hidden',
          },
        }}>
        <DialogTitle>Select Courses</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the diagrams you want to add.
          </DialogContentText>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Paper style={{ maxHeight: 700, overflow: 'auto' }}>
                <List
                  subheader={<ListSubheader sx={{ display: 'flex', justifyContent: 'center' }}>
                    <strong>All Diagrams</strong>
                  </ListSubheader>}
                >
                  {courses.map((course) => (
                    <Box>
                      <ListItem
                        key={course.id}
                        style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
                        onClick={() => handleCourseSelect(course.id.toString())}
                      >
                        <ListItemText primary={course.title} />
                        <Chip sx={{ minWidth: '70px' }} size='small' variant='outlined'
                          label={
                            course.type === 0
                              ? translation.written
                              : course.type === 1
                                ? translation.visual
                                : course.type === 2
                                  ? translation.vocal
                                  : translation.unknown
                          }
                        />
                      </ListItem>
                    </Box>
                  ))}
                </List>
              </Paper>
            </div>
            <div style={{ width: '0.5px', backgroundColor: 'gray' }} />

            <div style={{ flex: 1 }}>
              {/* Display the selected courses */}
              <Paper sx={{ maxHeight: 700, overflow: 'auto', backgroundColor: 'transparent', paddingX: '5px', height: '100%' }}>
                <DraggableList
                  key={selectedCourses.join(",")} // Anahtarı seçilen derslerin birleştirilmiş hali olarak ayarlayın
                  data={selectedCourses}
                  onDragEnd={handleSelectedCoursesChange}
                />
              </Paper>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" onClick={addCoursesToTheme}>
            Update Course
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{
          backdropFilter: "blur(5px) sepia(5%)",
          overflow: 'hidden',
          overflowY: 'hidden',
        }}
        // 👇 Props passed to Paper (modal content)
        onClose={() => setOpenDiagramDialog(false)}
        open={openDiagramDialog}
        maxWidth="md"
        PaperProps={{
          sx: { borderRadius: "30px", overflow: 'hidden', },
          style: {
            width: '80%', // Set the width as a percentage or in pixels as needed
            maxHeight: '98vh',
            overflow: 'hidden',
            overflowY: 'hidden',
          },
        }}
      >
        <DialogTitle style={{ backgroundColor: '#60c6c1', color: 'white', display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
          <span style={{ flex: 1, textAlign: 'center' }}>
            {selectedDiagram && selectedDiagram.title ? selectedDiagram.title : 'No diagram selected'}
          </span>
          <Chip label={selectedDiagram && selectedDiagram.type === 0 ? 'Written' : selectedDiagram && selectedDiagram.type === 1 ? 'Visual' : 'Vocal'} style={{ color: 'white' }} />
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          {selectedDiagram ? (
            <div style={{}}>

              <div style={{
                backgroundColor: 'black',
                margin: '10px',
                padding: '10px',
                position: 'relative',
                paddingTop: '40.25%',
                border: '2px solid #ccc', // Çerçeve eklemek için
                borderRadius: '10px', // Köşeleri yuvarlamak için
              }}>
                <ReactPlayer
                  url={videoUrl || ''}
                  style={{ position: 'absolute', top: '0', left: '0' }}
                  width="100%"
                  height="100%"
                  volume={0}
                  playing={true}
                  controls={false}
                />
              </div>
              <Divider variant="middle" sx={{ marginBottom: '16px' }} />
              <h4 style={{ marginBottom: '16px' }}>
                {translation.whatIsThis}
              </h4>
              <span style={{ marginRight: '8px' }}>➔</span>{selectedDiagram && selectedDiagram.topic ? selectedDiagram.topic : 'No diagram selected'}

              <h4 style={{ marginBottom: '8px', marginTop: '20px' }}>{translation.whatIsThisScenario}</h4>
              <p style={{ marginBottom: '24px' }}>
                <span style={{ marginRight: '8px' }}>➔</span>{selectedDiagram && selectedDiagram.scenario ? selectedDiagram.scenario : 'No diagram selected'}
              </p>

              <h4 style={{ marginBottom: '8px', marginTop: '20px' }}>{translation.targetGrammar}</h4>
              <p style={{ marginBottom: '24px' }}>
                <span style={{ marginRight: '8px' }}>➔</span>{selectedDiagram && selectedDiagram.targetGrammarRules ? selectedDiagram.targetGrammarRules : 'No diagram selected'}
              </p>

              <h4 style={{ marginBottom: '8px', marginTop: '20px' }}>{translation.usableGrammars}</h4>
              <p style={{ marginBottom: '24px' }}>
                <span style={{ marginRight: '8px' }}>➔</span>{selectedDiagram && selectedDiagram.usableGrammarRules ? selectedDiagram.usableGrammarRules : 'No diagram selected'}
              </p>

              {/* Add more details as needed */}
            </div>
          ) : (
            <p>No diagram selected.</p>
          )}
        </DialogContent>
        <DialogActions>
          {selectedDiagram ? ( // Check if selectedDiagram is not null or undefined
            <Link to={`/player/${selectedDiagram.id}`}>
              <button
                className="dialog-btn courses-one--courses__top-btn"
                onClick={() => setOpenDiagramDialog(false)}
                style={{ fontSize: '14px', padding: '12px 8px', margin: '16px 24px' }}
              >
                {translation.startDiagram} <SlArrowRight />
              </button>
            </Link>
          ) : (
            <p>Selected diagram is null or undefined.</p> // Handle the case where selectedDiagram is null or undefined
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CourseDetails