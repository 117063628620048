import {
    Alert, AlertTitle,
    Avatar,
    Box,
    Button, CircularProgress,
    Container,
    createTheme,
    CssBaseline,
    Grid,
    TextField, ThemeProvider,
    Typography
} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import LockIcon from "@mui/icons-material/Lock";
import Copyright from "../../components/Copyright";
import * as EmailValidator from "email-validator";
import zxcvbn from "zxcvbn";
import {AccountAPI} from "../../api/AccountAPI";
import {Link, useParams} from "react-router-dom";


const theme = createTheme();
export default function ResetPassword() {
    let {code} = useParams();
    const [passwordColorRW, setPasswordColorRW] = useState<boolean>(false)
    const [passwordColor, setPasswordColor] = useState("primary")
    const [helperText, setHelperText] = useState<string | undefined>(undefined)
    const [newPassword, setNewPassword] = useState('')
    const [button, setButton] = useState<boolean|undefined>(undefined)
    const [newPasswordRW, setNewPasswordRW] = useState('')
    const [changeSuccess, setChangeSuccess] = useState(false);
    const [error, setError] = useState('');
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!code) {
            return;
        }
        AccountAPI.recoverPassword(code, newPassword).then((success) => {
            if (success) {
                setChangeSuccess(true);
                setButton(true);
                setNewPassword("");
                setNewPasswordRW("");
            } else {
                setError("Url is invalid or expired.");
            }
        }).catch(() => {
            setError("Unknown error.");
        });
    };
    const passwordOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.currentTarget.value);
        const score = zxcvbn(event.currentTarget.value).score;
        if (event.currentTarget.value == "") {
            setPasswordColor("primary");
            setHelperText(undefined);
        } else if (score < 3 || event.currentTarget.value.length < 8) {
            setPasswordColor("error");
            setHelperText("Password is weak");
            console.log(score)
        } else {
            setPasswordColor("success");
            setHelperText("Strong password!");
        }
        if (newPasswordRW != '') {
            controlPassword(event.currentTarget.value, newPasswordRW)
        }
    }
    const controlPassword = (tempPassword: string, tempPasswordRW: string) => {

        setNewPasswordRW(tempPasswordRW);
        if (tempPassword == tempPasswordRW) {
            setPasswordColorRW(false)
        } else {
            setPasswordColorRW(true)
        }
        if (tempPasswordRW == '') {
            setPasswordColorRW(false)
        }
    }
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main', width: 64, height: 64}}>
                        <LockIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        New Password.
                    </Typography>
                    <Typography sx={{mx: "5%"}} variant="subtitle1" component="h2">
                        Please enter your new password.
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1, width: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            color={passwordColor as any}
                            error={passwordColor == "error" ? true : false}
                            name="newPassword"
                            label="New Password"
                            type="password"
                            id="newPassword"
                            helperText={helperText}
                            value={newPassword}
                            onChange={passwordOnChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            error={passwordColorRW}
                            name="newPasswordRW"
                            label="Retype password to confirm"
                            type="password"
                            id="newPasswordRW"
                            value={newPasswordRW}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setNewPasswordRW(event.currentTarget.value);
                                controlPassword(newPassword, event.currentTarget.value)
                            }}
                        />
                        <Button
                            disabled={button}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 5}}
                        >
                            Reset Password
                        </Button>
                    </Box>
                </Box>
                {changeSuccess &&
                    <Link to="/login">
                        <Alert severity="success">
                            <AlertTitle>Password is changed!</AlertTitle>
                            You can go to login page by clicking here.
                        </Alert>
                    </Link>
                }
                {error != "" &&
                    <Link to="/forgotPassword">
                        <Alert severity="warning">
                            <AlertTitle>{error}</AlertTitle>
                            You can go back by clicking here.
                        </Alert>
                    </Link>
                }
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    )
}