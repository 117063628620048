import Box from "@mui/material/Box";
import {Button, Grid, IconButton, Paper, styled, TextField, Typography} from "@mui/material";
import React, {ChangeEvent, useState} from "react";
import {AddTarget} from "./addTarget";
import {GridAddIcon} from "@mui/x-data-grid";
import {VideoNode} from "../../api/NodeModels";
import SaveIcon from '@mui/icons-material/Save';
import {NodeAPI} from "../../api/NodeAPI";
import {useAuth} from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import Popover from "@mui/material/Popover";

export function EditNode({
                             data,
                             saved,
                             savedSetter,
                             setter,
                             selectedItem
                         }: {
    data: VideoNode[],
    saved: boolean,
    savedSetter:(newSaved:boolean)=>void,
    setter: (newData: VideoNode[]) => void,
    selectedItem: number
}) {
    const auth = useAuth()
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    function addTarget() {
        const node: VideoNode[] = [...data]
        node[selectedItem].jumps.push({target: "", error6: "", conditions: []})
        setter(node)
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        updateNode()
        savedSetter(false)
    };

    async function updateNode() {
        saved = false
        const token = await auth.getToken()
        if (!token) {
            navigate("/login");
        }
        await NodeAPI.updateNode(token!, {
            nodeId: data[selectedItem].id,
            code: data[selectedItem].code,
            videoUrl: data[selectedItem].videoUrl,
            error3: data[selectedItem].error3,
            jumps: data[selectedItem].jumps
        })
    }

    return (
        <>
            <Paper elevation={2}
                   sx={{
                       borderRadius: 5,
                       backgroundColor: "white",
                       "padding": 2,
                   }}
            >
                <Typography component="h1" variant="h5">
                    Edit Node
                </Typography>
                <TextField
                    value={data[selectedItem].code}
                    onChange={
                        (event: React.ChangeEvent<HTMLInputElement>) => {
                            const node: VideoNode[] = [...data]
                            node[selectedItem].code = event.currentTarget.value
                            setter(node)
                        }
                    }
                    margin="normal"
                    required
                    fullWidth
                    id="code"
                    label="Code"
                    name="code"
                />
                <TextField
                    value={data[selectedItem].videoUrl}
                    onChange={
                        (event: React.ChangeEvent<HTMLInputElement>) => {
                            const node: VideoNode[] = [...data]
                            node[selectedItem].videoUrl = event.currentTarget.value
                            setter(node)
                        }
                    }
                    margin="normal"
                    required
                    fullWidth
                    id="videoLink"
                    label="Video Link"
                    name="videoLink"
                />
                <TextField
                    value={data[selectedItem].error3}
                    onChange={
                        (event: React.ChangeEvent<HTMLInputElement>) => {
                            const node: VideoNode[] = [...data]
                            node[selectedItem].error3 = event.currentTarget.value
                            setter(node)
                        }
                    }
                    margin="normal"
                    required
                    fullWidth
                    id="3ErrorVideoLink"
                    label="3 Error Video Link"
                    name="3ErrorVideoLink"
                />
            </Paper>
            <Grid container spacing={2} direction={"row"} alignItems={"stretch"}>
                {data[selectedItem].jumps.map((mapArray, index) => (
                    <Grid item key={index} xs={12}>
                        <AddTarget data={data} setter={setter} indexes={[selectedItem, index]}></AddTarget>
                    </Grid>
                ))}
            </Grid>
            <Box
                sx={{
                    pt: 2,
                    display: 'flex',
                }}

                justifyContent={"space-around"}
            >
                <Button
                    type="submit"
                    onClick={addTarget}
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    startIcon={<GridAddIcon/>}
                >Add Target</Button>
                <Button
                    type="submit"
                    onClick={
                        handleClick}
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    startIcon={<SaveIcon/>}
                >Save changes</Button>
                <Popover
                    open={open}
                    onClose={() => {
                        setAnchorEl(null);
                    }}
                    anchorReference="anchorPosition"
                    anchorPosition={{top: 900, left: 1400}}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "right"
                    }}
                    sx={{
                        borderRadius: 5

                    }}
                ><Typography fontSize={20}>Node saved.</Typography></Popover>
            </Box>
        </>
    )
}