import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Alert, Avatar, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Snackbar, TextField, Tooltip } from '@mui/material';
import { BiEditAlt } from 'react-icons/bi';
import { BsSendCheck } from 'react-icons/bs';
import { MdPassword } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { BiReset } from 'react-icons/bi';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { AuthUser, useAuth } from "../../hooks/useAuth";
import Navbar from '../../components/Navbar';
import { User } from '../../api/UserModel';
import { AccountAPI } from '../../api/AccountAPI';
import { useNavigate } from 'react-router-dom';
import { ProgressAPI } from '../../api/ProgressAPI';
import { getTranslation, useLanguage } from '../../LanguageProvider';
const Pronew = () => {

    useEffect(() => {
        document.body.style.backgroundColor = "#F4FBFA";
        return () => {
            document.body.style.backgroundColor = "";
        };
    }, []);
    const [selectedTab, setSelectedTab] = useState(0);
    const [passwordChangeDialog, setPasswordChangeDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [resetDialog, setResetDialog] = useState(false);
    const [isUppercaseValid, setIsUppercaseValid] = useState(false);
    const [isLowercaseValid, setIsLowercaseValid] = useState(false);
    const [isDigitValid, setIsDigitValid] = useState(false);
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isDeleteCheckboxSelected, setIsDeleteCheckboxSelected] = useState(false);
    const [isResetCheckboxSelected, setIsResetCheckboxSelected] = useState(false);
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [isUserDeleted, setIsUserDeleted] = useState(false);
    const [isProgressReset, setIsProgressReset] = useState(false);
    const [isVerifyResend, setIsVerifyResend] = useState(false);
    const { language } = useLanguage();
    const translation = getTranslation(language);
    let navigate = useNavigate();
    const [userDetails, setUserDetails] = useState<AuthUser | null>(null);
    const auth = useAuth();

    const [isOpen, setIsOpen] = useState(false);

    const handleToggleDrawer = (value: boolean | ((prevState: boolean) => boolean)) => {
        setIsOpen(value);
    }
    const handlePasswordChange = (event: { target: { value: any; }; }) => {
        const newPassword = event.target.value;
        setIsUppercaseValid(/[A-Z]/.test(newPassword));
        setIsLowercaseValid(/[a-z]/.test(newPassword));
        setIsDigitValid(/[0-9]/.test(newPassword));
        setIsLengthValid(newPassword.length >= 8);
    };
    const isSubmitDisabled = !(
        isUppercaseValid &&
        isLowercaseValid &&
        isDigitValid &&
        isLengthValid
    );
    const handleCloseSnackbar = () => {
        setIsUserDeleted(false);
    };
    const resetProgress = async () => {

        const token = await auth.getToken();
        if (!token) {

            return;
        }
        try {
            const success = await ProgressAPI.resetProgress(token);
            setIsProgressReset(true);
            setTimeout(() => {
                setIsProgressReset(false);
            }, 2000);
            if (success) {

                console.log(success);
            } else {
                console.log("error")
            }
        } catch (error) {
            console.log(error);
        }
    };

    const verifyResend = async () => {
        const token = await auth.getToken();
        if (!token) {

            return;
        }
        try {
            const success = await AccountAPI.verifyResend(token);
            if (success) {
                setIsVerifyResend(true);
                setTimeout(() => {
                    setIsVerifyResend(false);
                }, 2000);

                console.log(success);
            } else {

            }
        } catch (error) {

        }
    };

    useEffect(() => {
        // Check if the passwords match whenever newPassword or confirmNewPassword changes
        if (newPassword !== confirmNewPassword) {
            setPasswordMatch(false);
        } else {
            setPasswordMatch(true);
        }
    }, [newPassword, confirmNewPassword]);
    const openPasswordChangeDialog = async () => {
        setPasswordChangeDialog(true);
    };
    const closePasswordChangeDialog = async () => {
        setPasswordChangeDialog(false);
        setIsUppercaseValid(false);
        setIsLowercaseValid(false);
        setIsDigitValid(false);
        setIsLengthValid(false);
    };

    const openDeleteDialog = () => {
        setDeleteDialog(true);
    };

    const closeDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const openResetDialog = () => {
        setResetDialog(true);
    };

    const closeResetDialog = () => {
        setResetDialog(false);
    };
    const handleTabChange = (event: any, newValue: React.SetStateAction<number>) => {
        setSelectedTab(newValue);
    };
    const fetchUserDetails = async () => {
        try {
            const token = await auth.getToken(); // Token'i asenkron olarak alın
            const userDetails = await AccountAPI.getDetails(token!.toString());
            setUserDetails(userDetails);
            console.log(userDetails);
        } catch (error) {
            // Hata durumunda kullanıcıya uygun bir hata mesajı gösterin veya işlem yapın.
            console.error('Kullanıcı bilgilerini alma hatası:', error);
        }
    };
    const handleChangePassword = async () => {

        try {
            const token = await auth.getToken(); // Get the user's token
            const success = await AccountAPI.changePassword(oldPassword, newPassword, token!.toString());

            if (success) {
            } else {
            }
        } catch (error) {
            console.error('Password change error:', error);
        }
    };
    const handleDeleteAccount = async () => {
        try {
            const token = await auth.getToken(); // Kullanıcının token'ını alın
            await AccountAPI.deleteAccount(token!.toString());
            setIsUserDeleted(true);
            setTimeout(() => {
                navigate('/login');
            }, 2000);

        } catch (error) {
            console.error('Hesap silme hatası:', error);
        }
    };
    const getUserPermissionText = (permission: any) => {
        switch (permission) {
            case 0:
                return translation.unverifieduser;
            case 10:
                return translation.user;
            case 20:
                return translation.tutor;
            case 30:
                return translation.mod;
            case 40:
                return translation.admin;
            default:
                return translation.unknown; // Bilinmeyen izin seviyesi için varsayılan metin
        }
    };
    const getFormattedDate = (dateString: string | number | Date | undefined) => {
        if (!dateString) return ''; // Eğer tarih bilgisi yoksa boş bir dize döndürün veya uygun bir hata mesajı gösterebilirsiniz.

        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

        return date.toLocaleDateString(undefined, options);
    };
    useEffect(() => {
        // Kullanıcı giriş yaptığında veya token değiştiğinde kullanıcı bilgilerini alın

        fetchUserDetails();
    }, [auth]);

    return (
        <>
            <Navbar toggleNavbarDrawer={handleToggleDrawer} data={"home"} />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '90vh' }}>
                <Card style={{ display: 'flex', flexDirection: 'row', width: '1200px', height: '800px', boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.1)', }}>
                    {/* Sol taraftaki tablar */}
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        orientation="vertical"
                        style={{ flexBasis: '20%', borderRight: '1px solid #ccc' }}
                    >
                        <Tab
                            label={translation.profile}
                            sx={{
                                bgcolor: selectedTab === 0 ? '#e8f8f6' : 'transparent', color: selectedTab === 0 ? '#1dbf73' : '#667c88', borderRadius: '16px', marginX: '40px', marginY: '4px', fontSize: '12px', // Tab font boyutu fontFamily: 'Kumbh Sans, sans-serif', // Yazı tipi fontWeight: 'bold', // Kalın yazı tıklanan sekmede textTransform: 'capitalize', // Sadece baş harfi büyük yapar
                            }}
                        />
                        <Tab
                            label={translation.security}
                            sx={{
                                bgcolor: selectedTab === 1 ? '#e8f8f6' : 'transparent', borderRadius: '16px', marginX: '40px', marginY: '4px', fontSize: '12px', // Tab font boyutu fontFamily: 'Kumbh Sans, sans-serif', // Yazı tipi fontWeight: 'bold', // Kalın yazı tıklanan sekmede textTransform: 'capitalize', // Sadece baş harfi büyük yapar
                            }}
                        />
                    </Tabs>
                    {/* Sağ taraftaki içerik */}
                    <CardContent style={{ flexBasis: '80%' }}>
                        {selectedTab === 0 && (
                            <div>
                                <div>
                                    <h5 style={{ fontFamily: 'Kumbh Sans, sans-serif', fontWeight: 'bold', margin: '0', padding: '10px', display: 'flex', alignItems: 'center' }}>
                                        {translation.myprofile}

                                    </h5>
                                </div>
                                {/* Sekme 1 içeriği buraya gelir */}
                                <div style={{ border: '1px solid #e8f8f6', borderRadius: '16px', padding: '10px', margin: '10px', display: 'flex', alignItems: 'center' }}>
                                    {/* Sol taraftaki içerik */}
                                    <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                                        {/* Varsayılan İkon */}
                                        <div style={{ width: '60px', height: '60px', borderRadius: '50%', marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/pro_pic.png'} alt="Profil Resmi" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                                        </div>
                                        {/* Kullanıcı Bilgileri */}
                                        <div>
                                            <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', fontWeight: 'bold', margin: '0', paddingTop: '10px' }}>{userDetails?.email}</h6>
                                            <p style={{ margin: '0', fontSize: '14px', fontFamily: 'Kumbh Sans, sans-serif', color: '#66738c', fontWeight: '600' }}>{getUserPermissionText(userDetails?.permission)}</p>
                                        </div>
                                    </div>

                                    <Chip
                                        label={userDetails?.permission == 0 ? translation.unverified : translation.verified}
                                        color={userDetails?.permission == 0 ? 'error' : 'success'}
                                        style={{ marginLeft: '8px' }}
                                    />
                                </div>
                                <div style={{ border: '1px solid #e8f8f6', borderRadius: '16px', padding: '10px', margin: '10px', }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px', margin: '10px' }}>
                                        <div style={{ flex: 1 }}>
                                            <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0' }}>{translation.personalinformation}</h6>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center', padding: '10px', margin: '10px' }}>
                                            <div style={{ flex: 1 }}>
                                                <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', color: '#949eae', fontSize: '14px' }}>{translation.emailadress}</h6>
                                                <p style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '15px', fontWeight: 'bold' }}>{userDetails?.email}</p>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', color: '#949eae', fontSize: '14px' }}>
                                                    {translation.dateofbirth}
                                                </h6>
                                                <p style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '15px', fontWeight: 'bold' }}>{getFormattedDate(userDetails?.birthday)}</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', padding: '10px', margin: '10px' }}>
                                            <div style={{ flex: 1 }}>
                                                <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', color: '#949eae', fontSize: '14px' }}>{translation.country}</h6>
                                                <p style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '15px', fontWeight: 'bold' }}>{userDetails?.country}</p>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', color: '#949eae', fontSize: '14px' }}>{translation.gender}</h6>
                                                <p style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '15px', fontWeight: 'bold' }}>{userDetails?.gender}</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', padding: '10px', margin: '10px' }}>
                                            <div style={{ flex: 1 }}>
                                                <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', color: '#949eae', fontSize: '14px' }}>{translation.education}</h6>
                                                <p style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '15px', fontWeight: 'bold' }}>{userDetails?.education}</p>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', color: '#949eae', fontSize: '14px' }}>{translation.nativeLanguage}</h6>
                                                <p style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '15px', fontWeight: 'bold' }}>{userDetails?.nativeLanguage}</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', padding: '10px', margin: '10px' }}>
                                            <div style={{ flex: 1 }}>
                                                <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', color: '#949eae', fontSize: '14px' }}>
                                                    {translation.otherLanguages}
                                                </h6>
                                                <div>
                                                    {/* Display other languages as chips */}
                                                    {userDetails?.otherLanguages.map((lang, index) => (
                                                        <Chip style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '15px', fontWeight: 'bold', marginRight: '5px' }} label={lang} variant="outlined" key={index} />
                                                    ))}
                                                </div>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                {/* İkinci sırada bilgi olmadığı için boş bırakabilirsiniz */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                        {selectedTab === 1 && (
                            <div>
                                <h5 style={{ fontFamily: 'Kumbh Sans, sans-serif', }}>{translation.secsettings}</h5>
                                <div style={{ border: '1px solid #e8f8f6', borderRadius: '16px', padding: '10px', margin: '10px', }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px', margin: '10px' }}>
                                        <div style={{ flex: 1 }}>
                                            <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '14px' }}>{translation.emailadress}</h6>
                                            <p style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '13px' }}>{translation.theemailaddressassociatedwithyouraccount}</p>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                                            {/* Email ve Chip */}
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px' }}>
                                                <div style={{}}>
                                                    <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '14px' }}>{userDetails?.email}</h6>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Chip
                                                        label={userDetails?.permission == 0 ? translation.unverified : translation.verified}
                                                        color={userDetails?.permission == 0 ? 'error' : 'success'}
                                                        style={{ fontSize: '11px', marginTop: '5px' }}
                                                    />
                                                </div>
                                            </div>

                                            {/* Send Butonu */}
                                            <button
                                                disabled={userDetails?.permission !== 0}
                                                className={`edit-button${userDetails?.permission !== 0 ? ' disabled' : ''}`}
                                                style={{ justifySelf: 'end' }}
                                                onClick={() => { verifyResend() }}
                                            >
                                                {translation.send} <BsSendCheck style={{ fontSize: '18px', marginLeft: '5px' }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ border: '1px solid #e8f8f6', borderRadius: '16px', padding: '10px', margin: '10px', }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px', margin: '10px' }}>
                                        <div style={{ flex: 1 }}>
                                            <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '14px' }}>{translation.password}</h6>
                                            <p style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '13px' }}>{translation.setauniquepasswordtoprotectyouraccount}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <button onClick={() => { openPasswordChangeDialog() }} className='edit-button'>
                                                {translation.changepassword}   <MdPassword style={{ fontSize: '18px', marginLeft: '5px' }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ border: '1px solid #e8f8f6', borderRadius: '16px', padding: '10px', margin: '10px', }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px', margin: '10px' }}>
                                        <div style={{ flex: 1 }}>
                                            <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '14px' }}>{translation.deleteaccount}</h6>
                                            <p style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '13px' }}>{translation.thiswilldeleteyouraccount}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>


                                            <button onClick={() => { openDeleteDialog() }} className='delete-button' style={{ color: 'red' }}>
                                                {translation.deleteaccount}  <RiDeleteBin2Line style={{ fontSize: '18px', marginLeft: '5px', color: 'red' }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ border: '1px solid #e8f8f6', borderRadius: '16px', padding: '10px', margin: '10px', }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px', margin: '10px' }}>
                                        <div style={{ flex: 1 }}>
                                            <h6 style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '14px' }}>{translation.resetprogress}</h6>
                                            <p style={{ fontFamily: 'Kumbh Sans, sans-serif', margin: '0', fontSize: '13px' }}>{translation.thiswillresetallprogress}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <button onClick={() => { openResetDialog() }} className='delete-button' style={{ color: 'red' }}>
                                                {translation.resetprogress}  <BiReset style={{ fontSize: '18px', marginLeft: '5px', color: 'red' }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </CardContent>
                </Card>
                <Dialog
                    sx={{
                        backdropFilter: "blur(5px) sepia(5%)", overflow: 'hidden', overflowY: 'hidden',
                    }}
                    // 👇 Props passed to Paper (modal content)
                    onClose={() => closePasswordChangeDialog()}
                    open={passwordChangeDialog}
                    maxWidth="md"
                    PaperProps={{
                        sx: { borderRadius: "30px", overflow: 'hidden', },
                        style: {
                            width: '80%', maxHeight: '98vh', overflow: 'hidden', overflowY: 'hidden',
                        },
                    }}
                >
                    <DialogTitle>
                    </DialogTitle>
                    <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {/* Left Side: Text Fields */}

                        {/* Right Side: Password Requirements */}
                        <div style={{ flex: 1 }}>
                            <div>
                                <h2 style={{ fontFamily: 'Kumbh Sans, sans-serif' }}>{translation.changepassword}</h2>
                            </div>
                            <div>
                                <span style={{ fontFamily: 'Kumbh Sans, sans-serif' }}>{translation.passwordmustcontain}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AiOutlineCheckCircle fontSize={24} style={{ color: isUppercaseValid ? 'green' : 'gray', marginRight: '5px', paddingBottom: '5px' }} />
                                <span style={{ fontFamily: 'Kumbh Sans, sans-serif', textDecoration: isUppercaseValid ? 'line-through' : 'none', color: isUppercaseValid ? '#dfdfdf' : 'inherit' }}>{translation.atleast1uppercaseletter}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AiOutlineCheckCircle fontSize={24} style={{ color: isLowercaseValid ? 'green' : 'gray', marginRight: '5px', paddingBottom: '5px' }} />
                                <span style={{ fontFamily: 'Kumbh Sans, sans-serif', textDecoration: isLowercaseValid ? 'line-through' : 'none', color: isLowercaseValid ? '#dfdfdf' : 'inherit' }}>{translation.atleast1lowercaseletter}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AiOutlineCheckCircle fontSize={24} style={{ color: isDigitValid ? 'green' : 'gray', marginRight: '5px', paddingBottom: '5px' }} />
                                <span style={{ fontFamily: 'Kumbh Sans, sans-serif', textDecoration: isDigitValid ? 'line-through' : 'none', color: isDigitValid ? '#dfdfdf' : 'inherit' }}>{translation.atleast1number}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AiOutlineCheckCircle fontSize={24} style={{ color: isLengthValid ? 'green' : 'gray', marginRight: '5px', paddingBottom: '5px' }} />
                                <span style={{ fontFamily: 'Kumbh Sans, sans-serif', textDecoration: isLengthValid ? 'line-through' : 'none', color: isLengthValid ? '#dfdfdf' : 'inherit' }}>{translation.atleast8characters}</span>
                            </div>
                        </div>
                        <div style={{ flex: 1, padding: '20px' }}>
                            <TextField fullWidth label="Old Password" variant="outlined" onChange={(event) => setOldPassword(event.target.value)} type="password"
                            />

                            <TextField fullWidth label="New Password" variant="outlined" type="password" onChange={(event) => {
                                setNewPassword(event.target.value);
                                handlePasswordChange(event); // Call handlePasswordChange with the event
                            }} style={{ marginTop: '20px' }}
                            />

                            <TextField fullWidth label="Confirm Password" variant="outlined" type="password" onChange={(event) => setConfirmNewPassword(event.target.value)} style={{ marginTop: '20px' }} helperText={!passwordMatch && "Passwords must match"} error={!passwordMatch} // Set error to true when passwords don't match
                            />

                            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                                <Button onClick={() => { handleChangePassword() }} variant='contained' color="primary" disabled={isSubmitDisabled || !passwordMatch}>
                                    Save
                                </Button>
                                <Button variant='outlined' sx={{ marginTop: '5px' }} onClick={() => closePasswordChangeDialog()} color="primary">
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>

                    </DialogActions>
                </Dialog>
                <Dialog
                    sx={{
                        backdropFilter: "blur(5px) sepia(5%)", overflow: 'hidden', overflowY: 'hidden',
                    }}
                    onClose={() => closeDeleteDialog()}
                    open={deleteDialog}
                    maxWidth="md"
                    PaperProps={{
                        sx: { borderRadius: "30px", overflow: 'hidden' }, style: { width: '80%', maxHeight: '98vh', overflow: 'hidden', overflowY: 'hidden' },
                    }}
                >
                    <DialogTitle>
                        {translation.confirmaccountdeletion}
                    </DialogTitle>
                    <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <p>
                                {translation.confirmdeletetext}
                            </p>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isDeleteCheckboxSelected}
                                        onChange={(event) => setIsDeleteCheckboxSelected(event.target.checked)}
                                        color="error"
                                    />
                                }
                                label={translation.consequences}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{ display: 'flex', padding: '10px' }}>
                            <Button onClick={() => { handleDeleteAccount() }} variant='contained' color="error" disabled={!isDeleteCheckboxSelected} // Checkbox işaretlenmemişse butonu devre dışı bırak
                            >
                                {translation.deletebutton}
                            </Button>
                            <Button variant='outlined' sx={{ marginLeft: '5px' }} onClick={() => closeDeleteDialog()} color="error"
                            >
                                {translation.cancelbutton}
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
                <Dialog
                    sx={{
                        backdropFilter: "blur(5px) sepia(5%)",
                        overflow: 'hidden',
                        overflowY: 'hidden',
                    }}
                    onClose={() => closeResetDialog()} // Use closeResetDialog to close the dialog
                    open={resetDialog} // Use resetDialog state to control the dialog's visibility
                    maxWidth="md"
                    PaperProps={{
                        sx: { borderRadius: "30px", overflow: 'hidden', },
                        style: {
                            width: '80%',
                            maxHeight: '98vh',
                            overflow: 'hidden',
                            overflowY: 'hidden',
                        },
                    }}
                >
                    <DialogTitle>
                        {translation.confirmresetprogress}
                    </DialogTitle>
                    <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <p>
                                {translation.confirmresettext}
                            </p>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isResetCheckboxSelected}
                                        onChange={(event) => setIsResetCheckboxSelected(event.target.checked)}
                                        color="error"
                                    />
                                }
                                label="I understand the consequences."
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{ display: 'flex', padding: '10px' }}>
                            <Button onClick={() => {
                                resetProgress(); // Call the resetProgress function
                                closeResetDialog(); // Close the dialog
                            }} variant='contained' color="error" disabled={!isResetCheckboxSelected}>
                                {translation.resetbutton}
                            </Button>
                            <Button variant='outlined' sx={{ marginLeft: '5px' }} onClick={() => closeResetDialog()} color="error">
                                {translation.cancelbutton}
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>

                <Snackbar open={isUserDeleted} autoHideDuration={2000} // Snackbar 2 saniye sonra otomatik olarak kapanır onClose={handleCloseSnackbar}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {translation.deletesuccess}
                    </Alert>
                </Snackbar>


                <Snackbar open={isProgressReset} autoHideDuration={2000} // Snackbar 2 saniye sonra otomatik olarak kapanır onClose={handleCloseSnackbar}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {translation.resetprogresssuccess}
                    </Alert>
                </Snackbar>


                <Snackbar open={isVerifyResend} autoHideDuration={2000} // Snackbar 2 saniye sonra otomatik olarak kapanır onClose={handleCloseSnackbar}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {translation.verifyemailsentsuccess}
                    </Alert>
                </Snackbar>

            </div >
        </>
    );
}
export default Pronew;
