import React, { useEffect, useState } from 'react';
import { CircularProgress, Alert, Button, Card, CardActions, CardContent, CardHeader, Chip, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Select, TextField, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Navbar from "../../components/Navbar";
import { DiagramAPI } from '../../api/DiagramAPI';
import { AccountAPI } from '../../api/AccountAPI';
import { AuthorInfo, Diagram } from '../../api/DiagramModels';
import { MdEdit } from "react-icons/md";
import { GoDiffAdded } from 'react-icons/go';
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { ImStackoverflow } from 'react-icons/im';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useAuth } from "../../hooks/useAuth";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import { BsEye } from 'react-icons/bs';
import { HiTrash } from 'react-icons/hi';
import { BsDiagram2 } from 'react-icons/bs';
import { VscDebugRestart } from 'react-icons/vsc';
import { AiOutlineFileDone } from 'react-icons/ai';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { TfiSettings } from 'react-icons/tfi';
import { GoGitMerge } from 'react-icons/go';
import { } from 'react-icons/bs';
import { Convert } from '../../api/UserModel';
import { Link } from 'react-router-dom';
export default function Diagrams() {
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [scenario, setScenario] = useState('');
    const [rules, setRules] = useState('');
    const [targetGrammerRule, setTargetGrammerRule] = useState('');
    const [usableGrammerRule, setUsableGrammerRule] = useState('');
    const [openApprove, setOpenApprove] = React.useState(false);
    const [openReject, setOpenReject] = React.useState(false);
    const [openRemove, setOpenRemove] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [header, setHeader] = useState('');
    const [isSuccess, SetIsSuccess] = useState(false);
    const [approveItem, setApproveItem] = useState(0);
    const [rejectItem, setRejectItem] = useState(0);
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [type, setType] = React.useState('');
    const auth = useAuth();
    const [diagrams, setDiagrams] = useState<Diagram[]>([]);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [filteredDiagrams, setFilteredDiagrams] = useState<Diagram[]>([]);
    const [authors, setAuthors] = useState<AuthorInfo[]>([]);
    const authorsArray: AuthorInfo[] = [];
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedDiagramToDelete, setSelectedDiagramToDelete] = useState<number | null>(null);
    const [diagramTooltipOpen, setdiagramTooltipOpen] = useState(false);
    const [diagramTooltipOpenMap, setdiagramTooltipOpenMap] = useState<Record<number, boolean>>({});
    const [settingsTooltipOpen, setsettingsTooltipOpen] = useState(false);
    const [settingsTooltipOpenMap, setsettingsTooltipOpenMap] = useState<Record<number, boolean>>({});
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleDrawer = (value: boolean | ((prevState: boolean) => boolean)) => {
      setIsOpen(value);
    }
    const toggleDiagramTooltip = (diagramId: number) => {
        setdiagramTooltipOpenMap((prevMap) => ({
            ...prevMap,
            [diagramId]: !prevMap[diagramId]
        }));
    };
    const toggleSettingsTooltip = (diagramId: number) => {
        setsettingsTooltipOpenMap((prevMap) => ({
            ...prevMap,
            [diagramId]: !prevMap[diagramId]
        }));
    };
    const closeAllTooltips = () => {
        setdiagramTooltipOpenMap({});
        setsettingsTooltipOpenMap({});
    };
    const handleApproveDialog = (diagramId: number) => {
        setOpenApprove(true);
        setApproveItem(diagramId);
    };

    const handleRejectDialog = (diagramId: number) => {
        setOpenReject(true);
        setRejectItem(diagramId);
    };
    const handleDeleteDiagram = (diagramId: number) => {
        setSelectedDiagramToDelete(diagramId);
        setDeleteDialogOpen(true);
    };
    const handleDeleteConfirmed = async (diagramId: number | null) => {
        if (diagramId !== null) {
            try {
                const resolvedToken = await auth.getToken();
                const token = resolvedToken;

                await DiagramAPI.deleteDiagram(token!, diagramId);
                console.log(`Diagram with ID ${diagramId} has been deleted.`);
                fetchDiagrams();
                fetchAndSetDiagrams();
            } catch (error) {
                console.error('Error deleting diagram:', error);
            }
        }

        setSelectedDiagramToDelete(null);
    };

    const handleApprove = async () => {
        try {
            const resolvedToken = await auth.getToken();
            const token = resolvedToken;

            await DiagramAPI.approveDiagram(token!, approveItem);
            console.log(`Diagram with ID ${approveItem} has been approved.`);
            setOpenApprove(false);
            fetchDiagrams(); // Refresh diagrams after approval
            fetchAndSetDiagrams();
        } catch (error) {
            console.error('Error approving diagram:', error);
        }
    };

    const handleReject = async () => {
        try {
            const resolvedToken = await auth.getToken();
            const token = resolvedToken;

            await DiagramAPI.rejectDiagram(token!, rejectItem);
            console.log(`Diagram with ID ${rejectItem} has been rejected.`);
            setOpenReject(false);
            setOpenApprove(false);
            fetchDiagrams(); // Refresh diagrams after rejection
            fetchAndSetDiagrams();
        } catch (error) {
            console.error('Error rejecting diagram:', error);
        }
    };
    const [updateDiagramData, setUpdateDiagramData] = useState({
        id: 0,
        startNode: 0,
        title: "",
        topic: "",
        scenario: "",
        rules: "",
        targetGrammarRules: "",
        usableGrammarRules: "",
        type: 0,
        extra: "",
    });

    const openUpdateDialog = (diagramData: Diagram) => {
        const startNode = diagramData.startNode || 0;
        const updatedDiagramData = {
            id: diagramData.id,
            startNode: startNode,
            title: diagramData.title,
            topic: diagramData.topic,
            scenario: diagramData.scenario,
            rules: diagramData.rules,
            targetGrammarRules: diagramData.targetGrammarRules,
            usableGrammarRules: diagramData.usableGrammarRules,
            type: diagramData.type,
            extra: diagramData.extra,
        };
        setUpdateDiagramData(updatedDiagramData);
        setOpenUpdate(true);
    };

    const closeUpdateDialog = () => {
        setOpenUpdate(false);
    };
    async function fetchDiagrams() {
        try {
            const resolvedToken = await auth.getToken();
            const token = resolvedToken; // Token'ınızı buraya ekleyin
            const fetchedDiagrams = await DiagramAPI.getAllDiagrams(token!);
            setDiagrams(fetchedDiagrams);
        } catch (error) {
            console.error('Error fetching diagrams:', error);
        }
    }
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUpdateDiagramData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    async function fetchAndSetDiagrams() {
        try {
            const resolvedToken = await auth.getToken();
            const token = resolvedToken;
            const fetchedDiagrams = await DiagramAPI.getAllDiagrams(token!);
            setDiagrams(fetchedDiagrams);
            setFilteredDiagrams(fetchedDiagrams); // Set filteredDiagrams to include all diagrams
        } catch (error) {
            console.error('Error fetching diagrams:', error);
        }
    }

    async function fetchDiagramAuthor(id: string) {
        try {
            const resolvedToken = await auth.getToken();
            const token = resolvedToken;
            const fetchedAuthor = await AccountAPI.getUser(token!, id);
            return fetchedAuthor; // Return the fetched author object
        } catch (error) {
            console.error('Error fetching diagrams:', error);
            throw error; // Re-throw the error to be handled outside
        }
    }
    const fetchAuthors = async () => {
        authorsArray.length = 0; // Clear the array before populating it again

        // Fetch and store authors for all diagrams
        for (const diagram of diagrams) {
            try {
                const fetchedAuthorJson = await fetchDiagramAuthor(diagram.author.toString());
                const fetchedAuthor = Convert.toWelcome(JSON.stringify(fetchedAuthorJson));
                const authorEmail = fetchedAuthor.users[0].email;

                const authorObject = {
                    id: diagram.id,
                    email: authorEmail
                };
                authorsArray.push(authorObject);
            } catch (error) {
                console.error('Error fetching diagram author:', error);
            }
        }

        setAuthors(authorsArray); // Set the authors state after processing all diagrams
    };

    useEffect(() => {
        fetchAndSetDiagrams();
    }, []);

    useEffect(() => {
        fetchAuthors().then(() => {
            setFilteredDiagrams(diagrams);
            setLoading(false);
        });

        console.log(authorsArray);

    }, [diagrams]);

    const handleTypeChange = (event: SelectChangeEvent) => {
        setType(event.target.value as string);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const closeApproveDialog = () => {
        setOpenApprove(false);
    };
    const closeRejectDialog = () => {
        setOpenReject(false);
    };
    const closeRemoveDialog = () => {
        setOpenRemove(false);
    };
    const handleHeaderChange = (event: { target: { value: any; }; }) => {
        setHeader(event.target.value);
    };
    const handleSubjectChange = (event: { target: { value: any; }; }) => {
        setSubject(event.target.value);
    };
    const handleScenarioChange = (event: { target: { value: any; }; }) => {
        setScenario(event.target.value);
    };
    const handleRulesChange = (event: { target: { value: any; }; }) => {
        setRules(event.target.value);
    };
    const handleTargetGrammerRuleChange = (event: { target: { value: any; }; }) => {
        setTargetGrammerRule(event.target.value);
    };
    const handleAdditionalGrammerRuleChange = (event: { target: { value: any; }; }) => {
        setUsableGrammerRule(event.target.value);
    };
    const handleAdditionInfoChange = (event: { target: { value: any; }; }) => {
        setAdditionalInfo(event.target.value);
    };
    function successAlert() {
        SetIsSuccess(true)
        setTimeout(() => {
            SetIsSuccess(false);
        }, 3000);

    }
    const filter = (e: any) => {
        const keyword = e.target.value;
        const filteredResults = filteredDiagrams.filter((diagram) => {
            return diagram.topic.toLowerCase().startsWith(keyword.toLowerCase())
                || diagram.title.toLowerCase().startsWith(keyword.toLowerCase())
                || diagram.id.toString().toLowerCase().startsWith(keyword.toLowerCase());
        });
        setFilteredDiagrams(keyword !== '' ? filteredResults : diagrams);
        setName(keyword);
    };

    const handleCreateDiagram = async () => {
        try {
            const resolvedToken = await auth.getToken();
            const token = resolvedToken;
            const newDiagramData = {
                title: header,
                topic: subject,
                scenario: scenario,
                rules: rules,
                targetGrammarRules: targetGrammerRule,
                usableGrammarRules: usableGrammerRule,
                type: parseInt(type),
                extra: additionalInfo,
            };

            const createdDiagram = await DiagramAPI.addDiagram(token!, newDiagramData);
            if (createdDiagram !== null) { // null kontrolü burada yapılıyor
                // Diyagram başarıyla oluşturulduğunda yapılacak işlemler
                successAlert();
                fetchDiagrams(); // Diyagram listesini güncellemek için
                handleClose(); // Dialogu kapatmak için
            } else {
                console.error('Failed to create diagram.');
            }
        } catch (error) {
            console.error('Error creating diagram:', error);
        }
    };
    const handleUpdateDiagram = async () => {
        try {
            const resolvedToken = await auth.getToken();
            const token = resolvedToken;

            const updatedDiagram = await DiagramAPI.updateDiagram(token!, updateDiagramData);
            if (updatedDiagram !== null) {
                // Diagram was updated successfully
                successAlert();
                fetchDiagrams();
                closeUpdateDialog();

            } else {
                console.error('Failed to update diagram.');
            }
        } catch (error) {
            console.error('Error updating diagram:', error);
        }
    };


    function getDiagramType(type: number) {
        switch (type) {
            case 0:
                return "Written";
            case 1:
                return "Visual";
            case 2:
                return "Vocal";
            default:
                return "UNKNOWN";
        }
    }
    function getDiagramStatus(status: number) {
        if (status === 0) {
            return (
                <span style={{ color: '#1976d2' }}>
                    <strong>IN PROGRESS</strong>
                </span>
            );
        } else if (status === 1) {
            return (
                <span style={{ color: 'orange' }}>
                    <strong>PENDING</strong>
                </span>
            );
        } else if (status === 2) {
            return (
                <span style={{ color: '#00e676' }}>
                    <strong>APPROVED</strong>
                </span>
            );
        } else if (status === 3) {
            return (
                <span style={{ color: 'red' }}>
                    <strong>REJECTED</strong>
                </span>
            );
        } else {
            return null;
        }
    }
    return (
        <>
            <Navbar toggleNavbarDrawer={handleToggleDrawer} data={"home"} />

            <Container maxWidth='xl'>

                <Card elevation={4} sx={{ p: 2 }}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={2}>
                            <TextField size="small" variant="outlined" type="search" value={name} onChange={filter} className="input" placeholder="Filter"
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Button
                                size="small"
                                variant="contained"


                                onClick={() => {
                                    setFilteredDiagrams(diagrams.filter(diagram => diagram.status === 0));
                                }}

                            >
                                <ImStackoverflow size={20} />
                            </Button>
                            <Button
                                size="small"
                                color="warning"
                                sx={{ ml: 0.5 }}
                                variant="contained"

                                onClick={() => {
                                    setFilteredDiagrams(diagrams.filter(diagram => diagram.status === 1));
                                }}
                            >
                                <AiOutlineFileDone size={20} color='white' />

                            </Button>
                            <Button
                                size="small"
                                color="success"

                                sx={{ ml: 0.5 }}
                                variant="contained"


                                onClick={() => {
                                    setFilteredDiagrams(diagrams.filter(diagram => diagram.status === 2));
                                }}

                            >
                                <AiFillCheckCircle size={20} />
                            </Button>

                            <Button
                                size="small"
                                color="error"

                                sx={{ ml: 0.5 }}
                                variant="contained"

                                onClick={() => {
                                    setFilteredDiagrams(diagrams.filter(diagram => diagram.status === 3));
                                }}
                            >
                                <AiFillCloseCircle size={20} />
                            </Button>
                            <Button
                                size="small"
                                color="primary"
                                sx={{ ml: 0.5 }}
                                variant="contained"


                                onClick={() => {
                                    setFilteredDiagrams(diagrams);
                                }}
                            >
                                <VscDebugRestart size={20} />
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={7} container justifyContent="flex-end">
                            <Button variant="outlined" onClick={handleClickOpen} startIcon={<GoDiffAdded />}>
                                Create New
                            </Button>
                        </Grid>


                    </Grid>

                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: 700 }}>
                        <Box className="user-list" sx={{ mt: 1 }}>
                            {loading ? (
                                <CircularProgress sx={{ mt: 5 }} /> // Show CircularProgress while loading
                            ) : (
                                filteredDiagrams && filteredDiagrams.length > 0 ? (
                                    <Grid container spacing={1}>
                                        {filteredDiagrams.map((diagram) => (
                                            <Grid item xs={12} sm={6} md={4} lg={3} key={diagram.id}>
                                                <Card className='customCard' elevation={4} sx={{ maxWidth: 345 }}>
                                                    <CardContent>
                                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                                            <Typography variant="h6" component="div">
                                                                <strong>{diagram.title}</strong>
                                                            </Typography>
                                                            <Chip label={getDiagramType(diagram.type)} variant="outlined" />
                                                        </Box>
                                                        <Typography variant="subtitle2" component="div"><p>{authors.find(author => author.id === diagram.id)?.email}</p></Typography>
                                                        <Divider sx={{ my: 2 }} />
                                                        <Typography noWrap variant="subtitle1" component="div">
                                                            <strong>{diagram.topic}</strong>
                                                        </Typography>
                                                        <Typography noWrap variant="body2" color="text.secondary">
                                                            <strong>Scenario:</strong> {diagram.scenario}
                                                        </Typography>
                                                        <Typography noWrap variant="body2" color="text.secondary">
                                                            <strong>Rules:</strong> {diagram.rules}
                                                        </Typography>
                                                    </CardContent>
                                                    <Divider />
                                                    <CardActions>
                                                        <Typography noWrap color='#00e676' variant="subtitle2" component="div">
                                                            {getDiagramStatus(diagram.status)}
                                                        </Typography>
                                                        <Box sx={{ flexGrow: 1 }} />
                                                        <Tooltip componentsProps={{
                                                            tooltip: {
                                                                sx: {
                                                                    bgcolor: 'common.white',
                                                                    border: '1px solid #ccc', // Add border style here
                                                                    '& .MuiTooltip-arrow': {
                                                                        color: 'common.white',
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                            title={
                                                                <Box>
                                                                    {diagram.status !== 2 && diagram.status !== 0 && (
                                                                        <IconButton color="success" onClick={() => {
                                                                            toggleDiagramTooltip(diagram.id)
                                                                            closeAllTooltips();
                                                                            handleApproveDialog(diagram.id);
                                                                        }}>
                                                                            <AiOutlineCheckCircle color='green' size={22} />
                                                                        </IconButton>
                                                                    )}
                                                                    {diagram.status !== 3 && diagram.status !== 0 && (
                                                                        <IconButton color="error" onClick={() => {
                                                                            toggleDiagramTooltip(diagram.id);
                                                                            handleRejectDialog(diagram.id);
                                                                        }}>
                                                                            <IoIosCloseCircleOutline color='error' size={22} />
                                                                        </IconButton>
                                                                    )}
                                                                    {diagram.status !== 2 && (
                                                                        <IconButton color="warning">
                                                                            <Link to={`/addNode/${diagram.id}`} className="course-link">
                                                                                <GoGitMerge color='warning' size={22} />
                                                                            </Link>

                                                                        </IconButton>
                                                                    )}
                                                                    <IconButton onClick={() => { }}>
                                                                        <BsEye color='#1976d2' size={22} />
                                                                    </IconButton>

                                                                </Box>
                                                            }
                                                            onClose={() => toggleDiagramTooltip(diagram.id)}
                                                            open={diagramTooltipOpenMap[diagram.id] || false}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener

                                                        >
                                                            <Button variant="outlined" onClick={() => toggleDiagramTooltip(diagram.id)}>
                                                                <BsDiagram2 color='#1976d2' size={22} />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip componentsProps={{
                                                            tooltip: {
                                                                sx: {
                                                                    bgcolor: 'common.white',
                                                                    border: '1px solid #ccc', // Add border style here
                                                                    '& .MuiTooltip-arrow': {
                                                                        color: 'common.white',
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                            title={
                                                                <Box>

                                                                    <IconButton aria-label="edit">
                                                                        <MdEdit color='orange' onClick={() => {
                                                                            toggleSettingsTooltip(diagram.id);
                                                                            openUpdateDialog(diagram);
                                                                            setOpenUpdate(true);
                                                                        }} />
                                                                    </IconButton>

                                                                    <IconButton aria-label="delete" onClick={() => {
                                                                        toggleSettingsTooltip(diagram.id);
                                                                        handleDeleteDiagram(diagram.id)
                                                                    }}>
                                                                        <HiTrash color='red' />
                                                                    </IconButton>

                                                                </Box>
                                                            }
                                                            onClose={() => toggleSettingsTooltip(diagram.id)}
                                                            open={settingsTooltipOpenMap[diagram.id] || false}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener
                                                        >
                                                            <Button variant="outlined" color='warning' onClick={() => toggleSettingsTooltip(diagram.id)}>
                                                                <TfiSettings color='orange' size={22} />
                                                            </Button>
                                                        </Tooltip>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
                                        <Typography variant="h6">No results found!</Typography>
                                    </Box>
                                )
                            )}
                        </Box>
                        <Dialog open={openApprove} onClose={closeApproveDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Approvement Process"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to approve this diagram?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" color="error" onClick={closeApproveDialog}>Cancel</Button>
                                <Button variant="contained" color="success" onClick={() => {
                                    setOpenApprove(false)
                                }} autoFocus>
                                    Approve
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openRemove} onClose={closeRemoveDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Remove Process"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to remove this diagram?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" color="info" onClick={closeRemoveDialog}>Cancel</Button>
                                <Button variant="contained" color="error" onClick={() => {
                                    setOpenRemove(false)
                                }} autoFocus>
                                    Remove
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={open} maxWidth="lg" onClose={handleClose}>
                            <DialogTitle>Create New Diagram</DialogTitle>
                            <DialogContent>
                                <DialogContentText>

                                </DialogContentText>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="stretch"
                                >
                                    <Grid item xs={12}>
                                        <TextField autoFocus margin="dense" id="name" label="Diagram Title" type="email" fullWidth variant="outlined" onChange={handleHeaderChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField autoFocus margin="dense" id="name" label="Topic" type="email" fullWidth variant="outlined" onChange={handleSubjectChange}
                                        />
                                    </Grid>
                                    <Grid sx={{ mt: 1 }} item xs={12}>

                                        <TextField fullWidth placeholder="Scenario" multiline rows={2} maxRows={4} onChange={handleScenarioChange}
                                        />
                                    </Grid>
                                    <Grid sx={{ mt: 1 }} item xs={12}>
                                        <TextField autoFocus margin="dense" id="name" label="Target Grammer Rule" type="email" fullWidth variant="outlined" onChange={handleTargetGrammerRuleChange}
                                        />
                                    </Grid>

                                    <Grid sx={{ mt: 1 }} item xs={6}>
                                        <TextField fullWidth placeholder="Usable Grammer Rules" multiline rows={2} maxRows={4} onChange={handleAdditionalGrammerRuleChange}
                                        />
                                    </Grid>
                                    <Grid sx={{ mt: 1, pl: 1 }} item xs={6}>
                                        <TextField fullWidth placeholder="Other Rules" multiline rows={2} maxRows={4} onChange={handleRulesChange}
                                        />
                                    </Grid>
                                    <Grid sx={{ mt: 1 }} item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={type} label="Type" onChange={handleTypeChange}
                                            >
                                                <MenuItem value={0}>Written</MenuItem>
                                                <MenuItem value={1}>Visual</MenuItem>
                                                <MenuItem value={2}>Vocal</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid sx={{ mt: 1 }} item xs={12}>
                                        <TextField fullWidth placeholder="Additional Info" multiline rows={2} maxRows={4} onChange={handleAdditionInfoChange}
                                        />
                                    </Grid></Grid>



                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleCreateDiagram} >Create Diagram</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openUpdate} onClose={closeUpdateDialog}>
                            <DialogTitle>Update Diagram</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Edit the information for the diagram.
                                </DialogContentText>
                                <TextField margin="dense" label="Title" fullWidth name="title" value={updateDiagramData.title} onChange={handleInputChange}
                                />
                                <TextField margin="dense" label="Topic" fullWidth name="topic" value={updateDiagramData.topic} onChange={handleInputChange}
                                />
                                <TextField margin="dense" label="Scenario" fullWidth multiline rows={4} name="scenario" value={updateDiagramData.scenario} onChange={handleInputChange}
                                />
                                <TextField margin="dense" label="Target Grammar Rules" fullWidth name="targetGrammarRules" value={updateDiagramData.targetGrammarRules} onChange={handleInputChange}
                                />
                                <TextField margin="dense" label="Usable Grammar Rules" fullWidth name="usableGrammarRules" value={updateDiagramData.usableGrammarRules} onChange={handleInputChange}
                                />
                                <TextField margin="dense" label="Other Rules" fullWidth name="rules" value={updateDiagramData.rules} onChange={handleInputChange}
                                />
                                <FormControl fullWidth>
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                        value={type}
                                        onChange={handleTypeChange}
                                        label="Type"
                                    >
                                        <MenuItem value={1}>Written</MenuItem>
                                        <MenuItem value={2}>Visual</MenuItem>
                                        <MenuItem value={3}>Vocal</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField margin="dense" label="Additional Info" fullWidth multiline rows={4} name="extra" value={updateDiagramData.extra} onChange={handleInputChange} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeUpdateDialog} color="primary">Cancel</Button>
                                <Button onClick={handleUpdateDiagram} color="primary">Update</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openApprove}
                            onClose={closeApproveDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Approvement Process"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to approve this diagram?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>

                                <Button variant="contained" color="success" onClick={handleApprove} autoFocus>
                                    Approve
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openReject}
                            onClose={closeRejectDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Approvement Process"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to approve this diagram?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="error" onClick={handleReject} autoFocus>
                                    Reject
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={deleteDialogOpen}
                            onClose={() => setDeleteDialogOpen(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to delete this diagram?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        setDeleteDialogOpen(false);
                                        handleDeleteConfirmed(selectedDiagramToDelete);
                                    }}
                                    variant="contained" color="error"
                                    autoFocus
                                >
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Scrollbars>
                </Card>
            </Container >

        </>
    );
}