import { VideoNode } from "./NodeModels";
import { Diagram } from "./DiagramModels";
import axios, { AxiosError } from "axios";

export class ProgressAPI {
    static baseUrl = process.env.REACT_APP_BACKEND_URL;

    static async updateProgress(diagramId: number, nodeId: number, token: string): Promise<any> {
        try {
            const data = {
                diagramId: diagramId,
                nodeId: nodeId
            };

            const config = {
                headers: {
                    token: token
                }
            };

            const res = await axios.post(this.baseUrl + '/progress/update', data, config);
            return res.data;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                // Handle error cases if needed
            }
            throw e;
        }
    }

    static async getProgress(diagramIds: number[], token: string): Promise<any> {
        try {
            const data = {
                diagramIds: diagramIds
            };

            const config = {
                headers: {
                    token: token
                }
            };

            const res = await axios.post(this.baseUrl + '/progress/getProgress', data, config);
            return res.data;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                // Handle error cases if needed
            }
            throw e;
        }
    }

    static async resetProgress(token: string): Promise<any> {
        try {
            const config = {
                headers: {
                    token: token,
                },
            };
            const res = await axios.delete(this.baseUrl + '/progress/reset', config);
            return res.data;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                // Handle error cases if needed
            }
            throw e;
        }
    }

}
