import { forwardRef, Ref, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/vimeo";
import { VideoNode } from "../../api/NodeModels";

interface VideoData {
  code: string,
  url: string,
  play: boolean
}

interface Props {
  activeNode: VideoNode,
  nextNodes: Array<VideoNode>,
  onVideoEnd: () => void
}

export const VideoLoader = forwardRef<ReactPlayer, Props>(({
  activeNode,
  nextNodes,
  onVideoEnd
}, ref) => {
  const [videoData, setVideoData] = useState<Array<VideoData>>([])
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    console.log({ nextNodes })
    const tempVideoData: VideoData[] = []

    nextNodes.forEach((value) => {
      tempVideoData.push({ code: value.code, url: value.videoUrl, play: false });
    });
    tempVideoData.push({ code: activeNode.code, play: true, url: activeNode.videoUrl });
    setVideoData(tempVideoData);
  }, [])

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      return;
    }
    console.log("main loader effect start " + activeNode.code)

    console.log({ nextNodes });

    // clean video data & start next vid
    const filtered = videoData.filter((value) => value.code === activeNode.code).map((value) => {
      value.play = true;
      return value;
    });

    // load new video data
    const tempVideoData: VideoData[] = []

    nextNodes.forEach((value) => {
      tempVideoData.push({ code: value.code, url: value.videoUrl, play: false });
    });

    console.log({ tempVideoData });
    console.log({ filtered });

    console.log("main loader effect end " + activeNode.code)

    setVideoData(tempVideoData.concat(filtered))

  }, [nextNodes]);

  return (
    <>
      {videoData.map((data, index) => {
        const isActive = data.code === activeNode.code;
        return (
          <ReactPlayer
            ref={isActive ? ref : undefined}
            key={data.code}
            url={data.url}
            style={{ position: 'absolute', top: '0', left: '0' }}
            playing={data.play}
            volume={isActive ? 1 : 0}
            onEnded={isActive ? () => {
              console.log("videoend");
              onVideoEnd();
              // pause the active video
              setVideoData((x) => {
                const temp = [...x];
                temp[index].play = false;
                return temp;
              });
            } : undefined}
            onSeek={(seconds) => setVideoData((x) => {
              // play the active video
              const temp = [...x];
              temp[index].play = true;
              console.log({ "onseek temp": temp })
              return temp;
            })}
            controls={true}
            onPlay={isActive ? undefined : () => {
              setVideoData((x) => {
                const temp = [...x];
                temp[index].play = false;
                return temp;
              });
            }}
            width="100%"
            height="100%"
          />
        )
      })}
    </>
  );
});