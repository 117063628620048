import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import ReportIcon from '@mui/icons-material/Report';
import {Alert, Box, Button, Chip} from "@mui/material";
import {useEffect} from "react";
import {useAuth} from "../hooks/useAuth";
import { getTranslation, useLanguage } from '../LanguageProvider';

export default function VerifyChip() {
    const auth = useAuth();
    const [isVerified, setIsVerified] = React.useState(0);
    const { language } = useLanguage();
    const translation = getTranslation(language);
    useEffect(() => {
        if (auth.user?.permission != 0) {
            setIsVerified(auth.user!.permission)
        }
    }, [auth.user]);
    return (
        <div>
            {isVerified === 0 &&
                <Box>
                    <Chip  sx={{borderRadius: 1,width:"120%"}} label={translation.unverifieduser} color="error"/>
                </Box>
            }
            {isVerified === 10 &&
                <Box>
                    <Chip sx={{borderRadius: 1}} label={translation.user}  color="info"/>
                </Box>
            }
            {isVerified === 20 &&
                <Box>
                    <Chip sx={{borderRadius: 1}} label={translation.tutor}  color="info"/>
                </Box>
            }
            {isVerified === 30 &&
                <Box>
                    <Chip sx={{borderRadius: 1}} label={translation.mod}  color="info"/>
                </Box>
            }
            {isVerified === 40 &&
                <Box>
                    <Chip sx={{borderRadius: 1,width:150}} label={translation.admin}  color="info"/>
                </Box>
            }
        </div>
    );
}
