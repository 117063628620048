import React, { useState } from 'react'
import Navbar from '../../components/Navbar'

const BecomeTeacher = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleDrawer = (value: boolean | ((prevState: boolean) => boolean)) => {
      setIsOpen(value);
    }
    return (
        <div>


            <div className="page-wrapper">


            <Navbar toggleNavbarDrawer={handleToggleDrawer} data={"home"} />
                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content">

                    </div>
                </div>



                <section className="page-header clearfix" style={{ backgroundImage: 'url(assets/images/backgrounds/page-header-bg1.jpg)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="page-header__wrapper clearfix">
                                    <div className="page-header__title">
                                        <h2>Become a Teacher</h2>
                                    </div>
                                    <div className="page-header__menu">

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="teaching-one">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-6 col-lg-5">
                                <div className="teaching-one__apply-form">
                                    <div className="title-box">
                                        <h4>Apply for Teaching</h4>
                                    </div>
                                    <div className="form-box">
                                        <form action="mailto:dilagaci2021@gmail.com?subject=Become a Teacher" method="post" encType="text/plain">
                                            <div className="form-group">
                                                <input type="text" name="name" placeholder="Your Name" required />
                                            </div>
                                            <div className="form-group">
                                                <input type="email" name="mail" placeholder="Email Address" required />
                                            </div>
                                            <div className="form-group">
                                                <textarea name="comment" placeholder="Comment" rows={4} cols={50}></textarea>
                                            </div>
                                            <button className="registration-one__right-form-btn" type="submit" value="Send">
                                                <span className="thm-btn">apply for it</span>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-7">
                                <div className="teaching-one__content">
                                    <h2 className="teaching-one__content-title">Teaching Benefits</h2>
                                    <p className="teaching-one__content-text1">Lorem Ipsum is simply dummy text of the printing and type industry. Lorem Ipsum has been the standard dummy text ever since the when an unknown was popularised.  It has survived not only five centuries, but also the leap into electronic.</p>
                                    <div className="teaching-one__content-img">
                                        <img src="assets/images/resources/teaching-one-img2.jpg" alt="" />
                                    </div>
                                    <p className="teaching-one__content-text2">Lorem Ipsum has been the standard dummy text ever since the when an unknown was popularised.  It has survived not only five centuries but also the leap into electronic.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                <section className="registration-two" style={{ marginTop: '20px' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="registration-two__wrapper">
                                    <div className="shape1 zoom-fade"><img src="assets/images/shapes/thm-shape2.png" alt="" /></div>
                                    <div className="shape2 wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms"><img src="assets/images/shapes/thm-shape3.png" alt="" /></div>
                                    <div className="registration-two__left">
                                        <h2 className="registration-two__left-text">Start Your Education Career <br />with Zilom</h2>
                                    </div>
                                    <div className="registration-two__right">
                                        <div className="registration-two__right-btn">
                                            <a href="#" className="thm-btn">Discover more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>




               
          <footer className="footer-one">
            <div className="footer-one__bg">
            </div>
            <div className="footer-one__top">
              <div className="container">
                <div className="row">

                  <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.1s">
                    <div className="footer-widget__column footer-widget__about">
                      <div className="footer-widget__about-logo">
                        <a href="index.html"><img src="assets/images/resources/langtreevector.png" alt=""
                          className="responsive-image-footer" /></a>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.3s">
                    <div className="footer-widget__column footer-widget__courses">
                      <h3 className="footer-widget__title">Courses</h3>
                      <ul className="footer-widget__courses-list list-unstyled">
                        <li><a href="#">UI/UX Design</a></li>
                        <li><a href="#">WordPress Development</a></li>
                        <li><a href="#">Business Strategy</a></li>
                        <li><a href="#">Software Development</a></li>
                        <li><a href="#">Business English</a></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.5s">
                    <div className="footer-widget__column footer-widget__links">
                      <h3 className="footer-widget__title">Links</h3>
                      <ul className="footer-widget__links-list list-unstyled">
                        <li><a href="about.html">About Us</a></li>
                        <li><a href="#">Overview</a></li>
                        <li><a href="teachers-1.html">Teachers</a></li>
                        <li><a href="#">Join Us</a></li>
                        <li><a href="news.html">Our News</a></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.7s">
                    <div className="footer-widget__column footer-widget__contact">
                      <h3 className="footer-widget__title">Contact</h3>
                      <p className="text">Kastamonu University, Türkiye</p>
                      <p><a href="mailto:dilagaci2021@gmail.com">dilagaci2021@gmail.com</a></p>
                      <p className="phone"><a href="tel:+90 366 280 14 97">+90 366 280 14 97</a></p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.9s">
                    <div className="footer-widget__column footer-widget__social-links">
                      <ul className="footer-widget__social-links-list list-unstyled clearfix">
                        <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fab fa-brands fa-linkedin"></i></a></li>
                      </ul>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </footer>









            </div>


            <div className="mobile-nav__wrapper">
                <div className="mobile-nav__overlay mobile-nav__toggler"></div>

                <div className="mobile-nav__content">
                    <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times"></i></span>

                    <div className="logo-box">
                        <a href="index.html" aria-label="logo image"><img src="assets/images/resources/mobilemenu_logo.png" width="155" alt="" /></a>
                    </div>

                    <div className="mobile-nav__container"></div>


                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="icon-phone-call"></i>
                            <a href="mailto:needhelp@packageName__.com">needhelp@zilom.com</a>
                        </li>
                        <li>
                            <i className="icon-letter"></i>
                            <a href="tel:666-888-0000">666 888 0000</a>
                        </li>
                    </ul>
                    <div className="mobile-nav__top">
                        <div className="mobile-nav__social">
                            <a href="#" className="fab fa-twitter"></a>
                            <a href="#" className="fab fa-facebook-square"></a>
                            <a href="#" className="fab fa-pinterest-p"></a>
                            <a href="#" className="fab fa-instagram"></a>
                        </div>
                    </div>
                </div>
            </div>



            <div className="search-popup">
                <div className="search-popup__overlay search-toggler"></div>
                <div className="search-popup__content">
                    <form action="#">
                        <label htmlFor="search" className="sr-only">search here</label>
                        <input type="text" id="search" placeholder="Search Here..." />
                        <button type="submit" aria-label="search submit" className="thm-btn2">
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </form>
                </div>
            </div>



            <a href="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></a>





        </div>

    )
}

export default BecomeTeacher