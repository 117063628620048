import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Icon, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { HiTrash } from 'react-icons/hi';
import Preloader from './Preloader';
import { DiagramListAPI } from '../api/DiagramListAPI';
import { ProgressAPI } from '../api/ProgressAPI';
import { useAuth } from '../hooks/useAuth';
import { useLanguage, getTranslation } from '../LanguageProvider';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BsStack } from 'react-icons/bs';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { RiClapperboardFill } from 'react-icons/ri';
import { BsFillPlusCircleFill } from 'react-icons/bs';
interface CourseDataItem {
    id: number;
    title: string;
    description: string;
    level: string;
    container: string[];
}

const CourseInfo: React.FC = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const { language } = useLanguage();
    const translation = getTranslation(language);

    const [progressList, setProgressList] = useState<{ courseId: string; progress: number; }[]>([]);
    const [newCourse, setNewCourse] = useState({
        title: '',
        description: '',
        level: '',
        container: [],
    });
    const [selectedFilter, setSelectedFilter] = useState<number>(0);
    const [courseData, setCourseData] = useState<CourseDataItem[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredCourseData, setFilteredCourseData] = useState<CourseDataItem[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    type Anchor = 'bottom';
    const openDrawer = () => {
        setIsOpen(true);
    };

    // Açılır menüyü kapatma fonksiyonu
    const closeDrawer = () => {
        setIsOpen(false);
    };
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >

            <ListItem onClick={() => setFilteredCourseData(courseData)} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <BsStack color='#60c6c1' />
                    </ListItemIcon>
                    <ListItemText primary={translation.allCourses} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem onClick={() => handleFilterClick('completed')} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <BsFillCheckCircleFill color='#60c6c1' />
                    </ListItemIcon>
                    <ListItemText primary={translation.completed} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem onClick={() => handleFilterClick('notStarted')} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <RiClapperboardFill color='#60c6c1' />
                    </ListItemIcon>
                    <ListItemText primary={translation.notStarted} />
                </ListItemButton>
            </ListItem>
            <ListItem onClick={openDialogBox} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <BsFillPlusCircleFill color='#60c6c1' />
                    </ListItemIcon>
                    <ListItemText primary={translation.addCourse} />
                </ListItemButton>
            </ListItem>



        </Box>
    );

    const fetchCourseData = async () => {
        try {
            const token = await auth.getToken();
            const fetchedCourseData = await DiagramListAPI.getAllTheme(token!);
            setCourseData(fetchedCourseData);
            setFilteredCourseData(fetchedCourseData);

            const courseIdToContainerMap: { [courseId: number]: string[] } = {};
            fetchedCourseData.forEach((course) => {
                courseIdToContainerMap[course.id] = course.container;
            });

            const progressData = await fetchProgressForCourses(courseIdToContainerMap);
            const updatedProgressList = Object.keys(progressData).map((courseId) => {
                const progressValues = Object.values(progressData[courseId]);
                const isCompleted = progressValues.length > 0 ? progressValues.every((value) => value === 2) : false;
                return {
                    courseId: courseId,
                    progress: isCompleted ? 1 : 0,
                };
            });
            setProgressList(updatedProgressList);
        } catch (error) {
            console.error('Hata:', error);
        }
    };

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        const filteredCourses = courseData.filter(course =>
            course.title.toLowerCase().includes(query) ||
            course.description.toLowerCase().includes(query)
        );

        setFilteredCourseData(filteredCourses);
    };

    const handleFilterClick = (filterType: 'completed' | 'notStarted') => {
        const filteredCourses = courseData.filter(course => {
            const progress = getProgressById(course.id.toString());
            if (filterType === 'completed') {
                return progress === 1;
            } else {
                return progress === 0;
            }
        });
        setFilteredCourseData(filteredCourses);
    };

    useEffect(() => {
        fetchCourseData();
    }, []);

    const fetchProgressForCourses = async (courseIdToContainerMap: any) => {
        const token = await auth.getToken();
        const courseIds = Object.keys(courseIdToContainerMap);
        const progressData: { [key: string]: number } = {};

        for (let i = 0; i < courseIds.length; i++) {
            const courseId = courseIds[i];
            const container = courseIdToContainerMap[courseId];

            if (courseId) {
                try {
                    const result = await ProgressAPI.getProgress(container.map(Number), token!);
                    progressData[courseId] = result;
                } catch (error) {
                    console.error(`Kurs ${courseId} için ilerleme alınırken hata oluştu:`, error);
                }
            }
        }

        return progressData;
    };

    const getProgressById = (courseId: string) => {
        const courseProgress = progressList.find((item) => item.courseId === courseId);
        return courseProgress ? courseProgress.progress : 0;
    };

    const refreshCourseData = async () => {
        try {
            const token = 'yourAuthToken'; // Replace with your token
            const fetchedCourseData = await DiagramListAPI.getAllTheme(token);
            setCourseData(fetchedCourseData);
            setFilteredCourseData(fetchedCourseData);
        } catch (error) {
            console.error('Error fetching course data:', error);
        }
    };

    const deleteCourse = async (themeId: number) => {
        const resolvedToken = await auth.getToken();
        if (resolvedToken !== null) {
            const success = await DiagramListAPI.deleteTheme(themeId, resolvedToken);

            if (success) {
                refreshCourseData();
            } else {
                console.log('Failed to delete the theme.');
            }
        } else {
            console.log('Token is null, cannot make API request.');
        }
    };

    const addNewCourse = async () => {
        const resolvedToken = await auth.getToken();
        if (resolvedToken !== null) {
            const success = await DiagramListAPI.addTheme(newCourse.title, newCourse.description, newCourse.level, newCourse.container, resolvedToken);

            if (success) {
                setNewCourse({
                    title: '',
                    description: '',
                    level: '',
                    container: [],
                });
                refreshCourseData();
                setOpenDialog(false);
            } else {
                console.log('Failed to add the new course.');
            }
        } else {
            console.log('Token is null, cannot make API request.');
        }
    };

    const handleClose = () => {
        setNewCourse({
            title: '',
            description: '',
            level: '',
            container: [],
        });
        setOpenDialog(false);
    };

    const openDialogBox = () => {
        setOpenDialog(true);
    };

    return (
        <div className="page-wrapper" style={{ overflow: 'visible', paddingBottom: '24px' }}>
            <section className="courses-one courses-one--courses" style={{ height: '100%', padding: '20px' }}>
                <div className="container">
                    <Grid item xs={12} md={6} sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none' }, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="search"
                            label="Search"
                            type="text"

                            variant="outlined"
                            onChange={handleSearchInputChange}
                            value={searchQuery}
                            size='small'
                            style={{ marginBottom: '7px' }}
                            InputProps={{
                                style: { borderColor: '#60c6c1' },
                            }}
                        />
                        {(['bottom'] as const).map((anchor) => (
                            <React.Fragment key={anchor}>
                                <IconButton color='primary' onClick={toggleDrawer(anchor, true)}><GiHamburgerMenu color='#60c6c1' /></IconButton>
                                <SwipeableDrawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                    onOpen={toggleDrawer(anchor, true)}
                                >
                                    {list(anchor)}
                                </SwipeableDrawer>
                            </React.Fragment>
                        ))}
                    </Grid>
                    <div className="courses-one--courses__top">

                        <div className="mobile-hidden-section">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div className="courses-one--courses__menu-box">
                                        <ul className="project-filter clearfix post-filter has-dynamic-filters-counter list-unstyled">
                                            <button onClick={() => { setFilteredCourseData(courseData); setSelectedFilter(0) }} className={`${selectedFilter === 0 ? 'filter-btn-2' : 'filter-btn'
                                                } courses-one--courses__top-btn`} style={{ fontSize: '14px', padding: '4px 8px', margin: '5px' }}>
                                                {translation.allCourses}
                                            </button>
                                            <button onClick={() => { handleFilterClick('completed'); setSelectedFilter(1) }} className={`${selectedFilter === 1 ? 'filter-btn-2' : 'filter-btn'
                                                } courses-one--courses__top-btn`} style={{ fontSize: '14px', padding: '4px 8px', margin: '5px' }}>
                                                {translation.completed}
                                            </button>
                                            <button onClick={() => { handleFilterClick('notStarted'); setSelectedFilter(2) }} className={`${selectedFilter === 2 ? 'filter-btn-2' : 'filter-btn'
                                                } courses-one--courses__top-btn`} style={{ fontSize: '14px', padding: '4px 8px', margin: '5px' }}>
                                                {translation.notFinished}
                                            </button>
                                        </ul>
                                    </div>

                                    <div className="courses-one--courses__top-btn" style={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="search"
                                            label="Search"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            onChange={handleSearchInputChange}
                                            value={searchQuery}
                                            size='small'
                                            style={{ marginBottom: '7px' }}
                                            InputProps={{
                                                style: { borderColor: '#60c6c1' },
                                            }}
                                        />
                                        <button
                                            onClick={openDialogBox}
                                            className="thm-btn courses-one--courses__top-btn"
                                            style={{
                                                fontSize: '14px',
                                                padding: '5px 16px',
                                                maxHeight: '45px',
                                                margin: '5px' // Butonun yüksekliğini ayarlayabilirsiniz
                                            }}
                                        >
                                            {translation.addCourse}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row filter-layout masonary-layout">
                        {filteredCourseData.length === 0 ? (
                            <div className="no-courses-available">
                           <CircularProgress />
                            </div> // Display preloader while courseData is loading
                        ) : (
                            filteredCourseData.map((course) => (
                                <div key={course.id} className={`col-xl-3 col-lg-6 col-md-6 filter-item`}>
                                    <div className="courses-one__single wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1000ms">
                                        <div className="courses-one__single-img">
                                            <img src="assets/images/resources/l.png" alt="" />
                                        </div>
                                        <div className="courses-one__single-content">
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <h4 className="courses-one__single-content-title">
                                                    <Link to={`/courseDetails/${course.id}`} className="course-link">
                                                        {course.title}
                                                    </Link>
                                                </h4>
                                                <IconButton sx={{ marginTop: '-5px' }} aria-label="delete" onClick={() => deleteCourse(course.id)}>
                                                    <HiTrash color="red" />
                                                </IconButton>
                                            </Box>
                                            <p className="courses-one__single-content-price">{course.description}</p>
                                            <ul className="courses-one__single-content-courses-info list-unstyled" style={{ display: 'flex', justifyContent: 'center' }}>
                                                <li>
                                                    {course.container.length} {translation.lesson}
                                                </li>
                                                <li>{getProgressById(course.id.toString()) === 1 ? translation.completed : translation.notFinished}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </section>

            <Dialog fullWidth maxWidth='md' onClose={handleClose} open={openDialog}>
                <DialogTitle>Add New Course</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please provide the details for the new course you want to add.</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="Title"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newCourse.title}
                        onChange={(e) => setNewCourse({ ...newCourse, title: e.target.value })}
                    />
                    <TextField
                        autoFocus
                        multiline
                        maxRows={4}
                        margin="dense"
                        id="description"
                        label="Description"
                        type="text"
                        fullWidth
                        variant="outlined"
                        rows={3}
                        value={newCourse.description}
                        onChange={(e) => setNewCourse({ ...newCourse, description: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>


                    <button onClick={addNewCourse} className="thm-btn courses-one--courses__top-btn" style={{ fontSize: '14px', padding: '4px 8px', margin: '15px' }}>
                        {translation.addCourse}
                    </button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default CourseInfo;
