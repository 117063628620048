import axios, { AxiosError } from "axios";
import { AuthUser } from "../hooks/useAuth";
import { User } from "./AccountModels";

export class AccountAPI {

    static baseUrl = process.env.REACT_APP_BACKEND_URL;

    /**
     * başarılıysa **true** döner
     *
     * email zaten kullanılıyorsa **false** döner
     */
    static async register(email: string, password: string, captchaToken: string, birthday: Date, country: string, gender: string, education: string, nativeLanguage: string, otherLanguages: string[]): Promise<boolean> {
        try {
            await axios.post(this.baseUrl + '/account/register', {
                email: email,
                password: password,
                captcha: captchaToken,
                birthday: birthday,
                country: country,
                gender: gender,
                education: education,
                nativeLanguage: nativeLanguage,
                otherLanguages: otherLanguages
            })
            return true;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status == 400) {
                    return false;
                }
            }
            throw e;
        }
    }

    /**
     * Giriş başarılı ise tokeni döndürür (string)
     *
     * Email veya şifre hatalı ise **false** döndürür
     */
    static async login(email: string, password: string, captchaToken: string): Promise<boolean | string> {
        try {
            const res = await axios.post(this.baseUrl + '/account/login', {
                email: email,
                password: password,
                captcha: captchaToken
            })
            return res.data.token;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status == 401) {
                    return false;
                }
            }
            throw e;
        }
    }

    static async sendRecoveryEmail(email: string): Promise<boolean> {
        try {
            const response = await axios.post(this.baseUrl + '/account/recover/' + email);

            if (response.status === 200) {
                return true; // Success case
            } else {
                return false; // Failure case
            }
        } catch (error) {
            // Error case
            console.error('Error sending recovery email:', error);
            return false; // Failure case
        }
    }


    static async getDetails(token: string): Promise<AuthUser> {
        const config = { headers: { token: token } };
        const res = await axios.get(this.baseUrl + '/account/details/', config)
        return res.data;
    }

    /**
     * Başarılıysa **true** döner.
     *
     * Hesap zaten verified ise **false** döner.
     * @throws token hatalıysa
     */
    static async verify(code: string): Promise<boolean> {
        try {
            await axios.get(this.baseUrl + '/account/verify/' + code)
            return true
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status == 422) {
                    return false;
                }
            }
            throw e;
        }
    }

    static async deleteAccount(token: string): Promise<void> {
        const config = { headers: { token: token } };
        const res = await axios.delete(this.baseUrl + '/account/delete/', config)
        return res.data;
    }

    /**
     * Başarılıysa **true** döner.
     *
     * Hesap zaten verified ise **false** döner.
     */
    static async verifyResend(token: string): Promise<boolean> {
        try {
            const config = { headers: { token: token } };
            await axios.get(this.baseUrl + '/account/verify/resend', config)
            return true
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status == 422) {
                    return false;
                }
            }
            throw e;
        }
    }

    /**
     * Başarılıysa **true** döner.
     *
     * Code hatalıysa **false** döner.
     */
    static async recoverPassword(code: string, password: string): Promise<boolean> {
        try {
            await axios.post(this.baseUrl + '/account/recover', {
                code: code,
                newPassword: password
            });
            return true;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status == 400) {
                    return false;
                }
            }
            throw e;
        }
    }

    static async getAllUsers(token: string): Promise<Array<User>> {
        const config = { headers: { token: token } };
        const res = await axios.get(this.baseUrl + '/user/all/', config)
        return res.data;
    }
    static async getUser(token: string, value: string): Promise<Array<User>> {
        const config = { headers: { token: token } };
        const res = await axios.get(this.baseUrl + '/user/search/' + value, config)
        return res.data;
    }

    static async setPermission(id: number, permission: number, token: string): Promise<boolean> {
        try {
            const config = { headers: { token: token } };
            const res = await axios.post(this.baseUrl + '/user/permission', {
                userId: id,
                permission: permission
            }, config)
            return true;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status == 404) {
                    return false;
                }
            }
            throw e;
        }
    }

    static async setBan(id: number, banned: boolean, token: string): Promise<boolean> {
        try {
            const config = { headers: { token: token } };
            const res = await axios.post(this.baseUrl + '/user/ban', {
                userId: id,
                banned: banned
            }, config)
            return true;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status == 404) {
                    return false;
                }
            }
            throw e;
        }
    }

    static async setDelete(id: number, token: string): Promise<boolean> {
        try {
            const config = { headers: { token: token } };
            const res = await axios.delete(this.baseUrl + '/user/' + id, config)
            return true;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status == 404) {
                    return false;
                }
            }
            throw e;
        }
    }

    static async changePassword(oldPassword: string, newPassword: string, token: string): Promise<boolean> {
        try {
            const config = { headers: { token: token } };
            const res = await axios.post(this.baseUrl + '/user/password', {
                oldPassword: oldPassword,
                newPassword: newPassword
            }, config)
            return true;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status == 422) {
                    return false;
                }
            }
            throw e;
        }
    }

}