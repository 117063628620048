import React, { useState, useEffect } from 'react';
import ReactDragListView from 'react-drag-listview';
import { DiagramAPI } from '../api/DiagramAPI';
import { Diagram } from '../api/DiagramModels';
import { useAuth } from '../hooks/useAuth';
import { Chip, ListSubheader } from '@mui/material';
import { BsChatLeftTextFill } from 'react-icons/bs';
import { FaImage } from 'react-icons/fa';
import { RiUserVoiceFill } from 'react-icons/ri';
import { getTranslation, useLanguage } from '../LanguageProvider';

interface Item {
    title: string;
}

interface DraggableListProps {
    data: string[];
    onDragEnd: (newOrder: string[]) => void;
}

const DraggableList: React.FC<DraggableListProps> = (props) => {
    const [data, setData] = useState<Item[]>(props.data.map((item, index) => ({ title: item })));
    const [diagrams, setDiagrams] = useState<Diagram[]>([]);
    const auth = useAuth(); // Call the useAuth hook at the top level
    const { language } = useLanguage();
    const translation = getTranslation(language);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = await auth.getToken(); // Use the auth object to call getToken
                const diagrams = await DiagramAPI.getAllDiagrams(token!);
                setDiagrams(diagrams);
            } catch (error) {
                console.error('Error fetching diagrams', error);
            }
        };

        fetchData();
    }, [auth]);

    const handleDragEnd = (fromIndex: number, toIndex: number) => {
        const newData = [...data];
        const item = newData.splice(fromIndex, 1)[0];
        newData.splice(toIndex, 0, item);
        setData(newData);

        if (props.onDragEnd) {
            props.onDragEnd(newData.map((item) => item.title));
        }
    };

    const getDiagramTitle = (itemId: string): string => {
        const diagram = diagrams.find((d) => d.id === parseInt(itemId));
        return diagram ? diagram.title : '';
    };
    function getDiagramType(diagramId: number) {
        // Verilen diagramId'ye sahip diagram verisini bul
        const diagram = diagrams.find((d) => d.id === diagramId);

        if (diagram) {
            // Eğer diagram bulunursa, diagram'ın türünü döndür
            return diagram.type;
        } else {
            // Eğer diagram bulunamazsa, null döndür
            return null;
        }
    }

    const dragProps = {
        onDragEnd: handleDragEnd,
        nodeSelector: 'li',
        handleSelector: 'a',
    };
    const handleItemClick = (title: string) => {
        // Tıklanan öğeyi listeden kaldır
        const newData = data.filter((item) => item.title !== title);
        setData(newData);

        // Eğer props.onDragEnd işlevi tanımlıysa, sıralamayı güncelle
        if (props.onDragEnd) {
            props.onDragEnd(newData.map((item) => item.title));
        }
    };
    return (
        <div className="simple simple1">
            <ListSubheader sx={{ display: 'flex', justifyContent: 'center' }}>
                <strong>Selected Diagrams</strong>
            </ListSubheader>
            <div className="simple-inner">
                <ReactDragListView {...dragProps}>
                    <ol>
                        {data.map((item, index) => (
                           
                            <li key={index}>
                                <a href="#" className="course-details__curriculum-list-left-title">
                                    <span onClick={() => handleItemClick(item.title)}>{getDiagramTitle(item.title)}</span>
                                    <span style={{ float: 'right' }}>
                                        <a> <i className="fas fa-bars"></i></a>
                                    </span>
                                    <span style={{ float: 'right', marginRight: '8px' }}>
                                        <Chip sx={{ minWidth: '70px' }} size='small'
                                            label={
                                                getDiagramType(parseInt(item.title)) === 0
                                                    ? translation.written
                                                    : getDiagramType(parseInt(item.title)) === 1
                                                        ? translation.visual
                                                        : getDiagramType(parseInt(item.title)) === 2
                                                            ? translation.vocal
                                                            : translation.unknown
                                            }
                                            variant="outlined"
                                        />
                                    </span>
                                </a>
                            </li>
                        ))}
                    </ol>
                </ReactDragListView>
            </div>
        </div>
    );
};

export default DraggableList;
