import {Box, Grid, IconButton, Paper, TextField, Typography} from "@mui/material";
import React from "react";
import {VideoNode} from "../../api/NodeModels";
import DeleteIcon from "@mui/icons-material/Delete";

export function IncludeStartEnd({
                                    data,
                                    setter,
                                    indexes
                                }: { data: VideoNode[], setter: (newData: VideoNode[]) => void, indexes: number[] }) {

    return (
        <Paper elevation={2} sx={{
            borderBlock: 2,
            borderRadius: 5,
            backgroundColor: "white",
            px: 3,
            mt: 2,
            pb: 2

        }}>
            <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                <Grid item xs={1}>
                    <Typography fontSize={20}>
                        {data[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].type}:
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        value={data[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].value}
                        onChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                const node: VideoNode[] = [...data]
                                node[indexes[0]].jumps[indexes[1]].conditions[indexes[2]].value = event.currentTarget.value
                                setter(node)
                            }
                        }
                        fullWidth
                        margin="normal"
                        id="text"
                        label="Text"
                        name="text"
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton aria-label="delete" onClick={() => {
                        const tmp: VideoNode[] = [...data]
                        tmp[indexes[0]].jumps[indexes[1]].conditions.splice(indexes[2], 1)
                        setter(tmp)
                    }
                    }
                    >
                        <DeleteIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
    )
}