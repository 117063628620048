import React, { useState } from 'react'
import Navbar from '../../components/Navbar'

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleDrawer = (value: boolean | ((prevState: boolean) => boolean)) => {
      setIsOpen(value);
    }
    // Function to handle form submission
    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        // Encode subject and body for the email
        const encodedSubject = encodeURIComponent(subject);
        const encodedBody = encodeURIComponent(
            `Name: ${name}\nPhone: ${phone}\nMessage: ${message}`
        );

        // Create the mailto URL
        const mailtoUrl = `mailto:dilagaci2021@gmail.com?subject=${encodedSubject}&body=${encodedBody}`;

        // Open the user's default email client with the pre-filled email
        window.location.href = mailtoUrl;
    };
    return (
        <div>



            <div className="page-wrapper">

            <Navbar toggleNavbarDrawer={handleToggleDrawer} data={"home"} />


                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content">

                    </div>
                </div>



                <section className="page-header clearfix" style={{ backgroundImage: 'url(assets/images/backgrounds/page-header-bg1.jpg)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="page-header__wrapper clearfix">
                                    <div className="page-header__title">
                                        <h2>Contact</h2>
                                    </div>
                                    <div className="page-header__menu">
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="contact-details-one">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="contact-details-one__single">
                                    <div className="contact-details-one__single-icon">
                                        <span className="icon-chat"></span>
                                    </div>
                                    <div className="contact-details-one__single-text">
                                        <h4><a href="tel:123456789">+90 366 280 14 97</a></h4>
                                       
                                    </div>
                                </div>
                            </div>

                           

                            <div className="col-xl-6 col-lg-6">
                                <div className="contact-details-one__single">
                                    <div className="contact-details-one__single-icon">
                                        <span className="icon-address"></span>
                                    </div>
                                    <div className="contact-details-one__single-text">
                                        <h4>Kastamonu University, Türkiye</h4>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4">
                                <div className="contact-page__left">
                                    <div className="section-title">
                                        <span className="section-title__tagline">Send a Message</span>
                                        <h2 className="section-title__title">We Always <br />Ready to Hear <br />From You</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8">
                                <div className="contact-page__right">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="comment-form__input-box">
                                                    <input
                                                        type="text"
                                                        placeholder="Your name"
                                                        name="name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="comment-form__input-box">
                                                    <input
                                                        type="text"
                                                        placeholder="Phone number"
                                                        name="phone"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            
                                            <div className="col-xl-12 col-lg-12">
                                                <div className="comment-form__input-box">
                                                    <input
                                                        type="text"
                                                        placeholder="Subject"
                                                        name="subject"
                                                        value={subject}
                                                        onChange={(e) => setSubject(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12">
                                                <div className="comment-form__input-box">
                                                    <textarea
                                                        name="message"
                                                        placeholder="Write message"
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    ></textarea>
                                                </div>
                                              
                                            </div>
                                        </div>
                                          <button style={{border:'none'}} className="registration-one__right-form-btn" type="submit" value="Send">
                                                <span className="thm-btn">Send Message</span>
                                            </button>
                                    </form>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-page-google-map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.4451443242656!2d33.76646996529734!3d41.429611214995305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4084fa0a26570527%3A0xcd2dc99013330c9a!2sKastamonu%20%C3%9Cniversitesi!5e0!3m2!1str!2str!4v1694340816970!5m2!1str!2str"
                        className="contact-page-google-map__one" allowFullScreen></iframe>
                </section>




                <footer className="footer-one">
                    <div className="footer-one__bg">
                    </div>
                    <div className="footer-one__top">
                        <div className="container">
                            <div className="row">

                                <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.1s">
                                    <div className="footer-widget__column footer-widget__about">
                                        <div className="footer-widget__about-logo">
                                            <a href="index.html"><img src="assets/images/resources/langtreevector.png" alt=""
                                                className="responsive-image-footer" /></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.3s">
                                    <div className="footer-widget__column footer-widget__courses">
                                        <h3 className="footer-widget__title">Courses</h3>
                                        <ul className="footer-widget__courses-list list-unstyled">
                                            <li><a href="#">UI/UX Design</a></li>
                                            <li><a href="#">WordPress Development</a></li>
                                            <li><a href="#">Business Strategy</a></li>
                                            <li><a href="#">Software Development</a></li>
                                            <li><a href="#">Business English</a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.5s">
                                    <div className="footer-widget__column footer-widget__links">
                                        <h3 className="footer-widget__title">Links</h3>
                                        <ul className="footer-widget__links-list list-unstyled">
                                            <li><a href="about.html">About Us</a></li>
                                            <li><a href="#">Overview</a></li>
                                            <li><a href="teachers-1.html">Teachers</a></li>
                                            <li><a href="#">Join Us</a></li>
                                            <li><a href="news.html">Our News</a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.7s">
                                    <div className="footer-widget__column footer-widget__contact">
                                        <h3 className="footer-widget__title">Contact</h3>
                                        <p className="text">Kastamonu University, Türkiye</p>
                                        <p><a href="mailto:dilagaci2021@gmail.com">dilagaci2021@gmail.com</a></p>
                                        <p className="phone"><a href="tel:+90 366 280 14 97">+90 366 280 14 97</a></p>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.9s">
                                    <div className="footer-widget__column footer-widget__social-links">
                                        <ul className="footer-widget__social-links-list list-unstyled clearfix">
                                            <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                            <li><a href="#"><i className="fab fa-brands fa-linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </footer>












                <div className="mobile-nav__wrapper">
                    <div className="mobile-nav__overlay mobile-nav__toggler"></div>
                    <div className="mobile-nav__content">
                        <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times"></i></span>

                        <div className="logo-box">
                            <a href="index.html" aria-label="logo image"><img src="assets/images/resources/mobilemenu_logo.png" width="155" alt="" /></a>
                        </div>
                        <div className="mobile-nav__container"></div>

                        <ul className="mobile-nav__contact list-unstyled">
                            <li>
                                <i className="icon-phone-call"></i>
                                <a href="mailto:needhelp@packageName__.com">needhelp@zilom.com</a>
                            </li>
                            <li>
                                <i className="icon-letter"></i>
                                <a href="tel:666-888-0000">666 888 0000</a>
                            </li>
                        </ul>
                        <div className="mobile-nav__top">
                            <div className="mobile-nav__social">
                                <a href="#" className="fab fa-twitter"></a>
                                <a href="#" className="fab fa-facebook-square"></a>
                                <a href="#" className="fab fa-pinterest-p"></a>
                                <a href="#" className="fab fa-instagram"></a>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="search-popup">
                    <div className="search-popup__overlay search-toggler"></div>

                    <div className="search-popup__content">
                        <form action="#">
                            <label htmlFor="search" className="sr-only">search here</label>
                            <input type="text" id="search" placeholder="Search Here..." />
                            <button type="submit" aria-label="search submit" className="thm-btn2">
                                <i className="fa fa-search" aria-hidden="true"></i>
                            </button>
                        </form>
                    </div>

                </div>




                <a href="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></a>




            </div>
        </div>
    )
}

export default Contact