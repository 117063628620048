import React, {useEffect} from "react"
import {useState} from "react"
import {Avatar, Button, Chip, Grid, TextField} from "@mui/material";
import {Theme, useTheme} from "@mui/material/styles";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {languages} from "../../languagedata/languageArray";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


export default function RegisterLanguageList(props: any) {
    const theme = useTheme();
    const [personLanguage, setPersonLanguage] = React.useState<string[]>([]);
    const [numberOfHashtags, setNumberOfHashtags] = useState(0)
    const [arrayOfHashtags, addHashtag] = useState([])
    useEffect(() => {
        buttonClickHandler()
    }, [arrayOfHashtags])

    function buttonClickHandler() {
        props.passData(arrayOfHashtags);
    }


    const handleDelete = (h: never) => () => {
        addHashtag((arrayOfHashtags) =>
            arrayOfHashtags.filter((hashtag) => hashtag !== h)
        )
    }
    const Hashtags = arrayOfHashtags.map((h) => (
        <Chip
            sx={{mt: 1, width: "33%"}}
            size="medium"
            label={h}
            onDelete={handleDelete(h)}
            color="info"
        />
    ))

    const handleChange = (event: SelectChangeEvent<typeof personLanguage>) => {
        const {
            target: {value},
        } = event;
        setPersonLanguage(
            typeof value === 'string' ? value.split(',') : value,
        );
        if (numberOfHashtags < 9) {
            setNumberOfHashtags(numberOfHashtags + 1)
            // @ts-ignore
            addHashtag((arrayOfHashtags) => arrayOfHashtags.concat(value))
        } else {
        }
    };
    return (
        <Grid>

            <FormControl sx={{width: "100%"}}>
                <InputLabel id="demo-simple-select-label">Which other languages dou you know?</InputLabel>
                <Select

                    labelId="demo-simple-select-label"
                    id="demo-multiple-name"
                    label="Which other languages dou you know?"
                    value={personLanguage}
                    onChange={handleChange}

                    MenuProps={MenuProps}
                >
                    {languages.map((language) => (
                        <MenuItem
                            key={language}
                            value={language}
                        >
                            {language}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Grid item xs={12}>
                {numberOfHashtags > 0 ? Hashtags : ""}
            </Grid>
        </Grid>
    )
}
