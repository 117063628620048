import axios, {AxiosError} from "axios";

import {DiagramList, ThemeList} from "./DiagramModels";

export class DiagramListAPI{

    static baseUrl = process.env.REACT_APP_BACKEND_URL;

    static async getAllPurpose(token: string): Promise<Array<DiagramList>> {
        const config = {headers: {token: token}};
        const res = await axios.get(this.baseUrl + '/purpose/all-purpose/', config)
        return res.data;
    }

    static async addPurpose(name: string,container: string[]): Promise<boolean> {
        try {
            await axios.post(this.baseUrl + '/purpose/diagram-purpose', {
                name: name,
                container: container
            })
            return true;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status == 400) {
                    return false;
                }
            }
            throw e;
        }
    }

    static async deletePurpose(id: number, token: string): Promise<boolean> {
        try {
            const config = {headers: {token: token}};
            const res = await axios.delete(this.baseUrl + '/purpose/' + id, config)
            return true;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status == 404) {
                    return false;
                }
            }
            throw e;
        }
    }

    static async getAllLevel(token: string): Promise<Array<DiagramList>> {
        const config = {headers: {token: token}};
        const res = await axios.get(this.baseUrl + '/level/all-level/', config)
        return res.data;
    }



    static async getAllTheme(token: string): Promise<Array<ThemeList>> {
        const config = {headers: {token: token}};
        const res = await axios.get(this.baseUrl + '/theme/all-theme/', config)
        return res.data;
    }

    static async getThemeById(id: number, token: string): Promise<ThemeList | null> {
        try {
            const config = {
                headers: {
                    token: token
                }
            };
            const res = await axios.get(this.baseUrl + `/theme/${id}`, config);
            return res.data;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status === 404) {
                    return null; // Theme not found
                }
            }
            throw e;
        }
    }
    static async updateTheme(id: number, requestBody: { title: string; description: string; level: string; container: string[] }, token: string): Promise<ThemeList | null> {
        try {
          const config = {
            headers: {
              token: token
            }
          };
      
          const res = await axios.put(this.baseUrl + `/theme/${id}`, requestBody, config);
          return res.data;
        } catch (e: unknown) {
          if (e instanceof AxiosError) {
            if (e.response?.status === 404) {
              return null; // Theme not found
            }
          }
          throw e;
        }
      }
    static async addTheme(title: string, description: string, level: string, container: string[], token: string): Promise<boolean> {
        try {
            const data = {
                title: title,
                description: description,
                level: level,
                container: container
            };
    
            const config = {
                headers: {
                    token: token
                }
            };
    
            await axios.post(this.baseUrl + '/theme/diagram-theme', data, config);
            return true;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status === 400) {
                    return false;
                }
            }
            throw e;
        }
    }

    static async deleteTheme(id: number, token: string): Promise<boolean> {
        try {
            const config = {
                headers: {
                    token: token
                }
            };
    
            await axios.delete(this.baseUrl + `/theme/${id}`, config);
            return true;
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status === 404) {
                    return false; // Theme not found
                }
            }
            throw e;
        }
    }
}