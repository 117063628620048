import Navbar from "../../components/Navbar";
import { useAuth } from "../../hooks/useAuth";
import HomeButton from "../../components/HomeButton";
import useLocalStorage from "use-local-storage";
import { Button, Collapse, CssBaseline, Divider, List, ListItemButton, ListItemIcon, ListItemText, Slider } from "@mui/material";
import { dark } from "@mui/material/styles/createPalette";
import { ExpandLess, ExpandMore, Margin, StarBorder } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import HomeSlider from "../../components/HomeSlider";
import { useEffect, useState } from "react";
import { DiagramListAPI } from "../../api/DiagramListAPI";
import { Link } from "react-router-dom";
import { AiOutlineInbox } from 'react-icons/ai';
interface CourseDataItem {
  id: number;
  title: string;
  description: string;
  level: string;
  container: string[];
}
function Home() {

  const auth = useAuth();
  const [courseData, setCourseData] = useState<CourseDataItem[]>([]);
  const [t, i18n] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDrawer = (value: boolean | ((prevState: boolean) => boolean)) => {
    setIsOpen(value);
  }

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const token = await auth.getToken(); // Auth işlemlerini senin uygulamanın gereksinimlerine uygun şekilde ekle.
        const fetchedCourseData = await DiagramListAPI.getAllTheme(token!); // API'den verileri çekmek için senin API bağlantılarını kullan.
        setCourseData(fetchedCourseData);
        // İlerleme verilerini burada güncelleyebilirsin.
      } catch (error) {
        console.error('Hata:', error);
      }
    };

    fetchCourseData(); // fetchCourseData işlemini çağır.

  }, []);
  return (
    <>
      <Navbar toggleNavbarDrawer={handleToggleDrawer} data={"home"} />
      <div>

        <div className="page-wrapper">


          <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content">

            </div>
          </div>
          <HomeSlider />
          <section className="features-one">
            <div className="container">
              <div className="row">

                <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <div className="features-one__single">
                    <div className="features-one__single-icon">
                      <span className="icon-empowerment"></span>
                    </div>
                    <div className="features-one__single-text">
                      <h4><a href="#">Learn Skills</a></h4>
                      <p>with unlimited courses</p>
                    </div>
                  </div>
                </div>


                <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                  <div className="features-one__single">
                    <div className="features-one__single-icon">
                      <span className="icon-human-resources-1"></span>
                    </div>
                    <div className="features-one__single-text">
                      <h4><a href="#">Expert Teachers</a></h4>
                      <p>best & highly qualified</p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                  <div className="features-one__single">
                    <div className="features-one__single-icon">
                      <span className="icon-recruitment"></span>
                    </div>
                    <div className="features-one__single-text">
                      <h4><a href="#">Certificates</a></h4>
                      <p>value all over the world</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>


          <section className="about-one clearfix">
            <div className="container">
              <div className="row">

                <div className="col-xl-6">
                  <div className="about-one__left">
                    <ul className="about-one__left-img-box list-unstyled clearfix">
                      <li className="about-one__left-single">
                        <div className="about-one__left-img1">
                          <img src="assets/images/about/about-v1-img1.jpg" alt="" />
                        </div>
                      </li>
                      <li className="about-one__left-single">
                        <div className="about-one__left-img2">
                          <img src="assets/images/about/about-v1-img2.jpg" alt="" />
                        </div>
                      </li>
                    </ul>
                    <div className="about-one__left-overlay">
                      <div className="icon">
                        <span className="icon-relationship"></span>
                      </div>
                      <div className="title">
                        <h6>Trusted by<br /><span className="odometer" data-count="8800">00</span> customers</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="about-one__right">
                    <div className="section-title">
                      <span className="section-title__tagline">About Zilom Company</span>
                      <h2 className="section-title__title">Welcome to the Online <br />Learning Center</h2>
                    </div>
                    <div className="about-one__right-inner">
                      <p className="about-one__right-text">There are many variations of passages of lorem ipsum
                        available but the majority have suffered alteration in some form by injected humour
                        or randomised words which don't look.</p>
                      <ul className="about-one__right-list list-unstyled">
                        <li className="about-one__right-list-item">
                          <div className="icon">
                            <span className="icon-confirmation"></span>
                          </div>
                          <div className="text">
                            <p>Get unlimited access to 66000+ of our top courses</p>
                          </div>
                        </li>

                        <li className="about-one__right-list-item">
                          <div className="icon">
                            <span className="icon-confirmation"></span>
                          </div>
                          <div className="text">
                            <p>Explore a variety of fresh educational topics</p>
                          </div>
                        </li>

                        <li className="about-one__right-list-item">
                          <div className="icon">
                            <span className="icon-confirmation"></span>
                          </div>
                          <div className="text">
                            <p>Find the best qualitfied teacher for you</p>
                          </div>
                        </li>
                      </ul>

                      <div className="courses-one--courses__top-btn">
                        <button className="thm-btn courses-one--courses__top-btn"
                          style={{ fontSize: '14px', padding: '16px 24px', margin: '16px 8px' }}>
                          Add New Course
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>

          <section className="courses-one">
            <div className="container">
              <div className="section-title text-center">
                <span className="section-title__tagline">Checkout New List</span>
                <h2 className="section-title__title">Explore Courses</h2>
              </div>
              <div className="row">
                {courseData.slice(0, 8).map((course) => (
                  <div key={course.id} className="col-xl-3 col-lg-6 col-md-6">
                    <div className="courses-one__single wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                      <div className="courses-one__single-img">
                        <img src="assets/images/resources/l.png" alt="" />
                        <div className="overlay-text">
                          <p>Featured</p>
                        </div>
                      </div>
                      <div className="courses-one__single-content">

                        <h4 className="courses-one__single-content-title"><Link to={"courseDetails/" + course.id}>{course.title}</Link></h4>
                        <div className="courses-one__single-content-review-box">
                          <ul className="list-unstyled">
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                          </ul>
                          <div className="rateing-box">
                            <span>(4)</span>
                          </div>
                        </div>
                        <p className="courses-one__single-content-price">{course.description}</p>
                        <ul style={{ display: 'flex', justifyContent: 'center' }} className="courses-one__single-content-courses-info list-unstyled">
                          <li>{course.container.length} Diagrams</li>

                        </ul>
                      </div>
                    </div>
                  </div>


                ))}
              </div>
            </div>
          </section>

          <section className="why-choose-one">
            <div className="container">
              <div className="row">

                <div className="col-xl-6 col-lg-6">
                  <div className="why-choose-one__left">
                    <div className="section-title">
                      <span className="section-title__tagline">Why Choose Us?</span>
                      <h2 className="section-title__title">Benefits of Learning <br />from Zilom</h2>
                    </div>
                    <p className="why-choose-one__left-text">There cursus massa at urnaaculis estie. Sed
                      aliquamellus vitae ultrs condmentum leo massa mollis estiegittis miristum nulla sed medy
                      fringilla vitae.</p>
                    <div className="why-choose-one__left-learning-box">
                      <div className="icon">
                        <span className="icon-professor"></span>
                      </div>
                      <div className="text">
                        <h4>Start learning from our experts and <br />enhance your skills</h4>
                      </div>
                    </div>
                    <div className="why-choose-one__left-list">
                      <ul className="list-unstyled">
                        <li className="why-choose-one__left-list-single">
                          <div className="icon">
                            <span className="icon-confirmation"></span>
                          </div>
                          <div className="text">
                            <p>Making this the first true on the Internet</p>
                          </div>
                        </li>

                        <li className="why-choose-one__left-list-single">
                          <div className="icon">
                            <span className="icon-confirmation"></span>
                          </div>
                          <div className="text">
                            <p>Lorem Ipsum is not simply random text</p>
                          </div>
                        </li>

                        <li className="why-choose-one__left-list-single">
                          <div className="icon">
                            <span className="icon-confirmation"></span>
                          </div>
                          <div className="text">
                            <p> If you are going to use a passage</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6">
                  <div className="why-choose-one__right wow slideInRight animated clearfix" data-wow-delay="0ms"
                    data-wow-duration="1500ms">
                    <div className="why-choose-one__right-img clearfix">
                      <img src="assets/images/resources/why-choose-v1-img.jpg" alt="" />
                      <div className="why-choose-one__right-img-overlay">
                        <p>We’re the best institution</p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </section>

          <section className="blog-one">
            <div className="container">
              <div className="section-title text-center">
                <span className="section-title__tagline">Directly from the Blog</span>
                <h2 className="section-title__title">Latest Articles</h2>
              </div>
              <div className="row">

                <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <div className="blog-one__single">
                    <div className="blog-one__single-img">
                      <img src="assets/images/blog/blog-v1-img1.jpg" alt="" />
                    </div>
                    <div className="blog-one__single-content">
                      <div className="blog-one__single-content-overlay-mata-info">
                        <ul className="list-unstyled">
                          <li><a href="#"><span className="icon-clock"></span>20 June</a></li>
                          <li><a href="#"><span className="icon-user"></span>Admin </a></li>
                          <li><a href="#"><span className="icon-chat"></span> Comments</a></li>
                        </ul>
                      </div>
                      <h2 className="blog-one__single-content-title"><a href="news-details.html">Helping Answers
                        Stand out in Discussions</a></h2>
                      <p className="blog-one__single-content-text">Lorem ipsum is simply free text on used by
                        copytyping refreshing the whole area.</p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <div className="blog-one__single">
                    <div className="blog-one__single-img">
                      <img src="assets/images/blog/blog-v1-img2.jpg" alt="" />
                    </div>
                    <div className="blog-one__single-content">
                      <div className="blog-one__single-content-overlay-mata-info">
                        <ul className="list-unstyled">
                          <li><a href="#"><span className="icon-clock"></span>20 June</a></li>
                          <li><a href="#"><span className="icon-user"></span>Admin </a></li>
                          <li><a href="#"><span className="icon-chat"></span> Comments</a></li>
                        </ul>
                      </div>
                      <h2 className="blog-one__single-content-title"><a href="news-details.html">Helping Answers
                        Stand out in Discussions</a></h2>
                      <p className="blog-one__single-content-text">Lorem ipsum is simply free text on used by
                        copytyping refreshing the whole area.</p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                  <div className="blog-one__single">
                    <div className="blog-one__single-img">
                      <img src="assets/images/blog/blog-v1-img3.jpg" alt="" />
                    </div>
                    <div className="blog-one__single-content">
                      <div className="blog-one__single-content-overlay-mata-info">
                        <ul className="list-unstyled">
                          <li><a href="#"><span className="icon-clock"></span>20 June</a></li>
                          <li><a href="#"><span className="icon-user"></span>Admin </a></li>
                          <li><a href="#"><span className="icon-chat"></span> Comments</a></li>
                        </ul>
                      </div>
                      <h2 className="blog-one__single-content-title"><a href="news-details.html">Helping Answers
                        Stand out in Discussions</a></h2>
                      <p className="blog-one__single-content-text">Lorem ipsum is simply free text on used by
                        copytyping refreshing the whole area.</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>

          <section className="newsletter-one">
            <div className="container">
              <div className="row">

                <div className="col-xl-6 col-lg-6">
                  <div className="newsletter-one__left">
                    <div className="section-title">
                      <h2 className="section-title__title">Subscribe to Our <br />Newsletter to Get Daily
                        <br />Content!</h2>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6">
                  <div className="newsletter-one__right">
                    <div className="shape1 zoom-fade"><img src="assets/images/shapes/thm-shape2.png" alt="" /></div>
                    <div className="shape2 wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms"><img
                      src="assets/images/shapes/thm-shape3.png" alt="" /></div>
                    <div className="newsletter-form wow slideInDown" data-wow-delay="100ms"
                      data-wow-duration="1500ms">
                      <form action="#">
                        <input type="text" name="email" placeholder="Enter your email" />
                        <button type="submit"><span className="icon-paper-plane"></span></button>
                      </form>
                      <div className="newsletter-one__right-checkbox">
                        <input type="checkbox" name="agree " id="agree" checked />
                        <label><span></span>I agree to all terms and policies of the
                          company</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>


          <footer className="footer-one">
            <div className="footer-one__bg">
            </div>
            <div className="footer-one__top">
              <div className="container">
                <div className="row">

                  <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.1s">
                    <div className="footer-widget__column footer-widget__about">
                      <div className="footer-widget__about-logo">
                        <a href="index.html"><img src="assets/images/resources/langtreevector.png" alt=""
                          className="responsive-image-footer" /></a>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.3s">
                    <div className="footer-widget__column footer-widget__courses">
                      <h3 className="footer-widget__title">Courses</h3>
                      <ul className="footer-widget__courses-list list-unstyled">
                        <li><a href="#">UI/UX Design</a></li>
                        <li><a href="#">WordPress Development</a></li>
                        <li><a href="#">Business Strategy</a></li>
                        <li><a href="#">Software Development</a></li>
                        <li><a href="#">Business English</a></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.5s">
                    <div className="footer-widget__column footer-widget__links">
                      <h3 className="footer-widget__title">Links</h3>
                      <ul className="footer-widget__links-list list-unstyled">
                        <li><a href="about.html">About Us</a></li>
                        <li><a href="#">Overview</a></li>
                        <li><a href="teachers-1.html">Teachers</a></li>
                        <li><a href="#">Join Us</a></li>
                        <li><a href="news.html">Our News</a></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.7s">
                    <div className="footer-widget__column footer-widget__contact">
                      <h3 className="footer-widget__title">Contact</h3>
                      <p className="text">Kastamonu University, Türkiye</p>
                      <p><a href="mailto:dilagaci2021@gmail.com">dilagaci2021@gmail.com</a></p>
                      <p className="phone"><a href="tel:+90 366 280 14 97">+90 366 280 14 97</a></p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.9s">
                    <div className="footer-widget__column footer-widget__social-links">
                      <ul className="footer-widget__social-links-list list-unstyled clearfix">
                        <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fab fa-brands fa-linkedin"></i></a></li>
                      </ul>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </footer>


        </div>





        <a href="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></a>


      </div>

    </>
  );
}

export default Home;